import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { DesignCollectionsList } from "../../../components/libraries/designCollections/DesignCollectionsList";
import { AddDesignCollectionButton } from "../../../components/libraries/designCollections/AddDesignCollectionButton";

export const DesignCollections: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("libraries.design_collections.title")}
          breadcrumb={breadcrumb}
          extra={<AddDesignCollectionButton />}
        />
      }
    >
      <DesignCollectionsList />
    </MainContentArea>
  );
};
