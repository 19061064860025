import { FullWidthSpace } from "melco-ui";
import { ProductsReferencesList } from "../../references/ProductsReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../references/ReferencesListAlert";
import { useDesignCollectionReferences } from "../../../hooks/libraries/designCollections/useDesignCollectionReferences";

type DesignCollectionReferencesListProps = {
  designCollectionId: string;
};

export const DesignCollectionReferencesList: React.FC<
  DesignCollectionReferencesListProps
> = ({ designCollectionId }) => {
  const { t } = useTranslation();

  const { data: designCollectionReferences } =
    useDesignCollectionReferences(designCollectionId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert
        message={t("libraries.design_collections.actions.references.warning")}
      />
      <ProductsReferencesList
        productList={
          designCollectionReferences?.custom_product_list ?? undefined
        }
      />
    </FullWidthSpace>
  );
};
