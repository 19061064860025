import { useField } from "formik";
import { isEmpty } from "lodash-es";
import { DrawerSelectFieldType, DrawerType } from "melco-shared-logic";
import {
  Button,
  Col,
  DesignTokens,
  EditIcon,
  FormItem,
  PlusIcon,
  Row,
} from "melco-ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const { spacings } = DesignTokens;

type DrawerSelectFieldProps<T> = {
  type: "add" | "choose";
  field: DrawerSelectFieldType<T>;
  drawer: DrawerType;
  render: (value: T) => React.ReactNode;
  compact?: boolean;
};

export const DrawerSelectField = <T extends any>({
  type,
  field,
  drawer,
  render,
  compact,
}: DrawerSelectFieldProps<T>) => {
  const { t } = useTranslation();

  const { openDrawer, setCurrentDrawerFieldName } = drawer;

  const openDrawerCallback = useCallback(() => {
    setCurrentDrawerFieldName(field.name);
    openDrawer();
  }, [openDrawer, setCurrentDrawerFieldName, field.name]);

  const { name, label } = field;
  const [formikField, meta] = useField(name);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error as any);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  let buttonTitle = t("global.button.add");
  let buttonIcon = <PlusIcon />;

  const hasValue = formikField.value && !isEmpty(formikField.value);

  if (type === "choose") {
    if (hasValue) {
      buttonIcon = <EditIcon />;
      buttonTitle = compact ? "" : t("global.button.change");
    } else {
      buttonTitle = t("global.button.choose");
    }
  }

  return (
    <div>
      <FormItem
        hasFeedback={hasFeedback}
        help={help}
        validateStatus={validateStatus}
        label={label}
      >
        <Row gutter={[spacings.small, spacings.small]} justify="space-between">
          {hasValue && <Col>{render(formikField.value)}</Col>}
          <Col>
            <Button size="small" onClick={openDrawerCallback} icon={buttonIcon}>
              {buttonTitle}
            </Button>
          </Col>
        </Row>
      </FormItem>
    </div>
  );
};
