import { MainContentArea, ContentHeader } from "melco-ui";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { useFont } from "../../../hooks/libraries/fonts/useFont";
import { FontDownloadButton } from "../../../components/libraries/fonts/FontDownloadButton";
import { FontDetailForm } from "../../../components/libraries/fonts/FontDetailForm";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { FontDetailMoreActionButton } from "../../../components/libraries/fonts/FontDetailMoreActionButton";
import { useGenericDrawer } from "../../../hooks/drawer/useGenericDrawer";
import { FontReferencesDrawer } from "../../../components/libraries/fonts/FontReferencesDrawer";
import { FontDuplicateButton } from "../../../components/libraries/fonts/FontDuplicateButton";

export const FontDetails: React.FC = () => {
  const breadcrumb = useBreadcrumb("libraries", "librariesFontsEmbroidery");

  const { fontId } = useParams<{
    fontId: string;
  }>();

  const {
    query: { data: font, refetch },
    queryKey,
  } = useFont(fontId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useGenericDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={font?.name ?? ""}
          breadcrumb={breadcrumb}
          extra={
            <>
              <FontDuplicateButton fontId={fontId ?? ""} />
              <FontDownloadButton
                fontId={fontId ?? ""}
                fontFilename={font?.file_name ?? ""}
              />
              {font && (
                <FontDetailMoreActionButton
                  font={font}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {font && (
        <>
          <FontDetailForm
            font={font}
            refetch={refetch}
            referencesDrawer={referencesDrawer}
            queryKey={queryKey}
          />
          {font.id && (
            <FontReferencesDrawer drawer={referencesDrawer} id={font.id} />
          )}
        </>
      )}
    </MainContentArea>
  );
};
