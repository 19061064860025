import { DesignTokens, Menu, MenuItem } from "melco-ui";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import { DesignsPreviewListItem } from "./DesignsPreviewListItem";

const { spacings } = DesignTokens;

type DesignsPreviewListProps = {
  designs: MelcoCoreModelsDesignListItem[];
  currentDesign: MelcoCoreModelsDesignListItem | undefined;
  setCurrentDesign: (design: MelcoCoreModelsDesignListItem) => void;
};

export const DesignsPreviewList: React.FC<DesignsPreviewListProps> = ({
  designs,
  setCurrentDesign,
}) => {
  const defaultSelectedKeys: string[] = [];

  if (designs[0]?.id) {
    defaultSelectedKeys.push(designs[0]?.id);
  }

  return (
    <Menu
      mode="inline"
      inlineIndent={spacings.xlarge}
      defaultSelectedKeys={defaultSelectedKeys}
    >
      {designs.map((design) => {
        const { id } = design;

        return (
          <MenuItem key={id} onClick={() => setCurrentDesign(design)}>
            <DesignsPreviewListItem design={design} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
