export const ALL_PUBLISHED_STATES = "draft,published,unpublished";

export type PublishState = "draft" | "published" | "unpublished" | string;

const publishStateLookup: {
  [key: number]: "draft" | "published" | "unpublished";
} = {
  1: "draft",
  2: "published",
  3: "unpublished",
};

export const publishStateIdToName = (id: number) => {
  return publishStateLookup[id];
};
