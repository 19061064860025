import { useMemo } from "react";
import { MelcoCoreModelsDesignElement } from "melco-shared-logic";
import { useField } from "formik";
import uniq from "lodash-es/uniq";
import { ElementConfiguration, Resource } from "../../renderer/useRenderer";
import { useColorCollectionsForRenderer } from "../colorCollections/useColorCollectionsForRenderer";
import {
  deDuplicateAlphabetRFMUrls,
  rendererElementsFromElementList,
} from "../../../helper/elementConfigurationHelper";

export const useDesignElementConfigurationFromFormState = (
  designRfmUrl: string
) => {
  const [{ value: elementList }] =
    useField<MelcoCoreModelsDesignElement[]>("element_list");

  const colorCollectionIds = uniq(
    elementList.flatMap(({ color_collection_id: colorCollectionId }) =>
      colorCollectionId ? [colorCollectionId] : []
    )
  );

  const colorCollectionQueries =
    useColorCollectionsForRenderer(colorCollectionIds);

  const getColorFromColorCollection = (
    colorCollectionId: string | undefined,
    colorId: string | undefined
  ) => {
    if (!colorCollectionId || !colorId) {
      return;
    }

    const colorCollection = colorCollectionQueries.find(
      (query) => query.data?.id === colorCollectionId
    );

    if (!colorCollection) {
      return;
    }

    return colorCollection.data?.color_list?.find((c) => c.id === colorId);
  };

  const elements = rendererElementsFromElementList(
    elementList,
    getColorFromColorCollection
  );

  const resources: Resource[] = [
    {
      url: designRfmUrl,
      resourceType: "design",
    },
    ...deDuplicateAlphabetRFMUrls(elements),
  ];

  const elementConfiguration: ElementConfiguration = {
    elements,
    resources,
  };

  const hash = JSON.stringify(elementConfiguration);

  // prevent unnecessary re-rendering by keeping the elements configuration stable if possible
  const memoizedElementConfiguration = useMemo(() => {
    return elementConfiguration;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return memoizedElementConfiguration;
};
