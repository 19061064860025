import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForBlank = (
  blankId: string,
  publishState: PublishState
) => ["blank", blankId, publishState];

export const useBlank = (blankId: string, publishState: PublishState) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForBlank(blankId, publishState),
    async (api) => api.userBlankGetDef({ id: blankId, publishState })
  );
};
