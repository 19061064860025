import { useFontReferences } from "./useFontReferences";

export const useFontReferencesCount = (fontId: string) => {
  const { data: colorCollectionReferences } = useFontReferences(fontId);

  const totalDesigns = colorCollectionReferences?.design_list?.length || 0;
  const totalProducts =
    colorCollectionReferences?.custom_product_list?.length || 0;

  return { total: totalDesigns + totalProducts };
};
