import { DisplayFormErrors, VerticalForm } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { Alert, FullWidthSpace, Title } from "melco-ui";
import { ConfiguratorVersionSelectField } from "../form/ConfiguratorVersionSelectField";

export const DisplayConfiguratorSettingsForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />
      <Title level={5}>
        {t("settings.configurator.form.section.visual_apperance.title")}
      </Title>
      <FullWidthSpace direction="vertical" size="middle">
        <Alert
          message={t(
            "settings.configurator.form.section.visual_apperance.info"
          )}
          type="info"
          withBackground
          closable={false}
        />

        <ConfiguratorVersionSelectField
          name="version"
          label={t("settings.configurator.form.label.version")}
        />
      </FullWidthSpace>
    </VerticalForm>
  );
};
