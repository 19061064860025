import { SecondaryNavigationMenuDropdown } from "melco-ui";
import { useLogout } from "melco-shared-logic";
import { LogoutMenuItem } from "./LogoutMenuItem";

type SecondaryNavigationMenuProps = {
  setDropDownVisible?: (isVisible: boolean) => void;
};

export const SecondaryNavigationMenu: React.FC<
  SecondaryNavigationMenuProps
> = ({ setDropDownVisible, ...rest }) => {
  const [logout, isLoggingOut] = useLogout();

  return (
    <SecondaryNavigationMenuDropdown
      {...rest}
      onClick={async (menuInfo) => {
        if (menuInfo.key === "logout") {
          try {
            // don't reset user because that would trigger a re-initialization of the app which we don't want
            await logout({ dontResetUser: true });
            window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}?logout-success&redirectTo=${process.env.REACT_APP_APP_URL}`;
          } catch (error) {
            setDropDownVisible && setDropDownVisible(false);
          }
        }
      }}
    >
      <LogoutMenuItem key="logout" isLoggingOut={isLoggingOut} />
    </SecondaryNavigationMenuDropdown>
  );
};
