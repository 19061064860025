import { DesignTokens } from "melco-ui";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import { useDesignThumbnailURL } from "../../../hooks/libraries/designs/useDesignThumbnailURL";
import { ListThumbnail } from "../../shared/ListThumbnail";

const { spacings } = DesignTokens;

type DesignsListThumbnailProps = {
  design: MelcoCoreModelsDesignListItem;
};

export const DesignsListThumbnail: React.FC<DesignsListThumbnailProps> = ({
  design,
}) => {
  const thumbnailSrc = useDesignThumbnailURL(
    design,
    spacings.xlarge,
    spacings.xlarge
  );

  return <ListThumbnail thumbnailSrc={thumbnailSrc ?? undefined} />;
};
