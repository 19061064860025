import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, SaveIcon, Space, Title, UndoIcon, UploadIcon } from "melco-ui";
import { useField, useFormikContext } from "formik";
import {
  MelcoCoreModelsCustomWarning,
  useIsFormChanged,
  useWarnings,
} from "melco-shared-logic";
import { publishStateIdToName } from "../../helper/publishedState";
import { SavePublishTimestamps } from "./SavePublishTimestamps";
import { PublishedState } from "../state/PublishedState";
import { WarningsDetails } from "../state/WarningsDetails";

import { Drawer as DrawerType } from "../../hooks/drawer/useGenericDrawer";
import { ReferencesWarningLink } from "../references/ReferencesWarningLink";

const ActionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

const Left = styled.div``;

const Right = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionHeaderTitle = styled(Title).attrs({ level: 4 })`
  && {
    margin: 0;
  }
`;

export type SubmitAction = "save" | "publish";

type SavePublishHeaderProps = {
  revertAction: () => Promise<void>;
  isCurrentlyReverting: boolean;
  publishedState: number;
  createdDate: Date;
  updatedDate?: Date | null;
  publishedDate?: Date | null;
  warnings?: MelcoCoreModelsCustomWarning[];
  referencesCount?: number;
  referencesDrawer?: DrawerType;
};

export const SavePublishHeader: React.FC<SavePublishHeaderProps> = ({
  revertAction,
  isCurrentlyReverting,
  publishedState,
  createdDate,
  updatedDate,
  publishedDate,
  warnings,
  referencesCount,
  referencesDrawer,
}) => {
  const { t } = useTranslation();
  const isFormChanged = useIsFormChanged();
  const { isSubmitting, submitForm } = useFormikContext();
  const [submitAction, , submitActionHelper] =
    useField<SubmitAction>("submitAction");

  const [warningMessages, areWarningDetailsShown, toggleWarnings] = useWarnings(
    warnings,
    { translationPrefix: "global" }
  );

  const publishStateName = publishStateIdToName(publishedState);

  const isCurrentlySaving = isSubmitting && submitAction.value === "save";
  const isCurrentlyPublishing =
    isSubmitting && submitAction.value === "publish";

  const isRevertPossible =
    !isCurrentlyReverting && publishStateName === "unpublished";
  const isSavePossible = !isCurrentlySaving && isFormChanged;
  const isPublishPossible =
    !isCurrentlyPublishing &&
    (isFormChanged || ["draft", "unpublished"].includes(publishStateName));

  return (
    <>
      <ActionHeader data-cy-id="action-header">
        <Left>
          <ActionHeaderTitle>Details</ActionHeaderTitle>
          <PublishedState
            publishedState={publishedState}
            toggleWarnings={toggleWarnings}
          />
        </Left>
        <Right direction="vertical" size="small">
          <SavePublishTimestamps
            createdDate={createdDate}
            updatedDate={updatedDate}
            publishedDate={publishedDate}
          />
          <Space size="small">
            <Button
              icon={<UndoIcon />}
              disabled={!isRevertPossible}
              loading={isCurrentlyReverting}
              onClick={revertAction}
            >
              {t("global.action_header.revert.button")}
            </Button>
            <Button
              icon={<SaveIcon />}
              disabled={!isSavePossible}
              loading={isCurrentlySaving}
              onClick={() => {
                submitActionHelper.setValue("save");
                submitForm();
              }}
            >
              {t("global.action_header.save.button")}
            </Button>
            <Button
              type="primary"
              icon={<UploadIcon />}
              disabled={!isPublishPossible}
              loading={isCurrentlyPublishing}
              onClick={() => {
                submitActionHelper.setValue("publish");
                submitForm();
              }}
            >
              {t("global.action_header.publish.button")}
            </Button>
          </Space>
          <ReferencesWarningLink
            referencesCount={referencesCount}
            openDrawer={referencesDrawer?.openDrawer}
          />
        </Right>
      </ActionHeader>

      {warningMessages && areWarningDetailsShown && (
        <WarningsDetails
          warningMessages={warningMessages}
          onClose={() => toggleWarnings!(false)}
        />
      )}
    </>
  );
};
