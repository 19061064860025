import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Paragraph, Space } from "melco-ui";
import { formatRelativeFromNow } from "melco-shared-logic";

const SaveState = styled(Space)``;

const Timestamp = styled(Paragraph).attrs({
  size: "small",
  type: "secondary",
})`
  && {
    margin: 0;
  }
`;

type SavePublishTimestampsProps = {
  createdDate: Date;
  updatedDate?: Date | null;
  publishedDate?: Date | null;
};

export const SavePublishTimestamps: React.FC<SavePublishTimestampsProps> = ({
  createdDate,
  updatedDate,
  publishedDate,
}) => {
  const { t } = useTranslation();

  const formattedSaveTimestamp = formatRelativeFromNow(
    updatedDate ?? createdDate
  );

  const formattedPublishedTimestamp =
    formatRelativeFromNow(publishedDate) ??
    t("global.action_header.timestamps.not_available");

  return (
    <SaveState size="middle">
      {formattedSaveTimestamp && (
        <Timestamp data-cy-id="timestamp-saved">
          <strong>{t("global.action_header.timestamps.saved")}</strong>{" "}
          {formattedSaveTimestamp}
        </Timestamp>
      )}

      {formattedPublishedTimestamp && (
        <Timestamp data-cy-id="timestamp-published">
          <strong>{t("global.action_header.timestamps.published")}</strong>{" "}
          {formattedPublishedTimestamp}
        </Timestamp>
      )}
    </SaveState>
  );
};
