import { useMemo } from "react";
import { MelcoCoreModelsCustomProductImageDesign } from "melco-shared-logic";
import {
  ElementConfiguration,
  RendererElement,
  Resource,
} from "../../renderer/useRenderer";
import {
  deDuplicateAlphabetRFMUrls,
  rendererElementsFromElementList,
} from "../../../helper/elementConfigurationHelper";

export const useProductElementConfiguration = (
  blankPictureUrl: string,
  productImageDesign: MelcoCoreModelsCustomProductImageDesign | undefined
) => {
  let elements: RendererElement[] = [];
  const resources: Resource[] = [];

  resources.push({
    url: blankPictureUrl,
    resourceType: "image",
  });

  if (productImageDesign) {
    const { design } = productImageDesign;

    if (design?.rfm_url) {
      resources.push({
        url: design.rfm_url,
        resourceType: "design",
      });
    }

    if (design?.element_list) {
      elements = rendererElementsFromElementList(design.element_list);

      resources.push(...deDuplicateAlphabetRFMUrls(elements));
    }
  }

  const elementConfiguration: ElementConfiguration = {
    elements,
    resources,
  };

  const hash = JSON.stringify(elementConfiguration);

  // prevent unnecessary re-rendering by keeping the elements configuration stable if possible
  const memoizedElementConfiguration = useMemo(() => {
    return elementConfiguration;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return memoizedElementConfiguration;
};
