import {
  MelcoCoreModelsDesignListItem,
  selectThumbnailForSize,
} from "melco-shared-logic";

export const useDesignThumbnailURL = (
  design: MelcoCoreModelsDesignListItem,
  width: number,
  height: number
) => {
  const thumbnail = selectThumbnailForSize(
    design.previews ?? [],
    Math.max(width, height)
  );

  return thumbnail?.url;
};
