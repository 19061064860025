import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDuplicateBlankPicture = (blankPictureId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userImageDuplicateDraft({
        id: blankPictureId,
      });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    { translationPrefix: "blank_pictures.actions.duplicate" }
  );
};
