import { useCallback, useState } from "react";
import { Button, UploadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "react-query";
import { useDesignUpload } from "../../../hooks/libraries/designs/useDesignUpload";

export const DesignUploadButton: React.FC = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { uploadDesign } = useDesignUpload();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true);
      for (const file of acceptedFiles) {
        try {
          await uploadDesign(file);
        } catch (error) {}
        queryClient.invalidateQueries("designs");
      }
      setIsLoading(false);
    },
    [uploadDesign, queryClient]
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <input {...getInputProps()} />

      <Button
        key="designs-upload"
        type="primary"
        icon={<UploadIcon />}
        onClick={() => {
          open();
        }}
        loading={isLoading}
      >
        {t("designs.actions.upload.button")}
      </Button>
    </>
  );
};
