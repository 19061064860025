import { useTranslation } from "react-i18next";
import { ColorSwatch, DesignTokens, Space, Text } from "melco-ui";
import { useColors } from "../../../hooks/libraries/blanks/useColors";
import { SortableList } from "../../shared/SortableList";

const { spacings } = DesignTokens;

export const BlankColorsSortableList: React.FC = () => {
  const { t } = useTranslation();
  const [colors, reorderColors] = useColors();

  return (
    <div data-cy-id="colors-sortable-list-wrapper">
      <Space direction="vertical" size="small">
        <Text>{t("libraries.blanks.blanks.detail.sort_colors.title")}</Text>

        <SortableList
          items={colors}
          onSortingChange={reorderColors}
          renderItem={(color) => {
            const { colorHex } = color;
            return (
              <ColorSwatch
                argb={colorHex ?? undefined}
                size={spacings.xlarge}
              />
            );
          }}
        />
      </Space>
    </div>
  );
};
