import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useAddColorCollection = () =>
  useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userColorCollectionsAddDraft({
        melcoCoreModelsAddColorCollection: {
          service: "embroidery",
          color_list: [],
        },
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "color_collections.actions.add",
    }
  );
