import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateFont } from "../../../hooks/libraries/fonts/useDuplicateFont";

type FontDuplicateButtonProps = {
  fontId: string;
};

export const FontDuplicateButton: React.FC<FontDuplicateButtonProps> = ({
  fontId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [duplicateFont, isLoading] = useDuplicateFont(fontId);

  return (
    <Button
      key="font-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const font = await duplicateFont();

        if (font) {
          navigate(`/libraries/fonts/embroidery/${font.id}`);
        }
      }}
      loading={isLoading}
    >
      {t("fonts.actions.duplicate.button")}
    </Button>
  );
};
