import { useDesignCollectionReferences } from "./useDesignCollectionReferences";

export const useDesignCollectionReferencesCount = (
  designCollectionId: string
) => {
  const { data: designCollectionReferences } =
    useDesignCollectionReferences(designCollectionId);

  const totalProducts =
    designCollectionReferences?.custom_product_list?.length || 0;

  return { total: totalProducts };
};
