import { MainContentArea, ContentHeader, ValueOrFallback } from "melco-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { DesignDownloadButton } from "../../../components/libraries/designs/DesignDownloadButton";
import { DesignDetailForm } from "../../../components/libraries/designs/DesignDetailForm";
import { DesignDuplicateButton } from "../../../components/libraries/designs/DesignDuplicateButton";
import { DesignCollectionDetailMoreActionButton } from "../../../components/libraries/designCollections/DesignCollectionDetailMoreActionButton";
import { DesignCollectionReferencesDrawer } from "../../../components/libraries/designCollections/DesignCollectionReferencesDrawer";
import { useDesignReferencesDrawer } from "../../../hooks/drawer/useDesignReferencesDrawer";
import { useDesignCollection } from "../../../hooks/libraries/designCollections/useDesignCollection";
import { DesignCollectionDetailForm } from "../../../components/libraries/designCollections/DesignCollectionDetailForm";

export const DesignCollectionDetails: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries", "librariesDesignCollections");

  const { designCollectionId } = useParams<{
    designCollectionId: string;
  }>();

  const {
    query: { data: designCollection, refetch },
    queryKey,
  } = useDesignCollection(designCollectionId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useDesignReferencesDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            designCollection && (
              <ValueOrFallback
                value={designCollection.name}
                fallback={t("global.fallback.empty")}
              />
            )
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              {/*<DesignDuplicateButton designId={designId ?? ""} />
              <DesignDownloadButton
                designId={designId ?? ""}
                designFilename={designCollection?.file_name ?? ""}
              />
              */}
              {designCollection && (
                <DesignCollectionDetailMoreActionButton
                  designCollection={designCollection}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {designCollection && (
        <>
          <DesignCollectionDetailForm
            designCollection={designCollection}
            refetch={refetch}
            referencesDrawer={referencesDrawer!}
            queryKey={queryKey}
          />

          {designCollection.id && referencesDrawer && (
            <DesignCollectionReferencesDrawer
              drawer={referencesDrawer}
              id={designCollection.id}
            />
          )}
        </>
      )}
    </MainContentArea>
  );
};
