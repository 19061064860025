import { MainContentArea, ContentHeader } from "melco-ui";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ThreadsList } from "../../../components/libraries/colors/ThreadsList";
import { useThreadSupplierProduct } from "../../../hooks/libraries/colors/useThreadSupplierProduct";
import { MelcoCoreModelsSupplierProduct } from "melco-shared-logic";

export const titleForThreadSupplierProduct = (
  threadSupplierProduct?: MelcoCoreModelsSupplierProduct
) => {
  if (threadSupplierProduct) {
    return [
      threadSupplierProduct.supplier_name,
      threadSupplierProduct.name,
    ].join(" ");
  }

  return "";
};

export const Threads: React.FC = () => {
  const breadcrumb = useBreadcrumb("libraries", "librariesColorsEmbroidery");
  const { productId } = useParams<{
    productId: string;
  }>();

  const { data: threadSupplierProduct } = useThreadSupplierProduct(
    productId ?? ""
  );

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={titleForThreadSupplierProduct(threadSupplierProduct)}
          breadcrumb={breadcrumb}
        />
      }
    >
      <ThreadsList productId={productId ?? ""} />
    </MainContentArea>
  );
};
