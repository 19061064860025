import { useState } from "react";
import { MelcoCoreModelsCustomProductImage } from "melco-shared-logic";
import { isEmpty } from "lodash-es";
import { useField } from "formik";
import styled from "styled-components";
import { DesignCanvasWorkingArea } from "../../libraries/designs/DesignCanvasWorkingArea";
import { CanvasActionGroup, CanvasActionGroupItem } from "melco-ui";
import { BlankPictureColorSwatch } from "./BlankPictureColorSwatch";
import { ProductViewCanvasRenderer } from "./ProductViewCanvasRenderer";
import { ZoomToggleButtonPortal } from "./ZoomToggleButtonPortal";

const StyledDesignCanvasWorkingArea = styled(DesignCanvasWorkingArea)`
  margin-right: 0;
`;

type ProductViewCanvasProps = {
  formikPrefix: string;
};

export const ProductViewCanvas: React.FC<ProductViewCanvasProps> = ({
  formikPrefix,
}) => {
  const [{ value: colorListValue }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(`${formikPrefix}.color_list`);

  const colorList = colorListValue ?? [];

  const [activeImageId, setActiveImageId] = useState<string | undefined>(
    isEmpty(colorList) ? undefined : colorList[0].id!
  );

  const activeColorList = colorList.find((cl) => cl.id === activeImageId);
  const activeImageIndex = colorList.findIndex((cl) => cl.id === activeImageId);

  return (
    <StyledDesignCanvasWorkingArea
      actionGroups={
        <>
          <CanvasActionGroup positionY="top" positionX="right">
            <ZoomToggleButtonPortal />
          </CanvasActionGroup>
          <CanvasActionGroup>
            {colorList.map((cl) => (
              <CanvasActionGroupItem
                key={cl.id!}
                onClick={() => setActiveImageId(cl.id!)}
                isActive={cl === activeColorList}
              >
                <BlankPictureColorSwatch blankPictureId={cl.id!} />
              </CanvasActionGroupItem>
            ))}
          </CanvasActionGroup>
        </>
      }
    >
      {activeImageIndex != null && (
        <ProductViewCanvasRenderer
          formikPrefix={formikPrefix}
          activeImageId={activeImageId ?? ""}
          activeImageIndex={activeImageIndex}
        />
      )}
    </StyledDesignCanvasWorkingArea>
  );
};
