import { Trans } from "react-i18next";
import { ExclamationCircleIcon, Link, Paragraph } from "melco-ui";
import styled from "styled-components";

const StyledParagraph = styled(Paragraph)`
  && {
    margin: 0;
  }
`;

type ReferencesWarningLinkProps = {
  referencesCount?: number;
  openDrawer?: () => void;
};

export const ReferencesWarningLink: React.FC<ReferencesWarningLinkProps> = ({
  referencesCount = 0,
  openDrawer,
}) => {
  return (
    <StyledParagraph
      size="small"
      type="warning"
      data-cy-id="references-warning-link"
    >
      {referencesCount > 0 ? (
        <>
          <ExclamationCircleIcon />{" "}
          <Trans
            i18nKey="global.references.warning_link"
            count={referencesCount}
          >
            Changes may affect
            <Link underline type="warning" onClick={openDrawer}>
              {`${referencesCount} items`}
            </Link>
          </Trans>
        </>
      ) : (
        <>&nbsp;</>
      )}
    </StyledParagraph>
  );
};
