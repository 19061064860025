import { FontReferencesListFusion } from "./FontReferencesList.fusion";
import { useCurrentProductVariant } from "../../../hooks/useCurrentProductVariant";
import { FontReferencesListAPIService } from "./FontReferencesList.apiservice";

export type FontReferencesListProps = {
  fontId: string;
};

export const FontReferencesList: React.FC<FontReferencesListProps> = ({
  fontId,
}) => {
  const productVariant = useCurrentProductVariant();
  return productVariant === "fusion" ? (
    <FontReferencesListFusion fontId={fontId} />
  ) : (
    <FontReferencesListAPIService fontId={fontId} />
  );
};
