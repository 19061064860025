import { useCurrentProductVariant } from "../../hooks/useCurrentProductVariant";
import { MainMenuFusion } from "./MainMenu.fusion";
import { MainMenuAPIService } from "./MainMenu.apiservice";

export const MainMenu: React.FC = () => {
  const productVariant = useCurrentProductVariant();

  return productVariant === "fusion" ? (
    <MainMenuFusion />
  ) : (
    <MainMenuAPIService />
  );
};
