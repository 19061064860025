import {
  BulkActionContainerCol,
  Col,
  Table,
  TableActionBarRow,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  BulkActionCount,
  FormattedDate,
  MelcoCoreModelsSessionPersonalizationListItem,
  useTableSelection,
} from "melco-shared-logic";
import { useOrders } from "../../hooks/orders/useOrders";
import {
  orderProductionStatusIdToName,
  orderSessionStatusIdToName,
} from "../../helper/ordersHelper";
import { BulkActionDownloadOrders } from "./BulkActionDownloadOrders";
import { OrdersListFilter } from "./OrdersListFilter";

export const OrdersList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    filtersChange,
  } = useOrders();

  const { t } = useTranslation();

  const { selectedRowKeys, rowSelection, onRow } =
    useTableSelection<MelcoCoreModelsSessionPersonalizationListItem>(() => {}, {
      fixed: "left",
    });

  const columns = [
    {
      title: t("orders_list.columns.personalization_id"),
      key: "personalization_id",
      dataIndex: "personalization_id",
      sorter: true,
      width: 170,
    },
    {
      title: t("orders_list.columns.product_name"),
      key: "product_name",
      dataIndex: "product_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.design_name"),
      key: "design_name",
      dataIndex: "design_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.blank_name"),
      key: "blank_name",
      dataIndex: "blank_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.color_name"),
      key: "color_name",
      dataIndex: "color_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.view_name"),
      key: "view_name",
      dataIndex: "view_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.hoop_name"),
      key: "hoop_name",
      dataIndex: "hoop_name",
      sorter: true,
    },
    {
      title: t("orders_list.columns.session_id"),
      key: "session_id",
      dataIndex: "session_id",
      sorter: true,
      width: 310,
    },
    {
      title: t("orders_list.columns.production_status"),
      key: "production_status",
      dataIndex: "production_status",
      sorter: true,
      render: (value: number) => {
        const productionStatusName = orderProductionStatusIdToName(value);
        return t(`orders_list.production_status.${productionStatusName}`);
      },
      fixed: "right" as const,
      width: 170,
    },
    {
      title: t("orders_list.columns.session_status"),
      key: "session_status",
      dataIndex: "session_status",
      sorter: true,
      render: (value: number) => {
        const sessionStatusName = orderSessionStatusIdToName(value);
        return t(`orders_list.session_status.${sessionStatusName}`);
      },
      fixed: "right" as const,
      width: 150,
    },
    {
      title: t("orders_list.columns.end_timestamp"),
      key: "end_timestamp",
      dataIndex: "end_timestamp",
      sorter: true,
      render: (
        _: string,
        item: MelcoCoreModelsSessionPersonalizationListItem
      ) => <FormattedDate date={item.end_timestamp} />,
      fixed: "right" as const,
      width: 220,
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col xs={24} xl={17}>
          <OrdersListFilter onFiltersChange={filtersChange} />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDownloadOrders selectedRowKeys={selectedRowKeys} />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="personalization_id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("orders_list.empty")}
        errorComponent={errorComponent}
        scroll={{ x: 1700 }}
      />
    </>
  );
};
