import { DesignTokens } from "melco-ui";
import { MelcoCoreModelsDesignCollection } from "melco-shared-logic";
import { useDesignCollectionsThumbnailURL } from "../../../hooks/libraries/designCollections/useDesignCollectionsThumbnailURL";
import { ListThumbnail } from "../../shared/ListThumbnail";

const { spacings } = DesignTokens;

type DesignCollectionsListThumbnailProps = {
  designCollection: MelcoCoreModelsDesignCollection;
};

export const DesignCollectionsListThumbnail: React.FC<
  DesignCollectionsListThumbnailProps
> = ({ designCollection }) => {
  const thumbnailSrc = useDesignCollectionsThumbnailURL(
    designCollection,
    spacings.xlarge,
    spacings.xlarge
  );

  return <ListThumbnail thumbnailSrc={thumbnailSrc ?? undefined} />;
};
