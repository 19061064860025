import {
  ElementListHelper,
  MelcoCoreModelsDesignElement,
  SwitchField,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DesignTokens, CanvasPanel, DetailPaneTitle } from "melco-ui";
import { useField } from "formik";
import { LetteringElementFields } from "./LetteringElementFields";
import { useElementTitle } from "../../../hooks/libraries/designs/useElementTitle";
import { ColorField } from "./ColorField";
import { useEffect } from "react";

const { isLettering } = ElementListHelper;

const { spacings } = DesignTokens;

const RightCanvasPanel = styled(CanvasPanel)`
  width: 300px;
`;

const ContentWrapper = styled.div`
  margin: 0 ${spacings.xlarge}px;
`;

type ElementDetailPanelProps = {
  formikPrefix: string;
  setCurrentDesignElementIndex: (
    designElementIndex: number | undefined
  ) => void;
};

export const ElementDetailPanel: React.FC<ElementDetailPanelProps> = ({
  formikPrefix,
  setCurrentDesignElementIndex,
}) => {
  const { t } = useTranslation();

  const [{ value: element }] = useField<
    MelcoCoreModelsDesignElement | undefined
  >(formikPrefix);

  const { title, subtitle } = useElementTitle(element);

  // reset selected element if it does not exist anymore
  useEffect(() => {
    if (!element) {
      // the element that should be selected does not exist anymore.
      // this can happen if the design is updated and the new design does contain e.g. less colors

      setCurrentDesignElementIndex(undefined);
    }
  }, [element, setCurrentDesignElementIndex]);

  const isEditable = !!element?.editable;

  return (
    <RightCanvasPanel elevation="left" data-cy-id="design-element-detail-panel">
      <ContentWrapper>
        <DetailPaneTitle
          title={title}
          subtitle={subtitle}
          onClose={() => setCurrentDesignElementIndex(undefined)}
        />

        <SwitchField
          name={`${formikPrefix}.editable`}
          label={t("libraries.designs.detail.form.label.editable")}
        />

        {isEditable && <ColorField formikPrefix={formikPrefix} />}

        {isEditable && isLettering(element) && (
          <LetteringElementFields formikPrefix={formikPrefix} />
        )}
      </ContentWrapper>
    </RightCanvasPanel>
  );
};
