import { useCallback } from "react";
import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDeleteBlankPictures = () => {
  const [deleteBlankPicture] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, id: string) => {
      const data = await api.userImageDelete({ id, force: true });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "blank_pictures.actions.delete",
    }
  );

  return useCallback(
    async (blankPictureIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const blankPictureId of blankPictureIds) {
        try {
          await deleteBlankPicture(blankPictureId);
          successfullyDeleted.push(blankPictureId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteBlankPicture]
  );
};
