import { useEffect } from "react";
import { useField } from "formik";
import compact from "lodash-es/compact";
import { useColorCollectionsForRenderer } from "./useColorCollectionsForRenderer";
import isEmpty from "lodash-es/isEmpty";

export const useResetDefaultColor = (formikPrefix: string) => {
  const [{ value: colorCollectionId }] = useField<string | undefined>(
    `${formikPrefix}.color_collection_id`
  );

  const [{ value: defaultColorId }, , { setValue: setDefaultColorId }] =
    useField<string | undefined>(`${formikPrefix}.default_color_id`);

  const colorCollectionQueries = useColorCollectionsForRenderer(
    compact([colorCollectionId])
  );

  useEffect(() => {
    const colorCollectionData = colorCollectionQueries[0];

    if (!colorCollectionData) {
      return;
    }

    const { data } = colorCollectionData;

    if (!data) {
      return;
    }

    if (
      !defaultColorId ||
      isEmpty(defaultColorId) ||
      !(data.color_list ?? []).find((c) => c.id === defaultColorId)
    ) {
      // set default color if currently chosen color is not available anymore or is empty
      const firstAvailableColor = (data.color_list ?? [])[0];

      if (firstAvailableColor) {
        setDefaultColorId(firstAvailableColor.id ?? "");
      } else if (defaultColorId !== "") {
        setDefaultColorId("");
      }
    }
  }, [
    colorCollectionId,
    colorCollectionQueries,
    defaultColorId,
    setDefaultColorId,
  ]);
};
