import { useErrorNotification } from "melco-shared-logic";
import { useCallback } from "react";
import { publishStateIdToName } from "../../../helper/publishedState";

export const useCheckIfProductPreviewIsAvailable = (
  productPublishState: number | undefined,
  apiKey: string | undefined
) => {
  const errorNotification = useErrorNotification();

  // if the product is in draft state it means that there is no published version available
  // hence, no preview is possible (previews use published versions only)
  const isNotPublished =
    publishStateIdToName(productPublishState ?? 0) === "draft";

  const isAPIKeyMissing = !apiKey;

  const isPreviewAvailable = !isAPIKeyMissing && !isNotPublished;

  const showPreviewNotAvailableMessage = useCallback(() => {
    isNotPublished &&
      errorNotification(
        "products.actions.preview.not_available.title",
        "products.actions.preview.not_available.message"
      );

    isAPIKeyMissing &&
      errorNotification(
        "products.actions.preview.no_api_key.title",
        "products.actions.preview.no_api_key.message"
      );
  }, [errorNotification, isNotPublished, isAPIKeyMissing]);

  return [isPreviewAvailable, showPreviewNotAvailableMessage] as const;
};
