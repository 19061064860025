import { FullWidthSpace } from "melco-ui";
import { DesignsReferencesList } from "../../references/DesignsReferencesList";
import { ProductsReferencesList } from "../../references/ProductsReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../references/ReferencesListAlert";
import { useFontReferences } from "../../../hooks/libraries/fonts/useFontReferences";
import { FontReferencesListProps } from "./FontReferencesList";

export const FontReferencesListFusion: React.FC<FontReferencesListProps> = ({
  fontId,
}) => {
  const { t } = useTranslation();

  const { data: fontReferences } = useFontReferences(fontId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert message={t("fonts.actions.references.warning")} />
      <DesignsReferencesList
        designList={fontReferences?.design_list ?? undefined}
      />
      <ProductsReferencesList
        productList={fontReferences?.custom_product_list ?? undefined}
      />
    </FullWidthSpace>
  );
};
