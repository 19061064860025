import { useTranslation } from "react-i18next";
import {
  SettingIcon,
  Menu,
  SubMenu,
  MenuItem,
  DiskIcon,
  ChartIcon,
} from "melco-ui";
import { useMainMenu } from "melco-shared-logic";

export const MainMenuAPIService: React.FC = () => {
  const { t } = useTranslation();
  const { selectedKeys, onClick } = useMainMenu();

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={selectedKeys}
      onClick={onClick}
    >
      <MenuItem key="/" icon={<ChartIcon />}>
        {t("main_menu.dashboard")}
      </MenuItem>

      <SubMenu
        key="/libraries"
        icon={<DiskIcon />}
        title={t("main_menu.libraries")}
      >
        <MenuItem key="/libraries/designs">
          {t("main_menu.libraries_designs")}
        </MenuItem>
        <MenuItem key="/libraries/fonts/embroidery">
          {t("main_menu.libraries_fonts_embroidery")}
        </MenuItem>
        <MenuItem key="/libraries/color-collections/embroidery">
          {t("main_menu.libraries_color_collections_embroidery")}
        </MenuItem>
      </SubMenu>

      <SubMenu
        key="/settings"
        icon={<SettingIcon />}
        title={t("main_menu.settings")}
      >
        <MenuItem key="/settings/basic">
          {t("main_menu.settings_basic")}
        </MenuItem>
      </SubMenu>
    </Menu>
  );
};
