import { MainNavigation } from "melco-ui";
import { useTranslation } from "react-i18next";

import Logo from "../../assets/logo.svg";
import LogoCollapsed from "../../assets/logo_collapsed.svg";
import { useCurrentProductVariant } from "../../hooks/useCurrentProductVariant";
import { MainMenu } from "./MainMenu";

export const FusionMainNavigation: React.FC = () => {
  const { t } = useTranslation();
  const productVariant = useCurrentProductVariant();

  return (
    <MainNavigation
      appName={t(`product.name.${productVariant}`)}
      logo={Logo}
      logoCollapsed={LogoCollapsed}
      mainMenu={<MainMenu />}
    />
  );
};
