import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddProduct } from "../../../hooks/customization/products/useAddProduct";

export const AddProductButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addProduct, isLoading] = useAddProduct();

  return (
    <Button
      key="product-add"
      type="primary"
      icon={<PlusIcon />}
      onClick={async () => {
        const product = await addProduct();
        if (product) {
          navigate(`/customization/products/${product.id!}`);
        }
      }}
      loading={isLoading}
    >
      {t("products.actions.add.button")}
    </Button>
  );
};
