import { Dispatch, useCallback, useEffect, useReducer, useState } from "react";
import {
  StitchElement,
  Color,
  Alphabet,
  ElementFactory,
  ImageElement,
  GLRenderer,
} from "@melco/renderer";
import { BaseAction, renderStateReducer } from "@melco/renderer/dist/events";
import {
  FusionRenderState,
  FusionRenderStateUtil,
  ZoomedTo,
} from "./FusionRendererActions";

export type RendererElement = {
  color?: Color;
  activeColorGroupId?: number;
  lettering?: RendererLetteringElement;
};

export type RendererLetteringElement = {
  text: string;
  rfmUrl?: string;
  rendererIndex: number;
  isEditable: boolean;
};

export type ElementConfiguration = {
  elements: RendererElement[];
  resources: Resource[];
};

export type Resource = {
  url: string;
  resourceType: ResourceType;
};

type ResourceType = "design" | "alphabet" | "image";

export type Resources = {
  [url: string]: StitchElement | Alphabet | ImageElement;
};

const initDefaultState = (zoomedTo?: ZoomedTo) => {
  const renderState = FusionRenderStateUtil.createDefault();

  if (zoomedTo) {
    renderState.zoomedTo = zoomedTo;
  }

  return renderState;
};

export const getRenderResourceByURL = (resources: Resources, url: string) =>
  resources[url];

export const useRenderer = (
  canvasId: string,
  elementConfiguration: ElementConfiguration,
  afterInit?: (
    f: ElementFactory | null,
    canvas: HTMLElement | undefined,
    renderer: GLRenderer | undefined,
    rendererDispatch: Dispatch<BaseAction>
  ) => void,
  zoomedTo?: ZoomedTo
) => {
  const [rendererState, rendererDispatch] = useReducer(
    renderStateReducer,
    initDefaultState(zoomedTo)
  );
  const [factory, setFactory] = useState<ElementFactory | null | undefined>(
    undefined
  );
  const [canvas, setCanvas] = useState<HTMLElement | undefined>(undefined);
  const [renderer, setRenderer] = useState<GLRenderer | undefined>(undefined);
  const [resources, setResources] = useState<Resources>({});

  const initCallback = useCallback(
    (
      factory: ElementFactory | null,
      canvas?: HTMLElement,
      renderer?: GLRenderer
    ) => {
      if (factory) {
        setFactory(factory);
      }
      if (canvas) {
        setCanvas(canvas);
      }
      if (renderer) {
        setRenderer(renderer);
      }
      if (afterInit) {
        afterInit(factory, canvas, renderer, rendererDispatch);
      }
    },
    [afterInit, setFactory, setCanvas, setRenderer]
  );

  const downloadResource = useCallback(
    async (resourceUrl: string, elementType: ResourceType) => {
      if (!factory) {
        console.error(
          "Downloading resources is only allowed after the renderer is initialized"
        );
        return;
      }

      // we already have downloaded this item
      if (resources[resourceUrl]) {
        return resources[resourceUrl];
      }

      switch (elementType) {
        case "design":
          const designElement = await factory.createDesignElement({
            designMetadataUrl: resourceUrl,
          });
          setResources((r) => ({ ...r, [resourceUrl]: designElement }));

          return;

        case "alphabet":
          const alphabetElement = await factory.createAlphabet(resourceUrl);
          setResources((r) => ({ ...r, [resourceUrl]: alphabetElement }));

          return;

        case "image":
          const imageElement = await factory.createImageElement({
            imageUrl: resourceUrl,
          });
          setResources((r) => ({ ...r, [resourceUrl]: imageElement }));

          return;
      }
    },
    [factory, resources, setResources]
  );

  useEffect(() => {
    const downloadResources = async () => {
      for (const resource of elementConfiguration.resources) {
        await downloadResource(resource.url, resource.resourceType);
      }
    };

    if (factory) {
      downloadResources();
    }
  }, [factory, downloadResource, elementConfiguration.resources]);

  const isLoading = !factory;

  return {
    canvasId,
    canvas,
    renderer,
    initCallback,
    rendererState: rendererState as FusionRenderState,
    rendererDispatch,
    isLoading,
    resources,
    factory,
    elementUtil: factory?.elementUtil,
  };
};
