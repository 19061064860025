import { Button, DownloadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { useDownloadColorCollection } from "../../../hooks/libraries/colorCollections/useDownloadColorCollection";

type ColorCollectionsDownloadButtonProps = {
  colorCollectionId: string;
  colorCollectionFilename: string;
};

export const ColorCollectionsDownloadButton: React.FC<
  ColorCollectionsDownloadButtonProps
> = ({ colorCollectionId, colorCollectionFilename }) => {
  const { t } = useTranslation();
  const [downloadColorCollection, isLoading] =
    useDownloadColorCollection(colorCollectionId);

  return (
    <Button
      key="color-collection-download"
      icon={<DownloadIcon />}
      onClick={async () => {
        const blob = await downloadColorCollection();

        if (blob) {
          saveAs(blob, colorCollectionFilename);
        }
      }}
      loading={isLoading}
    >
      {t("color_collections.actions.download.button")}
    </Button>
  );
};
