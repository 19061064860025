import { useTranslation } from "react-i18next";
import { Alert, AlertList, AlertWrapper } from "melco-ui";

type WarningsDetailsProps = {
  warningMessages: string[];
  onClose: () => void;
};

export const WarningsDetails: React.FC<WarningsDetailsProps> = ({
  warningMessages,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <AlertWrapper>
      <Alert
        withBackground
        message={t("global.action_header.warnings.title")}
        description={
          <AlertList>
            {warningMessages.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </AlertList>
        }
        type="warning"
        closable
        onClose={onClose}
      />
    </AlertWrapper>
  );
};
