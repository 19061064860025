import { useCurrentProductVariant } from "../../../hooks/useCurrentProductVariant";
import { PerformanceAPIService } from "./Performance.apiservice";
import { PerformanceFusion } from "./Performance.fusion";

export const Performance: React.FC = () => {
  const productVariant = useCurrentProductVariant();

  return productVariant === "fusion" ? (
    <PerformanceFusion />
  ) : (
    <PerformanceAPIService />
  );
};
