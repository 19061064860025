import {
  FusionApi,
  MelcoCoreModelsCustomProductListItem,
  useAPIQuery,
  useTable,
} from "melco-shared-logic";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import isEmpty from "lodash-es/isEmpty";
import { useTableErrorComponent } from "../../table/useTableErrorComponent";

export const useProducts = () => {
  const {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    currentPage,
    pageSize,
    sortBy,
    searchQuery,
    searchQueryChange,
  } = useTable<MelcoCoreModelsCustomProductListItem>();

  const query = useAPIQuery(
    FusionApi,
    ["products", currentPage, pageSize, filters, sortBy(), searchQuery],
    async (api) => {
      const response = await api.fusionCustomProductsSearch({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortBy(),
        publishState: ALL_PUBLISHED_STATES,
        searchText: !isEmpty(searchQuery) ? searchQuery : undefined,
      });

      setTotal(response.total);

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    searchQuery,
    searchQueryChange,
    errorComponent,
    ...query,
  };
};
