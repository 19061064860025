import { useCallback } from "react";
import {
  MelcoCoreModelsCustomProductImage,
  MelcoCoreModelsRendererMatrix,
} from "melco-shared-logic";
import { useField, useFormikContext } from "formik";
import { Rectangle, RectangleUtil } from "@melco/renderer";

const roundNumberToMatchBackendNumberPrecision = (input: number) =>
  parseFloat(input.toFixed(10));

const roundRect = (input: Rectangle) => {
  const { llx, lly, urx, ury } = input;

  return RectangleUtil.createRect(
    roundNumberToMatchBackendNumberPrecision(llx),
    roundNumberToMatchBackendNumberPrecision(lly),
    roundNumberToMatchBackendNumberPrecision(urx),
    roundNumberToMatchBackendNumberPrecision(ury)
  );
};

export const useUpdateRendererMetaData = (formikPrefix: string) => {
  const [{ value: colorList }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(formikPrefix);

  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext;

  const updateDesignMatrix = useCallback(
    (rendererMatrix: MelcoCoreModelsRendererMatrix) => {
      if (!colorList) {
        return;
      }

      colorList.forEach((item, idx) => {
        const design = (item.design_list ?? [])[0];

        if (design) {
          design.renderer_matrix = { ...rendererMatrix };

          const rendererMatrixFieldName = `${formikPrefix}[${idx}]design_list[0].renderer_matrix`;
          setFieldValue(rendererMatrixFieldName, {
            ...rendererMatrix,
          });
        }
      });
    },
    [colorList, formikPrefix, setFieldValue]
  );

  const updateBlankRect = useCallback(
    (rectangle: Rectangle) => {
      if (!colorList) {
        return;
      }

      colorList.forEach((_, idx) => {
        const blankRendererRectFieldName = `${formikPrefix}[${idx}]renderer_rect`;
        setFieldValue(blankRendererRectFieldName, { ...roundRect(rectangle) });
      });
    },
    [colorList, formikPrefix, setFieldValue]
  );

  return {
    updateDesignMatrix,
    updateBlankRect,
  };
};
