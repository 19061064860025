import { FullWidthSpace } from "melco-ui";
import { ProductsReferencesList } from "../../references/ProductsReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../references/ReferencesListAlert";
import { useBlankReferences } from "../../../hooks/libraries/blanks/useBlankReferences";

type BlankReferencesListProps = {
  blankId: string;
};

export const BlankReferencesList: React.FC<BlankReferencesListProps> = ({
  blankId,
}) => {
  const { t } = useTranslation();

  const { data: blankReferences } = useBlankReferences(blankId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert message={t("blanks.actions.references.warning")} />
      <ProductsReferencesList
        productList={blankReferences?.custom_product_list ?? undefined}
      />
    </FullWidthSpace>
  );
};
