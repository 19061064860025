import { createRef } from "react";
import { ApplicationLayout, DesignTokens } from "melco-ui";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import {
  ScrollToTop,
  TrialAlert,
  useSetApplicationLanguage,
} from "melco-shared-logic";
import styled from "styled-components";

import { FusionMainNavigation } from "./components/navigation/FusionMainNavigation";
import { FusionSecondaryNavigation } from "./components/navigation/FusionSecondaryNavigation";
import { MainContent } from "./MainContent";
import { ErrorBoundary } from "react-error-boundary";
import { ApplicationErrorBoundary } from "./components/errorboundary/ApplicationErrorBoundary";
import { useCurrentProductVariant } from "./hooks/useCurrentProductVariant";

function ErrorFallback() {
  return <ApplicationErrorBoundary />;
}

const { colors } = DesignTokens;

const StyledTrialAlert = styled(TrialAlert)`
  && a {
    color: ${colors.feedback.info};
  }

  && a:hover {
    color: ${colors.feedback.info};
  }
`;

export const Main: React.FC = () => {
  const { t } = useTranslation();
  const productVariant = useCurrentProductVariant();

  useSetApplicationLanguage();

  const contentAreaRef = createRef<HTMLDivElement>();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ScrollToTop contentAreaRef={contentAreaRef} />
        <ApplicationLayout
          mainNavigation={<FusionMainNavigation />}
          footer={t(`footer.copyright.${productVariant}`, {
            copyrightYear: new Date().getFullYear(),
          })}
          contentAreaRef={contentAreaRef}
          alert={<StyledTrialAlert />}
        >
          <MainContent />

          <FusionSecondaryNavigation />
        </ApplicationLayout>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
