import { StitchElementUtil } from "@melco/renderer";
import { BaseAction } from "@melco/renderer/dist/events";
import { ArrowsAltIcon, ShrinkIcon, CanvasActionGroupItem } from "melco-ui";
import { Dispatch, useCallback } from "react";
import { createPortal } from "react-dom";
import {
  FusionZoomToFitAction,
  ZoomedTo,
} from "../../../hooks/renderer/FusionRendererActions";
import { ZOOM_TOGGLE_BUTTON_PORTAL_ID } from "./ZoomToggleButtonPortal";

type ZoomToggleButtonProps = {
  zoomedTo: ZoomedTo;
  canvas: HTMLElement | undefined;
  rendererDispatch: Dispatch<BaseAction>;
  elementUtil: StitchElementUtil | undefined;
};

export const ZoomToggleButton: React.FC<ZoomToggleButtonProps> = ({
  zoomedTo,
  canvas,
  rendererDispatch,
  elementUtil,
}) => {
  const toggleZoom = useCallback(() => {
    if (!canvas || !elementUtil) {
      return;
    }
    const { clientWidth, clientHeight } = canvas;

    rendererDispatch(
      new FusionZoomToFitAction(
        "toggle",
        clientWidth,
        clientHeight,
        elementUtil
      )
    );
  }, [rendererDispatch, canvas, elementUtil]);

  const portalTarget = document.getElementById(ZOOM_TOGGLE_BUTTON_PORTAL_ID);

  if (!portalTarget) {
    return null;
  }

  return createPortal(
    <CanvasActionGroupItem onClick={toggleZoom}>
      {zoomedTo === "product" ? (
        <ArrowsAltIcon data-cy-id="zoom-to-fit" />
      ) : (
        <ShrinkIcon data-cy-id="zoom-to-fit" />
      )}
    </CanvasActionGroupItem>,
    document.getElementById(ZOOM_TOGGLE_BUTTON_PORTAL_ID)!
  );
};
