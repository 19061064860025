import { FullWidthSpace } from "melco-ui";
import { useColorCollectionReferences } from "../../../hooks/libraries/colorCollections/useColorCollectionReferences";
import { DesignsReferencesList } from "../../references/DesignsReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../references/ReferencesListAlert";
import { ColorCollectionReferencesListProps } from "./ColorCollectionReferencesList";

export const ColorCollectionReferencesListAPIService: React.FC<
  ColorCollectionReferencesListProps
> = ({ colorCollectionId }) => {
  const { t } = useTranslation();

  const { data: colorCollectionReferences } =
    useColorCollectionReferences(colorCollectionId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert
        message={t("color_collections.actions.references.warning")}
      />
      <DesignsReferencesList
        designList={colorCollectionReferences?.design_list ?? undefined}
      />
    </FullWidthSpace>
  );
};
