import { useCurrentProductVariant } from "../../../hooks/useCurrentProductVariant";
import { StatisticsCardRowAPIService } from "./StatisticsCardRow.apiservice";
import { StatisticsCardRowFusion } from "./StatisticsCardRow.fusion";

export type StatisticsCardRowProps = {
  startTimestamp?: Date;
  endTimestamp?: Date;
};

export const StatisticsCardRow: React.FC<StatisticsCardRowProps> = ({
  startTimestamp,
  endTimestamp,
}) => {
  const productVariant = useCurrentProductVariant();

  return productVariant === "fusion" ? (
    <StatisticsCardRowFusion
      startTimestamp={startTimestamp}
      endTimestamp={endTimestamp}
    />
  ) : (
    <StatisticsCardRowAPIService
      startTimestamp={startTimestamp}
      endTimestamp={endTimestamp}
    />
  );
};
