import styled from "styled-components";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";

const DesignsPreviewListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type DesignsPreviewListItemProps = {
  design: MelcoCoreModelsDesignListItem;
};

export const DesignsPreviewListItem: React.FC<DesignsPreviewListItemProps> = ({
  design,
}) => {
  const { name } = design;

  return <DesignsPreviewListItemWrapper>{name}</DesignsPreviewListItemWrapper>;
};
