import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useFontUpload = (service: "embroidery" | "print") => {
  const [uploadFont] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, file: Blob) => {
      const data = await api.userAlphabetsUploadDraft({
        service,
        file,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "fonts.actions.upload",
    }
  );

  return {
    uploadFont,
  } as const;
};
