import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { DesignsList } from "../../../components/libraries/designs/DesignsList";
import { DesignUploadButton } from "../../../components/libraries/designs/DesignUploadButton";

export const Designs: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("libraries.designs.title")}
          breadcrumb={breadcrumb}
          extra={<DesignUploadButton />}
        />
      }
    >
      <DesignsList />
    </MainContentArea>
  );
};
