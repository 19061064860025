import { useCallback, useEffect, useState } from "react";
import { useScript } from "../../useScript";

export const useProductPreview = (
  productToken: string | undefined,
  apiKey: string | undefined
) => {
  const [shouldLoadConfigurator, setShouldLoadConfigurator] = useState(false);
  const [productTokenToShow, showProductToken] = useState<string | undefined>(
    undefined
  );
  const [isShowingConfigurator, setIsShowingConfigurator] = useState(false);

  const src = shouldLoadConfigurator
    ? process.env.REACT_APP_CONFIGURATOR_JS!
    : null;

  const state = useScript(src);

  const showConfigurator = useCallback(
    (token: string | undefined) => {
      if (!token || !apiKey) {
        return;
      }

      const options = {
        onComplete: () => setIsShowingConfigurator(false),
        onCancel: () => setIsShowingConfigurator(false),
      };

      const Configurator = (window as any).MelcoConfigurator;

      if (Configurator) {
        const melcoConfigurator = new Configurator();
        melcoConfigurator.start(token, apiKey, options);
        setIsShowingConfigurator(true);
      }
    },
    [setIsShowingConfigurator, apiKey]
  );

  useEffect(() => {
    if (state === "ready" && productTokenToShow) {
      showConfigurator(productTokenToShow);
      showProductToken(undefined);
    }
  }, [state, showConfigurator, productTokenToShow]);

  const preview = useCallback(() => {
    if (!productToken) {
      return;
    }

    setShouldLoadConfigurator(true);
    showProductToken(productToken);
  }, [productToken]);

  const isLoading = state === "loading";

  return { isLoading, preview, isShowingConfigurator, shouldLoadConfigurator };
};
