import { ReactNode } from "react";
import { FormItem, InputNumber, InputNumberProps } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

type InputNumberFieldProps = {
  name: string;
  label?: string;
  info?: ReactNode;
};

const InputNumberField = (props: InputNumberFieldProps & InputNumberProps) => {
  const { t } = useTranslation();
  const { name, label, info, ...inputNumberProps } = props;
  const [field, meta, helpers] = useField<number | undefined>(name!);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
    >
      <InputNumber
        {...inputNumberProps}
        {...field}
        onChange={(value: number | undefined) => {
          helpers.setValue(value);
        }}
        onBlur={() => helpers.setTouched(true)}
      />
    </FormItem>
  );
};

export { InputNumberField };
