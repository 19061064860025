import { Select } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  ALL_ORDER_SESSION_STATUS,
  OrderSessionStatus,
} from "../../../helper/ordersHelper";

type SessionStatusFilterProps = {
  onChange: (value: OrderSessionStatus[]) => void;
  value?: OrderSessionStatus[];
};

export const SessionStatusFilter: React.FC<SessionStatusFilterProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const sessionStatusOptions = ALL_ORDER_SESSION_STATUS.split(",").map(
    (sessionStatusName) => ({
      value: sessionStatusName,
      label: t(`orders_list.session_status.${sessionStatusName}` as any),
    })
  );
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={t("orders_list.filter.session_status.placeholder")}
      style={{ width: "100%", minWidth: 150 }}
      maxTagCount={1}
      options={sessionStatusOptions}
      onChange={onChange}
      value={value}
    />
  );
};
