import { Formik, FormikHelpers } from "formik";
import {
  MelcoCoreModelsUserAlphabet,
  UseApiQueryKey,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import isEmpty from "lodash-es/isEmpty";
import { DisplayFontDetailForm } from "./DisplayFontDetailForm";
import { SubmitAction } from "../../header/SavePublishHeader";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { DirtyFormPrompt } from "../../form/DirtyFormPrompt";
import { queryKeyForFont } from "../../../hooks/libraries/fonts/useFont";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

type FontDetailFormProps = {
  font: MelcoCoreModelsUserAlphabet;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsUserAlphabet, unknown>
  >;
  referencesDrawer: DrawerType;
  queryKey: UseApiQueryKey;
};

type FontDetailFormValueType = {
  id: string;
  name: string;
  recommended_text?: string | null;
  file?: File[] | undefined;
  submitAction: SubmitAction;
};

export const fontToInitialValues = (font: MelcoCoreModelsUserAlphabet) => {
  const { id, name, recommended_text } = font;

  const initialValues: FontDetailFormValueType = {
    id: id ?? "",
    name: name ?? "",
    recommended_text,
    submitAction: "save",
  };

  return initialValues;
};

export const FontDetailForm: React.FC<FontDetailFormProps> = ({
  font,
  refetch,
  referencesDrawer,
  queryKey,
}) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = font;

  const onSubmit = async (
    values: FontDetailFormValueType,
    formikHelpers: FormikHelpers<FontDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: FontDetailFormValueType,
    formikHelpers: FormikHelpers<FontDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, file, recommended_text } = values;

    const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

    const newEntity = await api.userAlphabetUpdateDraft({
      id: id!,
      name,
      recommendedText: recommended_text,
      file: !isEmpty(file) ? file![0] : undefined,
    });

    if (publish) {
      const response = await api.userAlphabetPublish({
        id: id!,
      });

      // unsuccessful
      if (!response.result) {
        throw new Error();
      }

      queryClient.invalidateQueries(
        queryKeyForFont(font.id!, ALL_PUBLISHED_STATES)
      ); // refetch new entity state from server
    }

    // optimistically update ui
    formikHelpers.resetForm({
      values: fontToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: FontDetailFormValueType,
        formikHelpers: FormikHelpers<FontDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "libraries.fonts.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForFont(font.id!, ALL_PUBLISHED_STATES)
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = fontToInitialValues(font);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayFontDetailForm
          font={font}
          refetch={refetch}
          referencesDrawer={referencesDrawer}
        />
      </DirtyFormPrompt>
    </Formik>
  );
};
