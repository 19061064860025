import { UserAccountApi, useUserAccountAPI } from "melco-shared-logic";
import { useCallback } from "react";
import { BasicSettingsResult } from "./useBasicSettings";

const initialValue = { settings: undefined };

export const useApiServiceBasicSettings = () => {
  const handleRequest = useCallback(async (api: UserAccountApi) => {
    const { default_embroidery_production_file_format, measurement_unit } =
      await api.accountProductSettingsGetApiServiceAppSetting();

    return {
      settings: {
        default_embroidery_production_file_format,
        measurement_unit,
      },
    };
  }, []);

  return useUserAccountAPI<BasicSettingsResult>({
    initialValue,
    handleRequest,
  });
};
