import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

export const useDownloadColorCollection = (colorCollectionId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userColorCollectionsDownload({
        id: colorCollectionId,
        publishState: ALL_PUBLISHED_STATES,
        productCode: "DesignShop10",
      });

      return {
        successMessages: [],
        data,
      };
    },
    { translationPrefix: "global" }
  );
};
