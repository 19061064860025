import { useDesign } from "../../../hooks/libraries/designs/useDesign";

type DisplayNameOfDesignProps = {
  designId: string;
};

export const DisplayNameOfDesign: React.FC<DisplayNameOfDesignProps> = ({
  designId,
}) => {
  const {
    query: { data: design, isLoading },
  } = useDesign(designId, "published");

  if (isLoading) {
    return null;
  }

  return <>{design?.name ?? design?.id ?? ""}</>;
};
