import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { OrdersList } from "../../components/orders/OrdersList";

export const Orders: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("orders");

  return (
    <MainContentArea
      header={
        <ContentHeader title={t("orders.title")} breadcrumb={breadcrumb} />
      }
    >
      <OrdersList />
    </MainContentArea>
  );
};
