import { LogoutIcon, MenuItem, LoadingIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

type LogoutMenuItemProps = {
  key: string;
  isLoggingOut: boolean;
};

export const LogoutMenuItem: React.FC<LogoutMenuItemProps> = ({
  isLoggingOut,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      icon={isLoggingOut ? <LoadingIcon spin /> : <LogoutIcon />}
      disabled={isLoggingOut}
      {...rest}
    >
      {t("logout.menuTitle", { ns: "melco-common" })}
    </MenuItem>
  );
};
