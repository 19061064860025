import { InputField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { useDisableDefaultTextField } from "../../../hooks/libraries/designs/useDisableDefaultTextField";

type DefaultTextFieldProps = {
  formikPrefix: string;
};

export const DefaultTextField: React.FC<DefaultTextFieldProps> = ({
  formikPrefix,
}) => {
  const { t } = useTranslation();

  const { disabled } = useDisableDefaultTextField(formikPrefix);

  return (
    <InputField
      name={`${formikPrefix}.lettering.text`}
      label={t("libraries.designs.detail.form.label.text")}
      disabled={disabled}
    />
  );
};
