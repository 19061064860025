import {
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";
import { BasicSettingsFormValueType } from "./useBasicSettingsFormSubmit";

export const useApiServiceBasicSettingsFormSubmit = () => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const onSubmit = useFormSubmit<BasicSettingsFormValueType>(
    async (values: BasicSettingsFormValueType, { resetForm }) => {
      const { default_embroidery_production_file_format, measurement_unit } =
        values;

      const api = new UserAccountApi(authenticatedApiConfig());

      await api.accountProductSettingsEditApiServiceAppSetting({
        melcoCoreModelsFusionAppSetting: {
          default_embroidery_production_file_format:
            default_embroidery_production_file_format,
          measurement_unit,
        },
      });

      resetForm({ values });

      return { successMessages: [{ message: "default" }] };
    },
    { translationPrefix: "settings.basic" }
  );

  return onSubmit;
};
