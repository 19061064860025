import { useCallback, useState } from "react";
import { Button, CheckIcon, Col, Table, TableActionBarRow } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  DrawerType,
  MelcoCoreModelsDesignCollection,
  Search,
  ViewContext,
} from "melco-shared-logic";
import { useDesignCollections } from "../../../hooks/libraries/designCollections/useDesignCollections";
import { designCollectionsListColumns } from "../../libraries/designCollections/DesignCollectionsList";
import { useSetDesignCollectionForView } from "../../../hooks/customization/products/useSetDesignCollectionForView";
import { useFetchDesign } from "../../../hooks/libraries/designs/useFetchDesign";

type ChooseDesignCollectionListProps = {
  drawer: DrawerType;
  formikPrefix: string;
};

export const ChooseDesignCollectionList: React.FC<
  ChooseDesignCollectionListProps
> = ({ drawer, formikPrefix }) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useDesignCollections("published");

  const { t } = useTranslation();
  const { closeDrawer, drawerField } = drawer;
  const { isSelected } = drawerField;

  const setDesignCollection = useSetDesignCollectionForView(formikPrefix);

  const [selectedDesignCollectionId, setSelectedDesignCollectionId] = useState<
    string | undefined
  >(undefined);
  const [fetchDesign, isFetchingDesign] = useFetchDesign();

  const onSelect = useCallback(
    async (selectedDesignCollection: MelcoCoreModelsDesignCollection) => {
      setSelectedDesignCollectionId(selectedDesignCollection.id!);

      try {
        const { default_design } = selectedDesignCollection;

        if (default_design?.id) {
          const design = await fetchDesign(default_design.id!, "published");

          if (design) {
            setDesignCollection(selectedDesignCollection, design);
            closeDrawer();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSelectedDesignCollectionId(undefined);
      }
    },
    [
      setSelectedDesignCollectionId,
      fetchDesign,
      setDesignCollection,
      closeDrawer,
    ]
  );

  const columns = [
    ...designCollectionsListColumns(t, ViewContext.Drawer),
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, d: MelcoCoreModelsDesignCollection) => {
        return isSelected(d.id) ? (
          <Button type="primary" icon={<CheckIcon />} size="small" disabled>
            {t("products.actions.choose_design.actions.selected")}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<CheckIcon />}
            size="small"
            onClick={() => onSelect(d)}
            loading={isFetchingDesign && selectedDesignCollectionId === d.id}
          >
            {t("products.actions.choose_design.actions.select")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        noDataMessage={t("design_collections_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
