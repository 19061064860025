import { Col, DesignTokens, LabeledItem } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const { spacings, colors } = DesignTokens;

const StyledCol = styled(Col)`
  padding: ${spacings.small}px 0;
  &:nth-child(odd) {
    border-right: 1px solid ${colors.gray.light};
  }
`;

type DesignInfoItemProps = {
  label: string;
  value?: string | React.ReactNode;
};

export const DesignInfoItem: React.FC<DesignInfoItemProps> = ({
  label,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCol span={12}>
      <LabeledItem
        label={label}
        content={value}
        fallback={t("global.fallback.none")}
      />
    </StyledCol>
  );
};
