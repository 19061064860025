import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateProduct } from "../../../hooks/customization/products/useDuplicateProduct";

type ProductDuplicateButtonProps = {
  productId: string;
};

export const ProductDuplicateButton: React.FC<ProductDuplicateButtonProps> = ({
  productId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [duplicateProduct, isLoading] = useDuplicateProduct(productId);

  return (
    <Button
      key="product-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const product = await duplicateProduct();

        if (product) {
          navigate(`/customization/products/${product.id}`);
        }
      }}
      loading={isLoading}
    >
      {t("products.actions.duplicate.button")}
    </Button>
  );
};
