import { useTranslation } from "react-i18next";
import { DesignTokens, Space, Text } from "melco-ui";
import styled from "styled-components";
import { useBlankViews } from "../../../hooks/libraries/blanks/useBlankViews";
import { SortableList } from "../../shared/SortableList";

const { spacings } = DesignTokens;

const ViewName = styled.div`
  min-height: ${spacings.large}px;
  display: flex;
  align-items: center;
  padding: ${spacings.small}px;
`;

export const BlankViewsSortableList: React.FC = () => {
  const { t } = useTranslation();
  const [views, reorderViews] = useBlankViews();

  return (
    <div data-cy-id="views-sortable-list-wrapper">
      <Space direction="vertical" size="small">
        <Text>{t("libraries.blanks.blanks.detail.sort_views.title")}</Text>

        <SortableList
          items={views}
          onSortingChange={reorderViews}
          renderItem={(view) => {
            const { viewName } = view;
            return <ViewName>{viewName}</ViewName>;
          }}
        />
      </Space>
    </div>
  );
};
