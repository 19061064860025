import styled from "styled-components";
import { DesignTokens, DraggableItem } from "melco-ui";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

const { spacings } = DesignTokens;

const DraggableItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* to offset item padding */
  margin: -${spacings.xsmall}px;
`;

type SortableListProps<T> = {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onSortingChange: (sourceIndex: number, destinationIndex: number) => void;
};

export const SortableList = <T extends { key: string }>({
  items,
  renderItem,
  onSortingChange,
}: SortableListProps<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((el) => el.key === active.id);
      const newIndex = items.findIndex((el) => el.key === over.id);
      onSortingChange(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={items.map((item) => item.key)}
        strategy={rectSortingStrategy}
      >
        <DraggableItemWrapper>
          {items.map((item) => (
            <SortableItem key={item.key} id={item.key}>
              <DraggableItem>{renderItem(item)}</DraggableItem>
            </SortableItem>
          ))}
        </DraggableItemWrapper>
      </SortableContext>
    </DndContext>
  );
};
