import { useTranslation } from "react-i18next";
import { DesignTokens } from "melco-ui";
import styled from "styled-components";
import { publishStateIdToName } from "../../helper/publishedState";
import { ToggleWarningsLink } from "./ToggleWarningsLink";

const { colors } = DesignTokens;

type PublishStateColorProps = {
  color: string;
};

const PublishedStateWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["color"].includes(prop),
})<PublishStateColorProps>`
  color: ${({ color }) => color};
`;

const PublishStateIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => !["color"].includes(prop),
})`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

type PublishedStateProps = {
  publishedState: number;
  toggleWarnings?: () => void;
};

export const PublishedState: React.FC<PublishedStateProps> = ({
  publishedState,
  toggleWarnings,
}) => {
  const { t } = useTranslation();

  const publishedStateName = publishStateIdToName(publishedState);
  const color = colors.state[publishedStateName];

  return (
    <PublishedStateWrapper color={color} data-cy-id="published-state">
      <PublishStateIcon color={color} />
      {t(`global.versioning.states.titles.${publishedStateName}`)}

      {toggleWarnings && <ToggleWarningsLink toggleWarnings={toggleWarnings} />}
    </PublishedStateWrapper>
  );
};
