import { useDirtyFormPrompt } from "../../hooks/forms/useDirtyFormPrompt";

type DirtyFormPromptProps = {
  children: React.ReactNode;
};

export const DirtyFormPrompt: React.FC<DirtyFormPromptProps> = ({
  children,
}) => {
  useDirtyFormPrompt();
  return <>{children}</>;
};
