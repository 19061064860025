import { MainContentArea, ContentHeader } from "melco-ui";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../../hooks/navigation/useBreadcrumb";
import { ALL_PUBLISHED_STATES } from "../../../../helper/publishedState";
import { useBlankPicture } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPicture";
import { BlankPictureDetailForm } from "../../../../components/libraries/blanks/blankPictures/BlankPictureDetailForm";
import { BlankPictureDetailMoreActionButton } from "../../../../components/libraries/blanks/blankPictures/BlankPictureDetailMoreActionButton";
import { BlankPictureDuplicateButton } from "../../../../components/libraries/blanks/blankPictures/BlankPictureDuplicateButton";
import { useGenericDrawer } from "../../../../hooks/drawer/useGenericDrawer";
import { BlankPictureReferencesDrawer } from "../../../../components/libraries/blanks/blankPictures/BlankPictureReferencesDrawer";

export const BlankPictureDetails: React.FC = () => {
  const breadcrumb = useBreadcrumb("libraries", "librariesBlanksBlankPictures");

  const { blankPictureId } = useParams<{
    blankPictureId: string;
  }>();

  const {
    query: { data: blankPicture, refetch },
    queryKey,
  } = useBlankPicture(blankPictureId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useGenericDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={blankPicture?.name ?? ""}
          breadcrumb={breadcrumb}
          extra={
            <>
              <BlankPictureDuplicateButton
                blankPictureId={blankPictureId ?? ""}
              />
              {blankPicture && (
                <BlankPictureDetailMoreActionButton
                  blankPicture={blankPicture}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {blankPicture && (
        <>
          <BlankPictureDetailForm
            blankPicture={blankPicture}
            refetch={refetch}
            referencesDrawer={referencesDrawer}
            queryKey={queryKey}
          />
          {blankPicture.id && (
            <BlankPictureReferencesDrawer
              drawer={referencesDrawer}
              id={blankPicture.id}
            />
          )}
        </>
      )}
    </MainContentArea>
  );
};
