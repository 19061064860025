import { SortableList } from "../../shared/SortableList";
import { useDesignsSorting } from "../../../hooks/libraries/designCollections/useDesignsSorting";
import { DesignsListThumbnail } from "../designs/DesignsListThumbnail";

export const DesignsSortableList: React.FC = () => {
  const [designs, reorderDesigns] = useDesignsSorting();

  return (
    <div data-cy-id="designs-sortable-list-wrapper">
      <SortableList
        items={designs}
        onSortingChange={reorderDesigns}
        renderItem={(design) => <DesignsListThumbnail design={design} />}
      />
    </div>
  );
};
