import {
  MelcoCoreModelsUserBlankListItem,
  selectThumbnailForSize,
} from "melco-shared-logic";

export const useBlankThumbnailURL = (
  blank: MelcoCoreModelsUserBlankListItem,
  width: number,
  height: number
) => {
  const thumbnail = selectThumbnailForSize(
    blank.default_image?.previews ?? [],
    Math.max(width, height)
  );

  return thumbnail?.url;
};
