import { ReactNode } from "react";
import { FormItem, Input, InputProps } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import map from "lodash-es/map";

type FileUploadFieldProps = {
  name: string;
  label?: string;
  allowedFileExtensions?: string;
  maxFiles?: number;
  info?: ReactNode;
};

const FileUploadField = (props: FileUploadFieldProps & InputProps) => {
  const { t } = useTranslation();
  const { name, label, maxFiles, allowedFileExtensions, info, ...inputProps } =
    props;
  const [, meta, { setValue: setFileFieldValue }] = useField<
    File[] | undefined
  >(name);
  const { setFieldValue } = useFormikContext();

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
      info={info}
    >
      <Input
        {...inputProps}
        name={name}
        type="file"
        accept={allowedFileExtensions}
        multiple={(maxFiles ?? 0) > 0}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          let uploadedFiles: File[] = [];

          if (event.currentTarget.files) {
            uploadedFiles = map(event.currentTarget.files, (f) => f);
          }

          setFileFieldValue(uploadedFiles);

          // whenever a file is selected, make sure to change at least one field of the form to mark form as dirty
          setFieldValue(`${name}__DIRTY_TRACKING_WORKAROUND__`, Math.random());
        }}
      />
    </FormItem>
  );
};

export { FileUploadField };
