import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { message } from "melco-ui";
import { useField } from "formik";
import { isEmpty } from "lodash";
import {
  MelcoCoreModelsUserImage,
  useErrorNotification,
} from "melco-shared-logic";
import { deriveViewsFromBlankPictures } from "./useBlankViews";
import { useSetInitialCanvasSize } from "./useSetInitialCanvasSize";

type AddBlankPictureConstraint =
  | "maxPictureCountViolation"
  | "maxViewCountViolation"
  | "colorViewCombinationViolation";

export const MAX_BLANK_PICTURE_COUNT = 40;
export const MAX_VIEW_COUNT = 4;

export const useAddBlankPictures = () => {
  const { t } = useTranslation();

  const errorNotification = useErrorNotification();

  const [{ value: blankPictures }, , { setValue: setBlankPictures }] =
    useField<MelcoCoreModelsUserImage[]>("images");

  const blankPictureCount = (blankPictures ?? []).length;

  const viewsCount = deriveViewsFromBlankPictures(
    blankPictures ?? [],
    []
  ).length;

  const setInitialCanvasSize = useSetInitialCanvasSize();

  const addBlankPictures = useCallback(
    (toAdd: MelcoCoreModelsUserImage[]) => {
      const picturesToAdd: MelcoCoreModelsUserImage[] = [];
      let addedPicturesCount = 0;
      const blankPicturesAfterChange = [...blankPictures];
      const violatedConstraints: Set<AddBlankPictureConstraint> = new Set();

      // add as many blank pictures as possible without violating the constraints
      toAdd.forEach((picture) => {
        const isViolatingMaxPictureCount =
          blankPicturesAfterChange.length + 1 > MAX_BLANK_PICTURE_COUNT;

        if (isViolatingMaxPictureCount) {
          violatedConstraints.add("maxPictureCountViolation");
        }

        const isViolatingMaxViewCount =
          deriveViewsFromBlankPictures(
            [...blankPicturesAfterChange, picture],
            []
          ).length > MAX_VIEW_COUNT;

        if (isViolatingMaxViewCount) {
          violatedConstraints.add("maxViewCountViolation");
        }

        const isViolatingColorViewCombination = !!blankPicturesAfterChange.find(
          (bp) =>
            bp.view_name === picture.view_name &&
            bp.color_name === picture.color_name
        );

        if (isViolatingColorViewCombination) {
          violatedConstraints.add("colorViewCombinationViolation");
        }

        if (
          !isViolatingMaxPictureCount &&
          !isViolatingMaxViewCount &&
          !isViolatingColorViewCombination
        ) {
          picturesToAdd.push(picture);
          addedPicturesCount += 1;
          blankPicturesAfterChange.push(picture);
        }
      });

      if (!isEmpty(picturesToAdd)) {
        setBlankPictures([...blankPictures, ...picturesToAdd]);

        if (!isEmpty(toAdd)) {
          setInitialCanvasSize(toAdd[0]);
        }
      }

      if (addedPicturesCount > 0) {
        message.success(
          t("blanks.actions.add_blank_pictures.success.default", {
            count: addedPicturesCount,
          })
        );
      }

      if (violatedConstraints.size > 0) {
        const picturesToAddCount = toAdd.length;
        const failedToAddCount = picturesToAddCount - addedPicturesCount;

        errorNotification(
          "blanks.actions.add_blank_pictures.error.title",
          Array.from(violatedConstraints).map(
            (violatedConstraint) =>
              `blanks.actions.add_blank_pictures.error.${violatedConstraint}`
          ),
          {
            title: {
              count: picturesToAddCount,
              failedToAddCount,
              picturesToAddCount,
            },
            message: {
              maxViewsCount: MAX_VIEW_COUNT,
              maxPictureCount: MAX_BLANK_PICTURE_COUNT,
            },
          }
        );
      }

      // return which pictures have been added as there is a chance that not all images are added (when using a bulk action)
      return picturesToAdd;
    },
    [blankPictures, setBlankPictures, setInitialCanvasSize, errorNotification]
  );

  const removeBlankPictures = useCallback(
    (toRemove: MelcoCoreModelsUserImage[]) => {
      const idsToRemove = toRemove.map((bp) => bp.id!);

      setBlankPictures(
        blankPictures.filter((bp) => !idsToRemove.includes(bp.id!))
      );
    },
    [blankPictures, setBlankPictures]
  );

  const isAlreadyAdded = useCallback(
    (blankPicture: MelcoCoreModelsUserImage) =>
      blankPicture.id && blankPictures.find((b) => b.id === blankPicture.id),
    [blankPictures]
  );

  return {
    blankPictureCount,
    viewsCount,
    addBlankPictures,
    removeBlankPictures,
    isAlreadyAdded,
  };
};
