import {
  MelcoCoreModelsSessionDailySummary,
  ProductAnalyticsApi,
  useProductsAnalyticsAPI,
} from "melco-shared-logic";
import { useCallback } from "react";

type SessionsData = {
  dailySummary: MelcoCoreModelsSessionDailySummary[];
};

const initialValue = { dailySummary: [] };

export const useSessionsAnalytics = (
  startTimestamp?: Date,
  endTimestamp?: Date
) => {
  const handleRequest = useCallback(
    async (api: ProductAnalyticsApi) => {
      const response = await api.productAnalyticsDailySessionSummary({
        productCode: process.env.REACT_APP_PRODUCT_CODE!,
        startTimestamp,
        endTimestamp,
      });

      return {
        dailySummary: response.list,
      };
    },
    [startTimestamp, endTimestamp]
  );

  return useProductsAnalyticsAPI<SessionsData>({
    initialValue,
    handleRequest,
  });
};
