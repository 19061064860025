import { MainContentArea, ContentHeader, ValueOrFallback } from "melco-ui";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { useColorCollection } from "../../../hooks/libraries/colorCollections/useColorCollection";
import { useTranslation } from "react-i18next";
import { ColorCollectionDetailForm } from "../../../components/libraries/colorCollections/ColorCollectionDetailForm";
import { ColorCollectionDuplicateButton } from "../../../components/libraries/colorCollections/ColorCollectionDuplicateButton";
import { ColorCollectionsDownloadButton } from "../../../components/libraries/colorCollections/ColorCollectionDownloadButton";
import { ColorCollectionDetailMoreActionButton } from "../../../components/libraries/colorCollections/ColorCollectionDetailMoreActionButton";
import { ColorCollectionReferencesDrawer } from "../../../components/libraries/colorCollections/ColorCollectionReferencesDrawer";
import { useGenericDrawer } from "../../../hooks/drawer/useGenericDrawer";

export const ColorCollectionDetails: React.FC = () => {
  const breadcrumb = useBreadcrumb(
    "libraries",
    "librariesColorCollectionsEmbroidery"
  );

  const { t } = useTranslation();

  const { colorCollectionId } = useParams<{
    colorCollectionId: string;
  }>();

  const {
    query: { data: colorCollection, refetch },
    queryKey,
  } = useColorCollection(colorCollectionId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useGenericDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            colorCollection && (
              <ValueOrFallback
                value={colorCollection.name}
                fallback={t("global.fallback.empty")}
              />
            )
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              <ColorCollectionDuplicateButton
                colorCollectionId={colorCollectionId ?? ""}
              />
              <ColorCollectionsDownloadButton
                colorCollectionId={colorCollectionId ?? ""}
                colorCollectionFilename={`${
                  colorCollection?.name ?? colorCollectionId
                }.clr`}
              />
              {colorCollection && (
                <ColorCollectionDetailMoreActionButton
                  colorCollection={colorCollection}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {colorCollection && (
        <>
          <ColorCollectionDetailForm
            colorCollection={colorCollection}
            refetch={refetch}
            referencesDrawer={referencesDrawer}
            queryKey={queryKey}
          />
          {colorCollection.id && (
            <ColorCollectionReferencesDrawer
              drawer={referencesDrawer}
              id={colorCollection.id}
            />
          )}
        </>
      )}
    </MainContentArea>
  );
};
