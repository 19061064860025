import { useColorCollection } from "../../../hooks/libraries/colorCollections/useColorCollection";
import { DefaultColorSelectField } from "./DefaultColorSelectField";

type DefaultColorSelectProps = {
  formikPrefix: string;
  colorCollectionId: string;
};

export const DefaultColorSelect: React.FC<DefaultColorSelectProps> = ({
  formikPrefix,
  colorCollectionId,
}) => {
  const {
    query: { data: colorCollection, isLoading, isPreviousData, isFetching },
  } = useColorCollection(colorCollectionId, "published");

  return (
    <DefaultColorSelectField
      formikPrefix={formikPrefix}
      colorList={colorCollection?.color_list ?? []}
      isLoading={isPreviousData ? isFetching : isLoading}
    />
  );
};
