import { useTranslation } from "react-i18next";
import { ColorSwatch, DesignTokens, Space, Text } from "melco-ui";
import { SortableList } from "../../shared/SortableList";
import { useColorsSorting } from "../../../hooks/libraries/colorCollections/useColorsSorting";

const { spacings } = DesignTokens;

export const ColorsSortableList: React.FC = () => {
  const { t } = useTranslation();
  const [colors, reorderColors] = useColorsSorting();

  return (
    <div data-cy-id="colors-sortable-list-wrapper">
      <Space direction="vertical" size="small">
        <Text>
          {t("libraries.color_collections.detail.sort_sider.subtitle")}
        </Text>

        <SortableList
          items={colors}
          onSortingChange={reorderColors}
          renderItem={(color) => {
            return (
              <ColorSwatch
                argb={color?.color?.argb ?? undefined}
                size={spacings.xlarge}
              />
            );
          }}
        />
      </Space>
    </div>
  );
};
