import { SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "melco-ui";
import { useHoopsDropdown } from "../../../hooks/libraries/hoops/useHoopsDropdown";

type HoopSelectProps = {
  name: string;
};

export const HoopSelect: React.FC<HoopSelectProps> = ({ name }) => {
  const { t } = useTranslation();

  const { isLoading, data } = useHoopsDropdown();

  return (
    <ConfigProvider
      renderEmpty={() => (
        <>{t("customization.products.detail.form.label.hoop_empty")}</>
      )}
    >
      <SelectField
        name={name}
        label={t("customization.products.detail.form.label.hoop")}
        loading={isLoading}
        style={{ width: "100%" }}
        options={data}
        allowClear
        showSearch
        optionFilterProp="label"
        filterOption
      />
    </ConfigProvider>
  );
};
