import {
  SystemManagementApi,
  useSystemManagementAPI,
} from "melco-shared-logic";
import { useCallback } from "react";
import { Settings } from "./useSettings";

const initialValue = {
  settings: undefined,
};

export const useAPIServiceSettings = () => {
  const handleRequest = useCallback(async (api: SystemManagementApi) => {
    const settings = await api.productSettingsGetApiServiceAppSetting();
    return { settings };
  }, []);

  return useSystemManagementAPI<Settings>({
    initialValue,
    handleRequest,
  });
};
