import { useAPIQuery, UserAccountApi } from "melco-shared-logic";

export const CONFIGURATOR_API_KEY_PRODUCT_CODE = "FUSION";
export const CONFIGURATOR_API_KEY_SCOPE = "Configurator";

export const useConfiguratorAPIKey = () => {
  return useAPIQuery(
    UserAccountApi,
    ["configuratorAPIKeys"],
    async (api) => {
      const { list } = await api.accountsApiKeysGetList({
        offset: 0,
        limit: 100,
        productCode: CONFIGURATOR_API_KEY_PRODUCT_CODE,
      });

      const apiKey = list.find(
        (apiKey) =>
          apiKey.product_code === CONFIGURATOR_API_KEY_PRODUCT_CODE &&
          apiKey.scope === CONFIGURATOR_API_KEY_SCOPE
      );

      return apiKey;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );
};
