import {
  MelcoCoreModelsColorCollection,
  useAPIAction,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { useFormikContext } from "formik";
import { QueryObserverResult } from "react-query";
import { colorCollectionToInitialValues } from "../../../components/libraries/colorCollections/ColorCollectionDetailForm";

export const useRevertColorCollection = (
  colorCollectionId: string,
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsColorCollection, unknown>
  >
) => {
  const { resetForm } = useFormikContext();

  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userColorCollectionsRevert({
        id: colorCollectionId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "libraries.color_collections.detail.revert",
      onSuccess: async () => {
        const refetchResult = await refetch();

        resetForm({
          values: refetchResult.data
            ? colorCollectionToInitialValues(refetchResult.data)
            : {},
        });
      },
    }
  );
};
