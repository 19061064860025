import { Formik, FormikHelpers } from "formik";
import {
  MelcoCoreModelsDesign,
  MelcoCoreModelsDesignElement,
  UseApiQueryKey,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserContentsDraftPublishApi,
  UserContentsDraftPublishApiUserDesignsUpdateDraftRequest,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import { SubmitAction } from "../../header/SavePublishHeader";
import { DisplayDesignDetailForm } from "./DisplayDesignDetailForm";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { DirtyFormPrompt } from "../../form/DirtyFormPrompt";
import { queryKeyForDesign } from "../../../hooks/libraries/designs/useDesign";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

type DesignDetailFormProps = {
  design: MelcoCoreModelsDesign;
  refetch: () => Promise<QueryObserverResult<MelcoCoreModelsDesign, unknown>>;
  referencesDrawer?: DrawerType;
  queryKey: UseApiQueryKey;
};

type DesignDetailFormValueType = {
  id: string;
  name: string;
  element_list: MelcoCoreModelsDesignElement[];
  submitAction: SubmitAction;
};

export const designToInitialValues = (design: MelcoCoreModelsDesign) => {
  const { id, name, element_list } = design;

  const initialValues: DesignDetailFormValueType = {
    id: id!,
    name: name ?? "",
    element_list: element_list ?? [],
    submitAction: "save",
  };

  return initialValues;
};

export const DesignDetailForm: React.FC<DesignDetailFormProps> = ({
  design,
  refetch,
  referencesDrawer,
  queryKey,
}) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = design;

  const onSubmit = async (
    values: DesignDetailFormValueType,
    formikHelpers: FormikHelpers<DesignDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: DesignDetailFormValueType,
    formikHelpers: FormikHelpers<DesignDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, element_list } = values;

    const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

    const entityAttributes: UserContentsDraftPublishApiUserDesignsUpdateDraftRequest =
      {
        id: id!,
        melcoCoreModelsUpdateDesign: {
          name,
          element_list,
        },
      };

    let newEntity = await api.userDesignsUpdateDraft(entityAttributes);

    if (publish) {
      newEntity = await api.userDesignsPublish({
        id: id!,
      });
    }

    // update ui with new data
    formikHelpers.resetForm({
      values: designToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: DesignDetailFormValueType,
        formikHelpers: FormikHelpers<DesignDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "libraries.designs.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForDesign(design.id!, ALL_PUBLISHED_STATES)
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = designToInitialValues(design);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayDesignDetailForm
          design={design}
          refetch={refetch}
          referencesDrawer={referencesDrawer}
        />
      </DirtyFormPrompt>
    </Formik>
  );
};
