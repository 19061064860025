import { useDesign } from "../../../hooks/libraries/designs/useDesign";
import { useDesignElementConfigurationFromDesign } from "../../../hooks/libraries/designs/useDesignElementConfigurationFromDesign";
import { RenderedDesign } from "../../renderer/RenderedDesign";

type DesignPreviewProps = {
  designId: string;
};

export const DesignPreview: React.FC<DesignPreviewProps> = ({ designId }) => {
  // we need to fetch a full design object here, otherwise we are missing the element list which is needed for the renderer to work
  const {
    query: { data: design },
  } = useDesign(designId, "published");

  const elementConfiguration = useDesignElementConfigurationFromDesign(design);

  if (!design || !elementConfiguration) {
    return null;
  }

  return (
    <RenderedDesign
      design={design}
      elementConfiguration={elementConfiguration}
      showOrigin
    />
  );
};
