import { useCurrentProductVariant } from "../../../hooks/useCurrentProductVariant";
import { ColorCollectionReferencesListAPIService } from "./ColorCollectionReferencesList.apiservice";
import { ColorCollectionReferencesListFusion } from "./ColorCollectionReferencesList.fusion";

export type ColorCollectionReferencesListProps = {
  colorCollectionId: string;
};

export const ColorCollectionReferencesList: React.FC<
  ColorCollectionReferencesListProps
> = ({ colorCollectionId }) => {
  const productVariant = useCurrentProductVariant();
  return productVariant === "fusion" ? (
    <ColorCollectionReferencesListFusion
      colorCollectionId={colorCollectionId}
    />
  ) : (
    <ColorCollectionReferencesListAPIService
      colorCollectionId={colorCollectionId}
    />
  );
};
