import { useCallback } from "react";
import {
  MelcoCoreModelsCustomProductImage,
  MelcoCoreModelsCustomProductImageDesign,
  MelcoCoreModelsDesign,
  MelcoCoreModelsDesignCollection,
} from "melco-shared-logic";
import { useField } from "formik";

export const useSetDesignCollectionForView = (formikPrefix: string) => {
  const [{ value }, , { setValue }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(`${formikPrefix}.color_list`);

  const setDesignCollection = useCallback(
    (
      designCollection?: MelcoCoreModelsDesignCollection,
      design?: MelcoCoreModelsDesign
    ) => {
      const newColorList = (value ?? []).map((vl) => {
        const designList: MelcoCoreModelsCustomProductImageDesign[] =
          designCollection
            ? [
                {
                  design,
                  design_collection: { id: designCollection?.id },
                  renderer_rect: {},
                  renderer_matrix: {
                    m00: 1,
                    m01: 0,
                    m02: 0,
                    m10: 0,
                    m11: 1,
                    m12: 0,
                  },
                },
              ]
            : [];

        return { ...vl, design_list: designList };
      });

      setValue(newColorList);
    },
    [value, setValue]
  );

  return setDesignCollection;
};
