import {
  BulkActionCount,
  ColorInputField,
  DisplayFieldErrors,
  InputField,
  MelcoCoreModelsColorListItem,
  useTableSelection,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import {
  Button,
  DesignTokens,
  FullWidthSpace,
  PlusIcon,
  Space,
  Table,
  Title,
  ValueOrFallback,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import get from "lodash-es/get";
import { BulkActionRemoveColors } from "./BulkActionRemoveColors";
import { useAddColors } from "../../../hooks/libraries/colorCollections/useAddColors";
import { useColorListIndex } from "../../../hooks/libraries/colorCollections/useColorListIndex";

const { colors, spacings } = DesignTokens;

const EmptyColorCollectionWrapper = styled.div`
  border: 1px dashed ${colors.gray.middle};
  padding: ${spacings.middle}px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  margin-left: -${spacings.xlarge}px;
  margin-right: -${spacings.xlarge}px;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type ColorCollectionColorsListProps = {
  setDrawerOpen: (open: boolean) => void;
};

export const ColorCollectionColorsList: React.FC<
  ColorCollectionColorsListProps
> = ({ setDrawerOpen }) => {
  const { t } = useTranslation();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection } =
    useTableSelection<MelcoCoreModelsColorListItem>(() => {});

  const { colors } = useAddColors();

  const getColorListIndexForColorId = useColorListIndex();

  const title = (
    <Title level={5}>{t("color_collections.actions.add_colors.title")}</Title>
  );

  const addButton = (
    <Button
      type="primary"
      icon={<PlusIcon />}
      onClick={() => setDrawerOpen(true)}
    >
      {t("color_collections.actions.add_colors.button")}
    </Button>
  );

  if (isEmpty(colors)) {
    return (
      <div>
        {title}

        <DisplayFieldErrors name="color_list" />

        <EmptyColorCollectionWrapper>{addButton}</EmptyColorCollectionWrapper>
      </div>
    );
  }

  const sorter = (dataIndex: string) => (a: any, b: any) =>
    String(get(a, dataIndex)).localeCompare(String(get(b, dataIndex)));

  const columns = [
    {
      title: t("threads_list.columns.code"),
      key: "code",
      dataIndex: "code",
      sorter: sorter("code"),
      render: (_: string, item: MelcoCoreModelsColorListItem) => (
        <ValueOrFallback
          value={item.code}
          fallback={t("global.fallback.empty")}
        />
      ),
      width: 150,
    },
    {
      title: t("threads_list.columns.color"),
      key: "color",
      dataIndex: "color",
      sorter: sorter("color.argb"),
      render: (_: string, item: MelcoCoreModelsColorListItem) => {
        if (item.id == null) {
          return null;
        }

        const index = getColorListIndexForColorId(item.id);
        if (index == null) {
          return null;
        }

        return <ColorInputField name={`color_list[${index}].color.argb`} />;
      },
    },
    {
      title: t("threads_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: sorter("name"),
      render: (_: string, item: MelcoCoreModelsColorListItem) => {
        if (item.id == null) {
          return null;
        }

        const index = getColorListIndexForColorId(item.id);
        if (index == null) {
          return null;
        }

        return (
          <InputField
            name={`color_list[${index}].name`}
            data-cy-id="color-list-item-name"
          />
        );
      },
    },
    {
      title: t("threads_list.columns.supplier_name"),
      key: "supplier_name",
      dataIndex: "supplier_name",
      sorter: sorter("supplier_name"),
      render: (_: string, item: MelcoCoreModelsColorListItem) => (
        <ValueOrFallback
          value={item.supplier_name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("threads_list.columns.product_name"),
      key: "product_name",
      dataIndex: "product_name",
      sorter: sorter("product_name"),
      render: (_: string, item: MelcoCoreModelsColorListItem) => (
        <ValueOrFallback
          value={item.product_name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
  ];

  return (
    <div>
      <TableHeader>
        {title}

        <Space size="middle">
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionRemoveColors
            allColors={colors}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </Space>
      </TableHeader>

      <DisplayFieldErrors name="color_list" />

      <FullWidthSpace direction="vertical" size="large">
        <TableWrapper>
          <Table
            showSorterTooltip={false}
            dataSource={colors}
            columns={columns}
            rowKey="id"
            pagination={false}
            rowSelection={rowSelection}
            data-cy="colors-table"
          />
        </TableWrapper>
        <AddButtonWrapper>{addButton}</AddButtonWrapper>
      </FullWidthSpace>
    </div>
  );
};
