import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "./screens/dashboard/Dashboard";
import { Error404 } from "./screens/404/Error404";
import { Products } from "./screens/customization/products/Products";
import { ProductDetails } from "./screens/customization/products/ProductDetails";
import { BasicSettings } from "./screens/settings/BasicSettings";
import { Designs } from "./screens/libraries/designs/Designs";
import { DesignDetails } from "./screens/libraries/designs/DesignDetails";
import { Blanks } from "./screens/libraries/blanks/Blanks";
import { BlankDetails } from "./screens/libraries/blanks/BlankDetails";
import { BlankPictures } from "./screens/libraries/blanks/blankPictures/BlankPictures";
import { BlankPictureDetails } from "./screens/libraries/blanks/blankPictures/BlankPictureDetails";
import { Alphabets } from "./screens/libraries/fonts/Alphabets";
import { FontDetails } from "./screens/libraries/fonts/FontDetails";
import { ColorCollections } from "./screens/libraries/colorCollections/ColorCollections";
import { ColorCollectionDetails } from "./screens/libraries/colorCollections/ColorCollectionDetails";
import { Orders } from "./screens/orders/Orders";
import { DesignCollections } from "./screens/libraries/designCollections/DesignCollections";
import { DesignCollectionDetails } from "./screens/libraries/designCollections/DesignCollectionDetails";
import { ConfiguratorSettings } from "./screens/settings/ConfiguratorSettings";

export const MainContentFusion: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />

      <Route path="orders">
        <Route path="" element={<Orders />} />
        {/* <Route path=":orderId" element={<OrderDetails />} /> */}
      </Route>

      <Route path="customization">
        <Route path="" element={<Navigate to="products" replace />} />
        <Route path="products">
          <Route path="" element={<Products />} />
          <Route path=":productId" element={<ProductDetails />} />
        </Route>
      </Route>

      <Route path="libraries">
        <Route path="" element={<Navigate to="designs" replace />} />

        <Route path="designs">
          <Route path="" element={<Designs />} />
          <Route path=":designId" element={<DesignDetails />} />
        </Route>

        <Route path="design-collections">
          <Route path="" element={<DesignCollections />} />
          <Route
            path=":designCollectionId"
            element={<DesignCollectionDetails />}
          />
        </Route>

        <Route path="blanks">
          <Route path="" element={<Blanks />} />
          <Route path=":blankId" element={<BlankDetails />} />
        </Route>

        <Route path="blank-pictures">
          <Route path="" element={<BlankPictures />} />
          <Route path=":blankPictureId" element={<BlankPictureDetails />} />
        </Route>

        <Route path="fonts">
          <Route path="" element={<Navigate to="embroidery" replace />} />
          <Route path="embroidery">
            <Route path="" element={<Alphabets />} />
            <Route path=":fontId" element={<FontDetails />} />
          </Route>
        </Route>

        <Route path="color-collections">
          <Route path="" element={<Navigate to="embroidery" replace />} />
          <Route path="embroidery">
            <Route path="" element={<ColorCollections />} />
            <Route
              path=":colorCollectionId"
              element={<ColorCollectionDetails />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="settings">
        <Route path="" element={<Navigate to="basic" replace />} />
        <Route path="basic" element={<BasicSettings />} />
        <Route path="configurator" element={<ConfiguratorSettings />} />
      </Route>

      <Route path="login/support" element={<Navigate to="/" replace />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
