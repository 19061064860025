import {
  DisplayFormErrors,
  InputField,
  MelcoCoreModelsDesignCollection,
  MelcoCoreModelsDesignListItem,
  VerticalForm,
  DebugForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import {
  Col,
  DesignTokens,
  FullWidthSpace,
  PreviewSider,
  Row,
  Title,
} from "melco-ui";
import { useField } from "formik";
import { isEmpty } from "lodash-es";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertDesignCollection } from "../../../hooks/libraries/designCollections/useRevertDesignCollection";
import { AddDesignsDrawer } from "./AddDesignsDrawer";
import { DesignCollectionDesignsList } from "./DesignCollectionDesignsList";
import { DesignsSortableList } from "./DesignsSortableList";
import { useDesignCollectionReferencesCount } from "../../../hooks/libraries/designCollections/useDesignCollectionReferencesCount";
import {
  Drawer as DrawerType,
  useGenericDrawer,
} from "../../../hooks/drawer/useGenericDrawer";
import { DesignsPreview } from "./DesignsPreview";

const { spacings } = DesignTokens;

type DisplayDesignCollectionDetailFormProps = {
  designCollection: MelcoCoreModelsDesignCollection;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsDesignCollection, unknown>
  >;
  referencesDrawer: DrawerType;
};

export const DisplayDesignCollectionDetailForm: React.FC<
  DisplayDesignCollectionDetailFormProps
> = ({ designCollection, refetch, referencesDrawer }) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = designCollection;

  const { t } = useTranslation();
  const [revertDesignCollection, isLoading] = useRevertDesignCollection(
    id!,
    refetch
  );

  const addDesignsDrawer = useGenericDrawer();

  const [{ value: designs }] =
    useField<MelcoCoreModelsDesignListItem[]>("design_list");

  const { total: referencesCount } = useDesignCollectionReferencesCount(id!);

  return (
    <>
      <SavePublishHeader
        revertAction={revertDesignCollection}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <DisplayFormErrors />

      <Row gutter={[spacings.xlarge, spacings.xlarge]}>
        <Col xs={isEmpty(designs) ? 24 : 16}>
          <VerticalForm>
            <FullWidthSpace direction="vertical">
              <div>
                <InputField
                  name="name"
                  label={t(
                    "libraries.design_collections.detail.form.label.name"
                  )}
                />
              </div>

              <DesignCollectionDesignsList drawer={addDesignsDrawer} />
            </FullWidthSpace>
          </VerticalForm>

          <AddDesignsDrawer drawer={addDesignsDrawer} />
        </Col>

        {!isEmpty(designs) && (
          <Col xs={8}>
            <PreviewSider>
              <FullWidthSpace direction="vertical" size="small">
                <Title level={5}>
                  {t("libraries.design_collections.detail.sort_sider.title")}
                </Title>
                <div>
                  <DesignsSortableList />
                </div>
              </FullWidthSpace>
            </PreviewSider>
          </Col>
        )}
      </Row>

      {!isEmpty(designs) && <DesignsPreview designs={designs ?? []} />}

      {false && <DebugForm />}
    </>
  );
};
