import { useCallback } from "react";
import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDeleteFonts = () => {
  const [deleteFont] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, id: string) => {
      const data = await api.userAlphabetDelete({ id, force: true });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "fonts.actions.delete",
    }
  );

  return useCallback(
    async (fontIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const fontId of fontIds) {
        try {
          await deleteFont(fontId);
          successfullyDeleted.push(fontId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteFont]
  );
};
