import { useEffect } from "react";
import { MelcoCoreModelsCustomProductImage } from "melco-shared-logic";
import { useField, useFormikContext } from "formik";

const formikPath = (
  formikPrefix: string,
  fieldName: string,
  colorListIndex: number = 0
) =>
  `${formikPrefix}.color_list[${colorListIndex}].design_list[0].${fieldName}`;

export const useDesignAttributes = (formikPrefix: string) => {
  const [{ value: colorList }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(`${formikPrefix}.color_list`);

  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext;

  const hoopFieldName = formikPath(formikPrefix, "hoop.id");
  const productionRotationDegreeFieldName = formikPath(
    formikPrefix,
    "production_rotation_degree"
  );
  const productionInstructionsFieldName = formikPath(
    formikPrefix,
    "production_instructions"
  );

  // all those field values need to be synced some they are the same for each color variation
  const [{ value: hoopId }] = useField<string | undefined>(hoopFieldName);

  useEffect(() => {
    colorList?.forEach((_, idx) => {
      idx !== 0 &&
        setFieldValue(formikPath(formikPrefix, "hoop.id", idx), hoopId);
    });
  }, [hoopId, colorList, setFieldValue, formikPrefix]);

  const [{ value: productionRotationDegree }] = useField<number | undefined>(
    productionRotationDegreeFieldName
  );

  useEffect(() => {
    colorList?.forEach((_, idx) => {
      idx !== 0 &&
        setFieldValue(
          formikPath(formikPrefix, "production_rotation_degree", idx),
          productionRotationDegree
        );
    });
  }, [productionRotationDegree, colorList, setFieldValue, formikPrefix]);

  const [{ value: productionInstructions }] = useField<string | undefined>(
    productionInstructionsFieldName
  );

  useEffect(() => {
    colorList?.forEach((_, idx) => {
      idx !== 0 &&
        setFieldValue(
          formikPath(formikPrefix, "production_instructions", idx),
          productionInstructions
        );
    });
  }, [productionInstructions, colorList, setFieldValue, formikPrefix]);

  return {
    hoopFieldName,
    productionRotationDegreeFieldName,
    productionInstructionsFieldName,
  };
};
