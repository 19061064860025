import { Select } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  ALL_ORDER_PRODUCTION_STATUS,
  OrderProductionStatus,
} from "../../../helper/ordersHelper";

type ProductionStatusFilterProps = {
  onChange: (value: OrderProductionStatus[]) => void;
  value?: OrderProductionStatus[];
};

export const ProductionStatusFilter: React.FC<ProductionStatusFilterProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const productionStatusOptions = ALL_ORDER_PRODUCTION_STATUS.split(",").map(
    (productionStatusName) => ({
      value: productionStatusName,
      label: t(`orders_list.production_status.${productionStatusName}` as any),
    })
  );

  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={t("orders_list.filter.production_status.placeholder")}
      style={{ width: "100%", minWidth: 150 }}
      maxTagCount={1}
      options={productionStatusOptions}
      onChange={onChange}
      value={value}
    />
  );
};
