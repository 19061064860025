import { Formik, FormikHelpers } from "formik";
import {
  MelcoCoreModelsUserBlank,
  MelcoCoreModelsUserImage,
  UseApiQueryKey,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserContentsDraftPublishApi,
  UserContentsDraftPublishApiUserBlankUpdateDraftRequest,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import { SubmitAction } from "../../header/SavePublishHeader";
import { DisplayBlankDetailForm } from "./DisplayBlankDetailForm";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { DirtyFormPrompt } from "../../form/DirtyFormPrompt";
import { queryKeyForBlank } from "../../../hooks/libraries/blanks/useBlank";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

type BlankDetailFormProps = {
  blank: MelcoCoreModelsUserBlank;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsUserBlank, unknown>
  >;
  referencesDrawer: DrawerType;
  queryKey: UseApiQueryKey;
};

type BlankDetailFormValueType = {
  id: string;
  name: string;
  canvas_width?: number;
  canvas_height?: number;
  images: MelcoCoreModelsUserImage[];
  colors: string[];
  views: string[];
  submitAction: SubmitAction;
};

export const blankToInitialValues = (blank: MelcoCoreModelsUserBlank) => {
  const { id, name, canvas_width, canvas_height, images, colors, views } =
    blank;

  const initialValues: BlankDetailFormValueType = {
    id: id!,
    name: name ?? "",
    canvas_width: canvas_width,
    canvas_height: canvas_height,
    images: images ?? [],
    colors: colors ?? [],
    views: views ?? [],
    submitAction: "save",
  };

  return initialValues;
};

export const BlankDetailForm: React.FC<BlankDetailFormProps> = ({
  blank,
  refetch,
  referencesDrawer,
  queryKey,
}) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = blank;

  const onSubmit = async (
    values: BlankDetailFormValueType,
    formikHelpers: FormikHelpers<BlankDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: BlankDetailFormValueType,
    formikHelpers: FormikHelpers<BlankDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, canvas_width, canvas_height, images, colors, views } = values;

    const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

    const entityAttributes: UserContentsDraftPublishApiUserBlankUpdateDraftRequest =
      {
        id: id!,
        melcoCoreModelsUpdateBlank: {
          name,
          canvas_width: canvas_width,
          canvas_height: canvas_height,
          images,
          colors,
          views,
        },
      };

    let newEntity = await api.userBlankUpdateDraft(entityAttributes);

    if (publish) {
      newEntity = await api.userBlankPublish({
        id: id!,
      });
    }

    // update ui with new data
    formikHelpers.resetForm({
      values: blankToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: BlankDetailFormValueType,
        formikHelpers: FormikHelpers<BlankDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "libraries.blanks.blanks.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForBlank(blank.id!, ALL_PUBLISHED_STATES)
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = blankToInitialValues(blank);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayBlankDetailForm
          blank={blank}
          refetch={refetch}
          referencesDrawer={referencesDrawer}
        />
      </DirtyFormPrompt>
    </Formik>
  );
};
