import { Col, Table, TableActionBarRow, ValueOrFallback } from "melco-ui";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useThreads } from "../../../hooks/libraries/colors/useThreads";
import { ColorCode } from "../../table/ColorCode";
import { MelcoCoreModelsThread, Search } from "melco-shared-logic";

type ThreadsListProps = {
  productId: string;
};

export const threadsListColumns = (t: TFunction) => [
  {
    title: t("threads_list.columns.code"),
    key: "code",
    dataIndex: "code",
    sorter: true,
    render: (_: string, item: MelcoCoreModelsThread) => {
      const { code, color } = item;
      return <ColorCode code={code ?? ""} argb={color?.argb ?? ""} />;
    },
    width: 150,
  },
  {
    title: t("threads_list.columns.name"),
    key: "name",
    dataIndex: "name",
    sorter: true,
    render: (_: string, item: MelcoCoreModelsThread) => (
      <ValueOrFallback
        value={item.name}
        fallback={t("global.fallback.empty")}
      />
    ),
  },
  {
    title: t("threads_list.columns.supplier_name"),
    key: "supplier_name",
    dataIndex: "supplier_name",
    sorter: true,
    render: (_: string, item: MelcoCoreModelsThread) => (
      <ValueOrFallback
        value={item.supplier_name}
        fallback={t("global.fallback.empty")}
      />
    ),
  },
  {
    title: t("threads_list.columns.product_name"),
    key: "product_name",
    dataIndex: "product_name",
    sorter: true,
    render: (_: string, item: MelcoCoreModelsThread) => (
      <ValueOrFallback
        value={item.product_name}
        fallback={t("global.fallback.empty")}
      />
    ),
  },
];

export const ThreadsList: React.FC<ThreadsListProps> = ({ productId }) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useThreads(productId);
  const { t } = useTranslation();

  const columns = threadsListColumns(t);

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        noDataMessage={t("threads_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
