import { useCurrentProductVariant } from "./hooks/useCurrentProductVariant";
import { MainContentFusion } from "./MainContent.fusion";
import { MainContentAPIService } from "./MainContent.apiservice";

export const MainContent: React.FC = () => {
  const productVariant = useCurrentProductVariant();

  return productVariant === "fusion" ? (
    <MainContentFusion />
  ) : (
    <MainContentAPIService />
  );
};
