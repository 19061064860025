import { useCallback } from "react";
import { useField } from "formik";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import { moveArray } from "../../../helper/moveArray";

export const useDesignsSorting = () => {
  const [{ value: designs }, , { setValue: setDesigns, setError }] =
    useField<MelcoCoreModelsDesignListItem[]>("design_list");

  const reorderDesigns = useCallback(
    (fromIndex: number, toIndex: number) => {
      setDesigns(moveArray(designs, fromIndex, toIndex));

      // reset field error as sorting changes the index positions which could lead to errors being assigned to the wrong field
      setError(undefined);
    },
    [designs, setDesigns, setError]
  );

  const designsWithKey = (designs ?? []).map((c) => ({ ...c, key: c.id! }));

  return [designsWithKey, reorderDesigns] as const;
};
