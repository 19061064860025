import {
  FusionApi,
  MelcoCoreModelsCustomProductView,
  MelcoCoreModelsUserBlankListItem,
  useAPIAction,
} from "melco-shared-logic";

export const useFixProductSchema = () => {
  return useAPIAction(
    FusionApi,
    async (
      api,
      blank: MelcoCoreModelsUserBlankListItem,
      view_list?: MelcoCoreModelsCustomProductView[]
    ) => {
      const data = await api.fusionCustomProductsFixSchema({
        melcoCoreModelsCustomProduct: {
          blank: {
            id: blank.id,
          },
          view_list,
        },
      });

      return {
        successMessages: [],
        data,
      };
    },
    {
      translationPrefix: "products.actions.change_blank",
    }
  );
};
