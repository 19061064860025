import {
  Alert,
  Button,
  DeleteIcon,
  LinkIcon,
  Menu,
  MoreIcon,
  Space,
} from "melco-ui";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDeleteBlanks } from "../../../hooks/libraries/blanks/useDeleteBlanks";
import { useShouldShowWarning } from "../../../hooks/items/useShouldShowWarning";
import { MelcoCoreModelsUserBlank, useConfirm } from "melco-shared-logic";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type BlankDetailMoreActionButtonProps = {
  blank: MelcoCoreModelsUserBlank;
  referencesDrawer: DrawerType;
};

export const BlankDetailMoreActionButton: React.FC<
  BlankDetailMoreActionButtonProps
> = ({ blank, referencesDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteBlank = useDeleteBlanks();
  const shouldShowWarning = useShouldShowWarning();

  const confirmDelete = useConfirm(
    async () => {
      //TODO: Handle case if blank.id doesnt exist
      if (blank.id) {
        const successfullyDeletedIds = await deleteBlank([blank.id]);

        // redirect to list view in case the entity was deleted successfully
        if (!isEmpty(successfullyDeletedIds)) {
          navigate(`/libraries/blanks`);
        }
      }
    },
    {
      translationPrefix: "blanks.actions.delete",
      translationOptions: {
        count: 1,
      },
      content: shouldShowWarning(blank) && (
        <Space direction="vertical" size="small">
          {t("blanks.actions.delete.confirmation.message", {
            count: 1,
          })}
          <Alert
            type="warning"
            withBackground
            description={t("blanks.actions.delete.confirmation.warning", {
              count: 1,
            })}
            showIcon={false}
          />
        </Space>
      ),
    }
  );

  const MoreActions = (
    <Menu>
      <Menu.Item
        key="more-actions-references"
        onClick={() => {
          referencesDrawer.openDrawer();
        }}
      >
        <LinkIcon /> {t("blanks.actions.references.button")}
      </Menu.Item>
      <Menu.Item
        key="more-actions-delete"
        danger
        onClick={() => {
          confirmDelete();
        }}
      >
        <DeleteIcon /> {t("blanks.actions.delete.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="blank-more-actions"
        icon={<MoreIcon />}
        data-cy-id="blank-more-actions"
      />
    </Dropdown>
  );
};
