import {
  FullWidthSpace,
  MainContentArea,
  PageContentWrapper,
  PageTitle,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import { Performance } from "../../components/dashboard/performance/Performance";
import { Statistics } from "../../components/dashboard/statistics/Statistics";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MainContentArea contentPadding>
      <PageContentWrapper>
        <PageTitle>{t("dashboard.title")}</PageTitle>
        <FullWidthSpace direction="vertical" size="xlarge">
          <Statistics />
          <Performance />
        </FullWidthSpace>
      </PageContentWrapper>
    </MainContentArea>
  );
};
