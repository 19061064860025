import {
  Alert,
  Button,
  DeleteIcon,
  LinkIcon,
  Menu,
  MoreIcon,
  Space,
} from "melco-ui";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDeleteBlankPictures } from "../../../../hooks/libraries/blanks/blankPictures/useDeleteBlankPictures";
import { useShouldShowWarning } from "../../../../hooks/items/useShouldShowWarning";
import { MelcoCoreModelsUserImage, useConfirm } from "melco-shared-logic";
import { Drawer as DrawerType } from "../../../../hooks/drawer/useGenericDrawer";

type BlankPictureDetailMoreActionButtonProps = {
  blankPicture: MelcoCoreModelsUserImage;
  referencesDrawer: DrawerType;
};

export const BlankPictureDetailMoreActionButton: React.FC<
  BlankPictureDetailMoreActionButtonProps
> = ({ blankPicture, referencesDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteBlankPictures = useDeleteBlankPictures();
  const shouldShowWarning = useShouldShowWarning();

  const confirmDelete = useConfirm(
    async () => {
      if (!blankPicture.id) {
        return;
      }

      const successfullyDeletedIds = await deleteBlankPictures([
        blankPicture.id,
      ]);

      // redirect to list view in case the entity was deleted successfully
      if (!isEmpty(successfullyDeletedIds)) {
        navigate(`/libraries/blank-pictures`);
      }
    },
    {
      translationPrefix: "blank_pictures.actions.delete",
      translationOptions: {
        count: 1,
      },
      content: shouldShowWarning(blankPicture) && (
        <Space direction="vertical" size="small">
          {t("blank_pictures.actions.delete.confirmation.message", {
            count: 1,
          })}
          <Alert
            type="warning"
            withBackground
            description={t(
              "blank_pictures.actions.delete.confirmation.warning",
              {
                count: 1,
              }
            )}
            showIcon={false}
          />
        </Space>
      ),
    }
  );

  const MoreActions = (
    <Menu>
      <Menu.Item
        key="more-actions-references"
        onClick={() => {
          referencesDrawer.openDrawer();
        }}
      >
        <LinkIcon /> {t("blank_pictures.actions.references.button")}
      </Menu.Item>
      <Menu.Item
        key="more-actions-delete"
        danger
        onClick={() => {
          confirmDelete();
        }}
      >
        <DeleteIcon /> {t("blank_pictures.actions.delete.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="blank-picture-more-actions"
        icon={<MoreIcon />}
        data-cy-id="blank-picture-more-actions"
      />
    </Dropdown>
  );
};
