import React from "react";
import { Input, InputProps } from "../../form/item/Input";
import { SwatchColorPicker } from "../picking/SwatchColorPicker";
import { useColorInput } from "../../../hooks/color/useColorInput";

export type ColorInputProps = Omit<
  InputProps,
  "addonBefore" | "addonAfter" | "value" | "onChange"
> & {
  value?: string;
  onChange: (argb?: string) => void;
};

const ColorInput: React.FC<ColorInputProps> = ({
  onChange,
  value,
  ...rest
}) => {
  const { inputFieldValue, handleInputFieldChange, handleInputFieldBlur } =
    useColorInput(onChange, value);

  return (
    <Input
      {...rest}
      addonBefore={<SwatchColorPicker argb={value} onChange={onChange} />}
      value={inputFieldValue}
      onChange={handleInputFieldChange}
      onBlur={handleInputFieldBlur}
    />
  );
};

export { ColorInput };
