import { LabeledColorSwatch } from "melco-ui";

type ColorCodeProps = {
  code: string;
  argb: string;
};

export const ColorCode: React.FC<ColorCodeProps> = ({ code, argb }) => (
  <LabeledColorSwatch argb={argb} label={code} />
);
