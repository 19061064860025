import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForFont = (fontId: string, publishState: PublishState) => [
  "font",
  fontId,
  publishState,
];

export const useFont = (fontId: string, publishState: PublishState) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForFont(fontId, publishState),
    async (api) => {
      return api.userAlphabetGet({ id: fontId, publishState });
    }
  );
};
