import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../../hooks/navigation/useBreadcrumb";
import { BlankPicturesList } from "../../../../components/libraries/blanks/blankPictures/BlankPicturesList";
import { BlankPictureUploadButton } from "../../../../components/libraries/blanks/blankPictures/BlankPictureUploadButton";

export const BlankPictures: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("libraries.blanks.blank_pictures.title")}
          breadcrumb={breadcrumb}
          extra={<BlankPictureUploadButton />}
        />
      }
    >
      <BlankPicturesList />
    </MainContentArea>
  );
};
