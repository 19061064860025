import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { BlanksList } from "../../../components/libraries/blanks/BlanksList";
import { AddBlankButton } from "../../../components/libraries/blanks/AddBlankButton";

export const Blanks: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("libraries.blanks.blanks.title")}
          breadcrumb={breadcrumb}
          extra={<AddBlankButton />}
        />
      }
    >
      <BlanksList />
    </MainContentArea>
  );
};
