import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateBlank } from "../../../hooks/libraries/blanks/useDuplicateBlank";

type BlankDuplicateButtonProps = {
  blankId: string;
};

export const BlankDuplicateButton: React.FC<BlankDuplicateButtonProps> = ({
  blankId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [duplicateBlank, isLoading] = useDuplicateBlank(blankId);

  return (
    <Button
      key="blank-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const blank = await duplicateBlank();

        if (blank) {
          navigate(`/libraries/blanks/${blank.id}`);
        }
      }}
      loading={isLoading}
    >
      {t("blanks.actions.duplicate.button")}
    </Button>
  );
};
