import styled from "styled-components";
import { DesignTokens } from "melco-ui";

const { spacings } = DesignTokens;

const ThumbnailWrapper = styled.div`
  display: flex;
`;

const Thumbnail = styled.img`
  && {
    width: ${spacings.xlarge}px;
    height: ${spacings.xlarge}px;
  }
`;

type ListThumbnailProps = {
  thumbnailSrc?: string;
};

export const ListThumbnail: React.FC<ListThumbnailProps> = ({
  thumbnailSrc,
}) => {
  return (
    <ThumbnailWrapper>
      {thumbnailSrc && <Thumbnail src={thumbnailSrc} />}
    </ThumbnailWrapper>
  );
};
