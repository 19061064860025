import { useDesignCollection } from "../../../hooks/libraries/designCollections/useDesignCollection";

type DisplayNameOfDesignCollectionProps = {
  designCollectionId: string;
};

export const DisplayNameOfDesignCollection: React.FC<
  DisplayNameOfDesignCollectionProps
> = ({ designCollectionId }) => {
  const {
    query: { data: designCollection, isLoading },
  } = useDesignCollection(designCollectionId, "published");

  if (isLoading) {
    return null;
  }

  return <>{designCollection?.name ?? designCollection?.id ?? ""}</>;
};
