import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateColorCollection } from "../../../hooks/libraries/colorCollections/useDuplicateColorCollection";

type ColorCollectionDuplicateButtonProps = {
  colorCollectionId: string;
};

export const ColorCollectionDuplicateButton: React.FC<
  ColorCollectionDuplicateButtonProps
> = ({ colorCollectionId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [duplicateColorCollection, isLoading] =
    useDuplicateColorCollection(colorCollectionId);

  return (
    <Button
      key="color-collection-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const colorCollection = await duplicateColorCollection();

        if (colorCollection) {
          navigate(
            `/libraries/color-collections/embroidery/${colorCollection.id}`
          );
        }
      }}
      loading={isLoading}
    >
      {t("color_collections.actions.duplicate.button")}
    </Button>
  );
};
