import { FusionApi, useAPIQueryAndQueryKey } from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForProduct = (
  productId: string,
  publishState: PublishState
) => ["product", productId, publishState];

export const useProduct = (productId: string, publishState: PublishState) => {
  return useAPIQueryAndQueryKey(
    FusionApi,
    queryKeyForProduct(productId, publishState),
    async (api) =>
      api.fusionCustomProductsGetDef({ id: productId, publishState })
  );
};
