import { useField } from "formik";
import { MelcoCoreModelsColorListItem } from "melco-shared-logic";
import { useCallback } from "react";

export const useColorListIndex = () => {
  const [{ value: colors }] = useField<
    MelcoCoreModelsColorListItem[] | undefined
  >("color_list");

  return useCallback(
    (colorId: string) => {
      return colors?.findIndex((c) => c.id === colorId);
    },
    [colors]
  );
};
