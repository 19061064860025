import { Key } from "antd/lib/table/interface";
import { ItemType, useShouldShowWarning } from "../items/useShouldShowWarning";

export const useShowWarningForRowKeys = (keys: Key[], items?: ItemType[]) => {
  const shouldShowWarning = useShouldShowWarning();

  if (!items) {
    return [];
  }

  return keys.filter((key) => {
    const item = items.find((item) => item.id === key);

    if (!item) {
      return false;
    }

    return shouldShowWarning(item);
  });
};
