import { Navigate, Route, Routes } from "react-router-dom";
import { Error404 } from "./screens/404/Error404";
import { BasicSettings } from "./screens/settings/BasicSettings";
import { Designs } from "./screens/libraries/designs/Designs";
import { DesignDetails } from "./screens/libraries/designs/DesignDetails";
import { Alphabets } from "./screens/libraries/fonts/Alphabets";
import { FontDetails } from "./screens/libraries/fonts/FontDetails";
import { ColorCollections } from "./screens/libraries/colorCollections/ColorCollections";
import { ColorCollectionDetails } from "./screens/libraries/colorCollections/ColorCollectionDetails";
import { Dashboard } from "./screens/dashboard/Dashboard";

export const MainContentAPIService: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />

      <Route path="libraries">
        <Route path="" element={<Navigate to="designs" replace />} />

        <Route path="designs">
          <Route path="" element={<Designs />} />
          <Route path=":designId" element={<DesignDetails />} />
        </Route>

        <Route path="fonts">
          <Route path="" element={<Navigate to="embroidery" replace />} />
          <Route path="embroidery">
            <Route path="" element={<Alphabets />} />
            <Route path=":fontId" element={<FontDetails />} />
          </Route>
        </Route>

        <Route path="color-collections">
          <Route path="" element={<Navigate to="embroidery" replace />} />
          <Route path="embroidery">
            <Route path="" element={<ColorCollections />} />
            <Route
              path=":colorCollectionId"
              element={<ColorCollectionDetails />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="settings">
        <Route path="" element={<Navigate to="basic" replace />} />
        <Route path="basic" element={<BasicSettings />} />
      </Route>

      <Route path="login/support" element={<Navigate to="/" replace />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
