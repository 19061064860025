import {
  FusionApi,
  MelcoCoreModelsCustomProduct,
  useAPIAction,
} from "melco-shared-logic";
import { useFormikContext } from "formik";
import { QueryObserverResult } from "react-query";
import { productToInitialValues } from "../../../components/customization/products/ProductDetailForm";
import { useState } from "react";

export const useRevertProduct = (
  productId: string,
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsCustomProduct, unknown>
  >
) => {
  const dateOfLastRevert = new Date().getTime();
  const [revertKey, setRevertKey] = useState(dateOfLastRevert);
  const { resetForm } = useFormikContext();

  return [
    ...useAPIAction(
      FusionApi,
      async (api) => {
        const data = await api.fusionCustomProductsRevert({
          id: productId,
          force: true,
        });

        return {
          successMessages: [
            {
              message: "default",
            },
          ],
          data,
        };
      },
      {
        translationPrefix: "customization.products.detail.revert",
        onSuccess: async () => {
          const refetchResult = await refetch();

          resetForm({
            values: refetchResult.data
              ? productToInitialValues(refetchResult.data)
              : {},
          });

          // wait until formik values have changed
          await Promise.resolve();

          // trigger re-initialization of renderer
          const dateOfRevert = new Date().getTime();
          setRevertKey(dateOfRevert);
        },
      }
    ),
    revertKey,
  ] as const;
};
