import { useApiServiceBasicSettings } from "./useBasicSettings.apiservice";
import { useFusionBasicSettings } from "./useBasicSettings.fusion";
import { BasicSettingsFormValueType } from "./useBasicSettingsFormSubmit";

export type BasicSettingsResult = {
  settings?: BasicSettingsFormValueType;
};

export const useBasicSettings =
  process.env.REACT_APP_PRODUCT_VARIANT! === "fusion"
    ? useFusionBasicSettings
    : useApiServiceBasicSettings;
