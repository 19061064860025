import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { DesignReferencesList } from "./DesignReferencesList";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type DesignReferencesDrawerProps = {
  drawer: DrawerType;
  id: string;
};

export const DesignReferencesDrawer: React.FC<DesignReferencesDrawerProps> = ({
  drawer,
  id: designId,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("designs.actions.references.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <DesignReferencesList designId={designId} />
    </Drawer>
  );
};
