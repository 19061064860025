import { useAPIQuery } from "../api/useAPIQuery";
import { UserAccountApi } from "../../api/apis/UserAccountApi";

export const useCurrentProductDetails = () => {
  const productCode = process.env.REACT_APP_PRODUCT_CODE!;

  return useAPIQuery(UserAccountApi, ["product", productCode], async (api) => {
    const product = await api.accountProductsProduct({
      productCode,
    });

    return product;
  });
};
