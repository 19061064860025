import { useField } from "formik";
import { ErrorIndicator } from "melco-ui";

type FieldErrorIndicatorProps = {
  name: string;
};

export const FieldErrorIndicator: React.FC<FieldErrorIndicatorProps> = ({
  name,
}) => {
  const [, { error }] = useField(name);

  if (!error) {
    return null;
  }

  return <ErrorIndicator data-cy-id="error-indicator" />;
};
