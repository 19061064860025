import { useCallback } from "react";
import { publishStateIdToName } from "../../helper/publishedState";

export type ItemType = {
  id?: string | null;
  publish_state?: number;
};

export const useShouldShowWarning = () =>
  useCallback((item: ItemType) => {
    const { publish_state: publishState } = item;

    if (!publishState) {
      return false;
    }

    return publishStateIdToName(publishState) !== "draft";
  }, []);
