import { FullWidthSpace } from "melco-ui";
import { ProductsReferencesList } from "../../../references/ProductsReferencesList";
import { BlanksReferencesList } from "../../../references/BlanksReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../../references/ReferencesListAlert";
import { useBlankPictureReferences } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictureReferences";

type BlankPictureReferencesListProps = {
  blankPictureId: string;
};

export const BlankPictureReferencesList: React.FC<
  BlankPictureReferencesListProps
> = ({ blankPictureId }) => {
  const { t } = useTranslation();

  const { data: blankPictureReferences } =
    useBlankPictureReferences(blankPictureId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert
        message={t("blank_pictures.actions.references.warning")}
      />
      <BlanksReferencesList
        blankList={blankPictureReferences?.blank_list ?? undefined}
      />
      <ProductsReferencesList
        productList={blankPictureReferences?.custom_product_list ?? undefined}
      />
    </FullWidthSpace>
  );
};
