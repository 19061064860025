// note: points are defined at Melco to equal 0.1mm
type Unit = "points" | "mm" | "inch";

type ConvertOptions = {
  value: number;
  from: Unit;
  to: Unit;
};

const MM_TO_INCH = 0.03937007874;
const POINT_TO_MM = 0.1;

const conversionFactors: { [key in Unit]: { [key in Unit]: number } } = {
  mm: {
    mm: 1,
    inch: MM_TO_INCH,
    points: 1 / POINT_TO_MM,
  },
  inch: {
    mm: 1 / MM_TO_INCH,
    inch: 1,
    points: (1 / MM_TO_INCH) * (1 / POINT_TO_MM),
  },
  points: {
    mm: POINT_TO_MM,
    inch: POINT_TO_MM * MM_TO_INCH,
    points: 1,
  },
};

export const convertUnit = (options: ConvertOptions) => {
  const { value, from, to } = options;

  if (from === to) {
    return value;
  }

  const factor = conversionFactors[from][to] as number;

  if (!factor) {
    throw new Error(`could not convert from ${from} to ${to}`);
  }

  return value * factor;
};
