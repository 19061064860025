import { UseConfirmOptions } from "../../hooks/confirm/useConfirm";
import { SaveFormWithConfirmButton } from "../form/SaveFormWithConfirmButton";
import { HeaderButtonAreaPortal } from "./HeaderButtonAreaPortal";

type HeaderSaveFormWithConfirmButtonProps = {
  confirmOptions: UseConfirmOptions;
};

export const HeaderSaveFormWithConfirmButton: React.FC<
  HeaderSaveFormWithConfirmButtonProps
> = ({ confirmOptions }) => (
  <HeaderButtonAreaPortal>
    <SaveFormWithConfirmButton confirmOptions={confirmOptions} />
  </HeaderButtonAreaPortal>
);
