import { UserAccountApi, useUserAccountAPI } from "melco-shared-logic";
import { useCallback } from "react";
import { ConfiguratorSettingsFormValueType } from "./useConfiguratorSettingsFormSubmit";

const initialValue = { settings: undefined };

export type ConfiguratorSettingsResult = {
  settings?: ConfiguratorSettingsFormValueType;
};

export const useConfiguratorSettings = () => {
  const handleRequest = useCallback(async (api: UserAccountApi) => {
    const { version } =
      await api.accountProductSettingsGetFusionConfiguratorSetting();

    return {
      settings: {
        version,
      },
    };
  }, []);

  return useUserAccountAPI<ConfiguratorSettingsResult>({
    initialValue,
    handleRequest,
  });
};
