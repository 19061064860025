export type ProductVariant = "fusion" | "apiservice";

export const useCurrentProductVariant = () => {
  const configuredProductVariant = process.env.REACT_APP_PRODUCT_VARIANT!;
  const allowedProductVariants = ["fusion", "apiservice"];

  if (allowedProductVariants.includes(configuredProductVariant)) {
    return configuredProductVariant as ProductVariant;
  }

  throw new Error(
    `Product variant incorrectly configured (${configuredProductVariant})`
  );
};
