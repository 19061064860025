import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateDesign } from "../../../hooks/libraries/designs/useDuplicateDesign";

type DesignDuplicateButtonProps = {
  designId: string;
};

export const DesignDuplicateButton: React.FC<DesignDuplicateButtonProps> = ({
  designId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [duplicateDesign, isLoading] = useDuplicateDesign(designId);

  return (
    <Button
      key="design-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const design = await duplicateDesign();

        if (design) {
          navigate(`/libraries/designs/${design.id}`);
        }
      }}
      loading={isLoading}
    >
      {t("designs.actions.duplicate.button")}
    </Button>
  );
};
