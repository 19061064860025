import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import { Spin, Empty } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { Formatter as LegendFormatter } from "recharts/types/component/DefaultLegendContent";
import { Formatter as TooltipFormatter } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import { calculateTotalDesignsAnalyticsPerMonth } from "../../../helper/analyticsHelper";
import { useDesignsAnalytics } from "../../../hooks/analytics/useDesignsAnalytics";

const ChartWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

const CHART_COLOR_TOTAL = "#60C325";

type DesignsChartProps = {
  startTimestamp?: Date;
  endTimestamp?: Date;
};

export const DesignsChart: React.FC<DesignsChartProps> = ({
  startTimestamp,
  endTimestamp,
}) => {
  const { t } = useTranslation();

  const { dailySummary, isLoading } = useDesignsAnalytics(
    startTimestamp,
    endTimestamp
  );

  const totalDesignsPerMonth =
    calculateTotalDesignsAnalyticsPerMonth(dailySummary);

  const chartData = totalDesignsPerMonth.map((value) => {
    return {
      name: dayjs(new Date(value.year, value.month)).format("MMM YY"),
      total: value.total.totalFiles,
    };
  });

  const legendFormatter: LegendFormatter = (value, entry) => {
    const { color } = entry;
    return (
      <span style={{ color }}>
        {t(`dashboard.performance.chart.designs.label.${value}` as any)}
      </span>
    );
  };

  const tooltipFormatter: TooltipFormatter<number, string> = (value, name) => {
    return [
      value,
      t(`dashboard.performance.chart.designs.label.${name}` as any),
    ];
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ChartWrapper data-cy-id="dashboard-performance-chart">
      {!isEmpty(dailySummary) ? (
        <ResponsiveContainer>
          <AreaChart width={500} height={300} data={chartData}>
            <Area
              type="linear"
              dataKey="total"
              stroke={CHART_COLOR_TOTAL}
              fillOpacity={0.25}
              fill={CHART_COLOR_TOTAL}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip formatter={tooltipFormatter} />
            <Legend formatter={legendFormatter} />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <Empty
          image={null}
          description={t(`dashboard.performance.chart.empty`)}
        />
      )}
    </ChartWrapper>
  );
};
