import { MelcoCoreModelsCustomProductImageDesign } from "melco-shared-logic";
import { StitchCanvas } from "@melco/renderer-react-component";
import styled from "styled-components";
import { useProductElementConfiguration } from "../../hooks/libraries/designs/useProductElementConfiguration";
import { useProductRenderer } from "../../hooks/renderer/useProductRenderer";
import { useUpdateRendererMetaData } from "../../hooks/customization/products/useUpdateRendererMetaData";
import { ZoomToggleButton } from "../customization/products/ZoomToggleButton";

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
`;

type RenderedProductProps = {
  viewId: string;
  blankPictureUrl: string;
  productImageDesign: MelcoCoreModelsCustomProductImageDesign | undefined;
  updateRendererMetaData: ReturnType<typeof useUpdateRendererMetaData>;
};

export const RenderedProduct: React.FC<RenderedProductProps> = ({
  viewId,
  blankPictureUrl,
  productImageDesign,
  updateRendererMetaData,
}) => {
  const elementConfiguration = useProductElementConfiguration(
    blankPictureUrl,
    productImageDesign
  );

  const {
    canvasId,
    initCallback,
    rendererState,
    isLoading,
    canvas,
    rendererDispatch,
    elementUtil,
  } = useProductRenderer(
    viewId,
    blankPictureUrl,
    productImageDesign,
    updateRendererMetaData,
    elementConfiguration
  );

  const { scene, viewPort, renderOptions, wireframeLayer, animationParams } =
    rendererState;

  return (
    <CanvasWrapper>
      {isLoading && "Loading..."}

      <StitchCanvas
        canvasId={canvasId}
        initCallback={initCallback}
        scene={scene}
        viewPort={viewPort}
        renderOptions={renderOptions}
        wireframeLayer={wireframeLayer}
        animationParams={animationParams}
      />

      <ZoomToggleButton
        zoomedTo={rendererState.zoomedTo}
        canvas={canvas}
        rendererDispatch={rendererDispatch}
        elementUtil={elementUtil}
      />
    </CanvasWrapper>
  );
};
