import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForDesignCollection = (
  designCollectionId: string,
  publishState: PublishState
) => ["designCollection", designCollectionId, publishState];

export const useDesignCollection = (
  designCollectionId: string,
  publishState: PublishState
) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForDesignCollection(designCollectionId, publishState),
    async (api) =>
      api.userDesignCollectionsGetDef({ id: designCollectionId, publishState })
  );
};
