import { RenderScene } from "@melco/renderer";
import {
  CursorStyleUpdateFn,
  EditableMouseGestureHandler,
  MoveScaleHandler,
  ProjectChangeUpdateFn,
  SelectionBoxHitFn,
  ViewPortUpdateFn,
} from "@melco/renderer/dist/events";

class NoZoomingScaleHandler extends MoveScaleHandler {
  public doMouseWheel() {
    return false;
  }
}

export class FusionMouseGestureHandler extends EditableMouseGestureHandler {
  public constructor(
    editMode: boolean,
    v: RenderScene,
    cursorUpdateFn?: CursorStyleUpdateFn,
    viewPortUpdate?: ViewPortUpdateFn,
    projectHandler?: ProjectChangeUpdateFn,
    selectionBoxHitFn?: SelectionBoxHitFn
  ) {
    super(
      editMode,
      v,
      cursorUpdateFn,
      viewPortUpdate,
      projectHandler,
      selectionBoxHitFn
    );

    this.selectHandler = new NoZoomingScaleHandler(
      this.viewPortData,
      this.selectionInfo,
      selectionBoxHitFn
    );

    this.mouseGestureHandler.handlers = [
      this._editMode ? this.selectHandler : this.panHandler,
    ];
  }
}
