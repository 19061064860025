import { FusionApi, useAPIAction } from "melco-shared-logic";

export const useDuplicateProduct = (productId: string) => {
  return useAPIAction(
    FusionApi,
    async (api) => {
      const data = await api.fusionCustomProductsDuplicateDraft({
        id: productId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    { translationPrefix: "products.actions.duplicate" }
  );
};
