import { MelcoCoreModelsReferences } from "melco-shared-logic";
import { Button, EditIcon, Table, ValueOrFallback } from "melco-ui";
import { useTranslation } from "react-i18next";
import { ReferencesListTitle } from "./ReferencesListTitle";

export type BlanksReferencesListProps = {
  blankList?: MelcoCoreModelsReferences[];
};

const DETAIL_VIEW_PATH = "/libraries/blanks";

export const BlanksReferencesList: React.FC<BlanksReferencesListProps> = ({
  blankList,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("global.references.blanks.list.columns.name"),
      key: "name",
      dataIndex: "name",
      render: (_: string, item: MelcoCoreModelsReferences) => (
        <ValueOrFallback
          value={item.name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      align: "right" as const,
      render: (_: string, item: MelcoCoreModelsReferences) => (
        <Button
          icon={<EditIcon />}
          size="small"
          href={[DETAIL_VIEW_PATH, item.id].join("/")}
          target="_blank"
        >
          {t("global.references.actions.edit.button")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <ReferencesListTitle level={5}>
        {t("global.references.blanks.title")}
      </ReferencesListTitle>
      <Table
        showSorterTooltip={false}
        dataSource={blankList}
        columns={columns}
        rowKey="id"
        pagination={false}
        noDataMessage={t("global.references.blanks.list.empty")}
      />
    </>
  );
};
