import { SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "melco-ui";
import { useColorCollectionsDropdownData } from "../../../hooks/libraries/colorCollections/useColorCollectionsDropdown";
import { DefaultColorField } from "./DefaultColorField";
import { useResetDefaultColor } from "../../../hooks/libraries/colorCollections/useResetDefaultColor";

type ColorFieldProps = {
  formikPrefix: string;
};

export const ColorField: React.FC<ColorFieldProps> = ({ formikPrefix }) => {
  const { t } = useTranslation();

  useResetDefaultColor(formikPrefix);

  const { isLoading, isFetching, data, isPreviousData } =
    useColorCollectionsDropdownData();

  const colorCollectionOptions = (data ?? []).map((o) => ({
    value: o.id ?? "",
    label: o.name ?? "",
  }));

  return (
    <>
      <ConfigProvider
        renderEmpty={() => <>{t("color_collections_list.empty")}</>}
      >
        <SelectField
          name={`${formikPrefix}.color_collection_id`}
          label={t("libraries.designs.detail.form.label.color_collection_id")}
          loading={isPreviousData ? isFetching : isLoading}
          style={{ width: "100%" }}
          options={colorCollectionOptions}
          showSearch
          optionFilterProp="label"
          filterOption
        />
      </ConfigProvider>

      <DefaultColorField formikPrefix={formikPrefix} />
    </>
  );
};
