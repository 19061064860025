import { useCallback, useState } from "react";
import { Button, UploadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "react-query";
import { useBlankPictureUpload } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictureUpload";

export const BlankPictureUploadButton: React.FC = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { uploadBlankPicture } = useBlankPictureUpload();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true);
      for (const file of acceptedFiles) {
        try {
          await uploadBlankPicture(file);
        } catch (error) {}
        queryClient.invalidateQueries("blankPictures");
      }
      setIsLoading(false);
    },
    [uploadBlankPicture, queryClient]
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <input {...getInputProps()} />

      <Button
        key="blank-pictures-upload"
        type="primary"
        icon={<UploadIcon />}
        onClick={() => {
          open();
        }}
        loading={isLoading}
      >
        {t("blank_pictures.actions.upload.button")}
      </Button>
    </>
  );
};
