import isEmpty from "lodash-es/isEmpty";
import { useCallback, useEffect, useState } from "react";
import { ColorHelper } from "../../helper/ColorHelper";

const { isValidRGB, isValidARGB, rgbToARGB, argbToRGB, colorToRGB } =
  ColorHelper;

export const useColorInput = (
  onChange: (argb?: string) => void,
  value?: string
) => {
  const [inputFieldValue, setInputFieldValue] = useState<string | undefined>(
    isValidARGB(value) ? argbToRGB(value) : undefined
  );
  const handleInputFieldChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const newInputFieldValue = e.target.value;
        setInputFieldValue(newInputFieldValue);

        // change color value if input is valid or field is empty
        if (isValidRGB(newInputFieldValue)) {
          onChange(rgbToARGB(newInputFieldValue));
        } else if (isEmpty(newInputFieldValue)) {
          onChange(undefined);
        }
      },
      [onChange, setInputFieldValue]
    );

  const handleInputFieldBlur: React.FocusEventHandler<HTMLInputElement> =
    useCallback(() => {
      setInputFieldValue(isValidARGB(value) ? argbToRGB(value) : undefined); // reset value to last valid value on invalid intermediate state
    }, [value, setInputFieldValue]);

  // synchronize input field value with value
  useEffect(() => {
    const rgb = argbToRGB(value);

    // don't override input field value if the user is entering e.g. #ff0, the input field should not change value to #ffff00
    if (rgb !== colorToRGB(inputFieldValue)) {
      setInputFieldValue(rgb);
    }
  }, [value]);

  return {
    inputFieldValue,
    handleInputFieldChange,
    handleInputFieldBlur,
  };
};
