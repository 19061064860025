import { MainContentArea, ContentHeader, ValueOrFallback } from "melco-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { useDesign } from "../../../hooks/libraries/designs/useDesign";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { DesignDownloadButton } from "../../../components/libraries/designs/DesignDownloadButton";
import { DesignDetailForm } from "../../../components/libraries/designs/DesignDetailForm";
import { DesignDuplicateButton } from "../../../components/libraries/designs/DesignDuplicateButton";
import { DesignDetailMoreActionButton } from "../../../components/libraries/designs/DesignDetailMoreActionButton";
import { DesignReferencesDrawer } from "../../../components/libraries/designs/DesignReferencesDrawer";
import { useDesignReferencesDrawer } from "../../../hooks/drawer/useDesignReferencesDrawer";

export const DesignDetails: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries", "librariesDesigns");

  const { designId } = useParams<{
    designId: string;
  }>();

  const {
    query: { data: design, refetch },
    queryKey,
  } = useDesign(designId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useDesignReferencesDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            design && (
              <ValueOrFallback
                value={design.name}
                fallback={t("global.fallback.empty")}
              />
            )
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              <DesignDuplicateButton designId={designId ?? ""} />
              <DesignDownloadButton
                designId={designId ?? ""}
                designFilename={design?.file_name ?? ""}
              />
              {design && (
                <DesignDetailMoreActionButton
                  design={design}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {design && (
        <>
          <DesignDetailForm
            design={design}
            refetch={refetch}
            referencesDrawer={referencesDrawer}
            queryKey={queryKey}
          />

          {design.id && referencesDrawer && (
            <DesignReferencesDrawer drawer={referencesDrawer} id={design.id} />
          )}
        </>
      )}
    </MainContentArea>
  );
};
