import { useApiServiceDesignReferencesCount } from "./useDesignReferencesCount.apiservice";
import { useFusionDesignReferencesCount } from "./useDesignReferencesCount.fusion";

export type DesignReferencesCountResult = {
  total: number;
};

export const useDesignReferencesCount =
  process.env.REACT_APP_PRODUCT_VARIANT! === "fusion"
    ? useFusionDesignReferencesCount
    : useApiServiceDesignReferencesCount;
