import { useCallback } from "react";
import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDeleteBlanks = () => {
  const [deleteBlank] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, id: string) => {
      const data = await api.userBlankDelete({ id, force: true });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "blanks.actions.delete",
    }
  );

  return useCallback(
    async (blankIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const blankId of blankIds) {
        try {
          await deleteBlank(blankId);
          successfullyDeleted.push(blankId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteBlank]
  );
};
