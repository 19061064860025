import { useCallback } from "react";
import { Button, FormItem, UploadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { QueryObserverResult } from "react-query";
import { MelcoCoreModelsDesign, useConfirm } from "melco-shared-logic";
import { useDesignUpdate } from "../../../hooks/libraries/designs/useDesignUpdate";

type DesignUpdateFieldProps = {
  design: MelcoCoreModelsDesign;
  refetch: () => Promise<QueryObserverResult<MelcoCoreModelsDesign, unknown>>;
};

export const DesignUpdateField: React.FC<DesignUpdateFieldProps> = ({
  design,
  refetch,
}) => {
  const { id } = design;

  const { t } = useTranslation();

  const confirmUpdate = useConfirm(
    async () => {
      open();
    },
    {
      translationPrefix: "designs.actions.update",
    }
  );

  const { updateDesign, isLoading } = useDesignUpdate(id!, refetch);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        try {
          await updateDesign(acceptedFiles[0]);
        } catch (error) {}
      }
    },
    [updateDesign]
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
  });
  return (
    <FormItem label={t("designs.actions.update.label")}>
      <input {...getInputProps()} />
      <Button
        icon={<UploadIcon />}
        onClick={() => {
          confirmUpdate();
        }}
        loading={isLoading}
      >
        {t("designs.actions.update.button")}
      </Button>
    </FormItem>
  );
};
