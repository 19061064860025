import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ProductsList } from "../../../components/customization/products/ProductsList";
import { AddProductButton } from "../../../components/customization/products/AddProductButton";

export const Products: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("customization");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("customization.products.title")}
          breadcrumb={breadcrumb}
          extra={<AddProductButton />}
        />
      }
    >
      <ProductsList />
    </MainContentArea>
  );
};
