import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDesignUpload = () => {
  const [uploadDesign] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, file: Blob) => {
      const data = await api.userDesignsUploadDraft({
        file,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "designs.actions.upload",
    }
  );

  return {
    uploadDesign,
  } as const;
};
