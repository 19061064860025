import { FullWidthSpace } from "melco-ui";
import { ProductsReferencesList } from "../../references/ProductsReferencesList";
import { useTranslation } from "react-i18next";
import { ReferencesListAlert } from "../../references/ReferencesListAlert";
import { useDesignReferences } from "../../../hooks/libraries/designs/useDesignReferences";
import { DesignCollectionsReferencesList } from "../../references/DesignCollectionsReferencesList";

type DesignReferencesListProps = {
  designId: string;
};

export const DesignReferencesList: React.FC<DesignReferencesListProps> = ({
  designId,
}) => {
  const { t } = useTranslation();

  const { data: designReferences } = useDesignReferences(designId);

  return (
    <FullWidthSpace direction="vertical" size="large">
      <ReferencesListAlert message={t("designs.actions.references.warning")} />
      <ProductsReferencesList
        productList={designReferences?.custom_product_list ?? undefined}
      />
      <DesignCollectionsReferencesList
        designCollectionList={
          designReferences?.design_collection_list ?? undefined
        }
      />
    </FullWidthSpace>
  );
};
