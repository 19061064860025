import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDuplicateColorCollection = (colorCollectionId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userColorCollectionsDuplicateDraft({
        id: colorCollectionId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    { translationPrefix: "color_collections.actions.duplicate" }
  );
};
