import { useField } from "formik";

export const useDisableDefaultTextField = (formikPrefix: string) => {
  const [{ value: defaultFontId }] = useField<string>(
    `${formikPrefix}.lettering.default_alphabet_id`
  );

  if (!defaultFontId || defaultFontId === "") {
    return { disabled: true };
  }

  return { disabled: false };
};
