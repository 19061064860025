import { useAPIQuery, UserContentsApi } from "melco-shared-logic";
import { useBasicSettings } from "../../settings/useBasicSettings";

export const useHoopsDropdown = () => {
  const { settings, isLoading: isSettingsLoading } = useBasicSettings();

  const {
    data,
    isPreviousData,
    isFetching,
    isLoading: isQueryLoading,
  } = useAPIQuery(
    UserContentsApi,
    ["hoopsDropdownData", settings?.measurement_unit],
    async (api) => {
      const response = await api.accountHoopSearch({
        offset: 0,
        limit: 1000,
        sortBy:
          settings?.measurement_unit === "english"
            ? "english_name.asc"
            : "metric_name.asc",
        publishState: "published",
        productList: "Fusion",
      });

      const options = (response.list ?? []).map((o) => ({
        value: o.id ?? "",
        label:
          (settings?.measurement_unit === "english"
            ? o.english_name
            : o.metric_name) ?? "",
      }));

      return options;
    },
    {
      queryOptions: {
        keepPreviousData: true,
        enabled: !!settings?.measurement_unit,
      },
    }
  );

  const isHoopLoading = isPreviousData ? isFetching : isQueryLoading;
  const isLoading = isHoopLoading || isSettingsLoading;

  return { isLoading, data: data ?? [] };
};
