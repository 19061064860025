import { useState } from "react";
import {
  DisplayFormErrors,
  InputField,
  MelcoCoreModelsColorCollection,
  MelcoCoreModelsColorListItem,
  VerticalForm,
  DebugForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import {
  Col,
  DesignTokens,
  FullWidthSpace,
  PreviewSider,
  Row,
  Title,
} from "melco-ui";
import { useField } from "formik";
import { isEmpty } from "lodash-es";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertColorCollection } from "../../../hooks/libraries/colorCollections/useRevertColorCollection";
import { AddColorsDrawer } from "./AddColorsDrawer";
import { ColorCollectionColorsList } from "./ColorCollectionColorsList";
import { ColorsSortableList } from "./ColorsSortableList";
import { useColorCollectionReferencesCount } from "../../../hooks/libraries/colorCollections/useColorCollectionReferencesCount";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

const { spacings } = DesignTokens;

type DisplayColorCollectionDetailFormProps = {
  colorCollection: MelcoCoreModelsColorCollection;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsColorCollection, unknown>
  >;
  referencesDrawer: DrawerType;
};

export const DisplayColorCollectionDetailForm: React.FC<
  DisplayColorCollectionDetailFormProps
> = ({ colorCollection, refetch, referencesDrawer }) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = colorCollection;

  const { t } = useTranslation();
  const [revertColorCollection, isLoading] = useRevertColorCollection(
    id!,
    refetch
  );
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [{ value: colors }] =
    useField<MelcoCoreModelsColorListItem[]>("color_list");

  const { total: referencesCount } = useColorCollectionReferencesCount(id!);

  return (
    <>
      <SavePublishHeader
        revertAction={revertColorCollection}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <DisplayFormErrors />

      <Row gutter={[spacings.xlarge, spacings.xlarge]}>
        <Col xs={isEmpty(colors) ? 24 : 16}>
          <VerticalForm>
            <FullWidthSpace direction="vertical">
              <div>
                <InputField
                  name="name"
                  label={t(
                    "libraries.color_collections.detail.form.label.name"
                  )}
                />
              </div>

              <ColorCollectionColorsList setDrawerOpen={setDrawerOpen} />
            </FullWidthSpace>
          </VerticalForm>

          <AddColorsDrawer
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </Col>

        {!isEmpty(colors) && (
          <Col xs={8}>
            <PreviewSider>
              <FullWidthSpace direction="vertical" size="small">
                <Title level={5}>
                  {t("libraries.color_collections.detail.sort_sider.title")}
                </Title>
                <div>
                  <ColorsSortableList />
                </div>
              </FullWidthSpace>
            </PreviewSider>
          </Col>
        )}
      </Row>

      {false && <DebugForm />}
    </>
  );
};
