import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthenticationProvider, useConfigProvider } from "melco-shared-logic";
import { ConfigProvider } from "melco-ui";
import { RestoreToken } from "./RestoreToken";

import "melco-ui/dist/melcoUI.css";
import "./fonts.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

export const App: React.FC = () => {
  const configProviderProps = useConfigProvider();

  return (
    <Suspense fallback={null}>
      <ConfigProvider {...configProviderProps}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <RestoreToken />
          </AuthenticationProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </Suspense>
  );
};
