import { useField } from "formik";
import {
  MelcoCoreModelsColorListItem,
  MelcoCoreModelsThread,
} from "melco-shared-logic";
import { message } from "melco-ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const TEMP_COLOR_ID_PREFIX = "__TEMP__ID__";

const generateTempId = () => [TEMP_COLOR_ID_PREFIX, uuidv4()].join("");

export const withoutTemporaryIds = (
  colorList: MelcoCoreModelsColorListItem[] | undefined
) => {
  if (!colorList) {
    return [];
  }

  return colorList.map((c) => {
    if (c.id && c.id.startsWith(TEMP_COLOR_ID_PREFIX)) {
      return { ...c, id: null };
    }

    return c;
  });
};

export const useAddColors = () => {
  const { t } = useTranslation();

  const [{ value: colors }, , { setValue: setColors, setError }] =
    useField<MelcoCoreModelsColorListItem[]>("color_list");

  const addColors = useCallback(
    (toAdd: MelcoCoreModelsThread[]) => {
      const addedColors = toAdd.map((thread) => {
        const { name, code, product_name, supplier_name, color } = thread;

        return {
          id: generateTempId(),
          name,
          serviceId: 1,
          supplier_name,
          product_name,
          code,
          color,
        };
      });

      setColors([...(colors ?? []), ...addedColors]);

      message.success(
        t("color_collections.actions.add_colors.actions.added", {
          count: addedColors.length,
        })
      );
    },
    [colors, setColors, t]
  );

  const removeColors = useCallback(
    (toRemove: { id: string }[]) => {
      const idsToRemove = toRemove.map((c) => c.id);

      setColors(colors.filter((c) => !idsToRemove.includes(c.id!)));

      // reset field error as removing a field changes the index positions which could lead to errors being assigned to the wrong field
      setError(undefined);
    },
    [colors, setColors, setError]
  );

  // with the current data model we cannot determine whether a color has
  // been added already or not, as there is no way to link the color
  // collection color back to the originally referenced color (by API
  // design)
  const isAlreadyAdded = (_color: MelcoCoreModelsThread) => false;

  return {
    colors,
    addColors,
    removeColors,
    isAlreadyAdded,
  };
};
