import { MainContentArea, ContentHeader, ValueOrFallback } from "melco-ui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { useProduct } from "../../../hooks/customization/products/useProduct";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { ProductDetailForm } from "../../../components/customization/products/ProductDetailForm";
import { ProductPreviewButton } from "../../../components/customization/products/ProductPreviewButton";
import { ProductDuplicateButton } from "../../../components/customization/products/ProductDuplicateButton";

export const ProductDetails: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("customization", "customizationProducts");

  const { productId } = useParams<{
    productId: string;
  }>();

  const {
    query: { data: product, refetch },
    queryKey,
  } = useProduct(productId ?? "", ALL_PUBLISHED_STATES);

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            product && (
              <ValueOrFallback
                value={product.name}
                fallback={t("global.fallback.empty")}
              />
            )
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              <ProductDuplicateButton productId={productId ?? ""} />
              <ProductPreviewButton
                productToken={product?.token ?? undefined}
                productPublishState={product?.publish_state}
              />
              {/*
              <DesignDownloadButton
                productId={productId}
                designFilename={design?.fileName ?? ""}
              />
              */}
              {/* <DesignDetailMoreActionButton productId={productId} /> */}
            </>
          }
        />
      }
      contentPadding
    >
      {product && (
        <ProductDetailForm
          product={product}
          refetch={refetch}
          queryKey={queryKey}
        />
      )}
    </MainContentArea>
  );
};
