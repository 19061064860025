import { Option, LabeledColorSwatch } from "melco-ui";
import { ConfigProvider } from "melco-ui";
import { MelcoCoreModelsColorListItem, SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { colorLabel } from "../../../helper/colorCollectionHelper";

type DefaultColorSelectFieldProps = {
  formikPrefix: string;
  colorList: MelcoCoreModelsColorListItem[];
  isLoading: boolean;
};

export const DefaultColorSelectField: React.FC<
  DefaultColorSelectFieldProps
> = ({ formikPrefix, colorList, isLoading }) => {
  const { t } = useTranslation();

  return (
    <ConfigProvider renderEmpty={() => <>{t("threads_list.empty")}</>}>
      <SelectField
        name={`${formikPrefix}.default_color_id`}
        label={t("libraries.designs.detail.form.label.default_color_id")}
        loading={isLoading}
        style={{ width: "100%" }}
        showSearch
      >
        {colorList.map((o) => (
          <Option key={o.id!} value={o.id!} label={colorLabel(o)}>
            <LabeledColorSwatch
              label={colorLabel(o)}
              argb={o.color?.argb ?? undefined}
            />
          </Option>
        ))}
      </SelectField>
    </ConfigProvider>
  );
};
