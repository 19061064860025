import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDuplicateDesign = (designId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userDesignsDuplicateDraft({
        id: designId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    { translationPrefix: "designs.actions.duplicate" }
  );
};
