import { StitchElementUtil } from "@melco/renderer";
import { BaseAction } from "@melco/renderer/dist/events";
import { CanvasActionGroupItem, ArrowsAltIcon } from "melco-ui";
import { Dispatch, useCallback } from "react";
import { createPortal } from "react-dom";
import { FusionZoomToFitAction } from "../../../hooks/renderer/FusionRendererActions";
import { ZOOM_TO_DESIGN_BUTTON_PORTAL_ID } from "./ZoomToDesignButtonPortal";

type ZoomToDesignButtonProps = {
  canvas: HTMLElement | undefined;
  rendererDispatch: Dispatch<BaseAction>;
  elementUtil: StitchElementUtil | undefined;
};

export const ZoomToDesignButton: React.FC<ZoomToDesignButtonProps> = ({
  canvas,
  rendererDispatch,
  elementUtil,
}) => {
  const toggleZoom = useCallback(() => {
    if (!canvas || !elementUtil) {
      return;
    }
    const { clientWidth, clientHeight } = canvas;

    rendererDispatch(
      new FusionZoomToFitAction(
        "design",
        clientWidth,
        clientHeight,
        elementUtil
      )
    );
  }, [rendererDispatch, canvas, elementUtil]);

  const portalTarget = document.getElementById(ZOOM_TO_DESIGN_BUTTON_PORTAL_ID);

  if (!portalTarget) {
    return null;
  }

  return createPortal(
    <CanvasActionGroupItem onClick={toggleZoom}>
      <ArrowsAltIcon data-cy-id="zoom-to-fit" />
    </CanvasActionGroupItem>,
    document.getElementById(ZOOM_TO_DESIGN_BUTTON_PORTAL_ID)!
  );
};
