import { useFormikContext } from "formik";
import { SaveFormButton, SaveFormButtonProps } from "./SaveFormButton";
import { UseConfirmOptions, useConfirm } from "../../hooks/confirm/useConfirm";

type SaveFormWithConfirmButtonProps = SaveFormButtonProps & {
  confirmOptions: UseConfirmOptions;
};

export const SaveFormWithConfirmButton: React.FC<
  SaveFormWithConfirmButtonProps
> = ({ confirmOptions, onClick, ...rest }) => {
  const { submitForm } = useFormikContext();

  const confirm = useConfirm(async () => {
    submitForm();
  }, confirmOptions);

  return <SaveFormButton onClick={confirm} {...rest} />;
};
