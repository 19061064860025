import {
  MelcoCoreModelsUserImage,
  selectThumbnailForSize,
} from "melco-shared-logic";

export const useBlankPictureThumbnailURL = (
  blankPicture: MelcoCoreModelsUserImage,
  width: number,
  height: number
) => {
  const thumbnail = selectThumbnailForSize(
    blankPicture.previews ?? [],
    Math.max(width, height)
  );

  return thumbnail?.url;
};
