import { Alert, Button, EyeIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useCheckIfProductPreviewIsAvailable } from "../../../hooks/customization/products/useCheckIfProductPreviewIsAvailable";
import { useProductPreview } from "../../../hooks/customization/products/useProductPreview";
import { useConfiguratorAPIKey } from "../../../hooks/settings/useConfiguratorAPIKey";

const AlertBannerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
`;

type ProductPreviewButtonProps = {
  productToken: string | undefined;
  productPublishState: number | undefined;
};

export const ProductPreviewButton: React.FC<ProductPreviewButtonProps> = ({
  productToken,
  productPublishState,
}) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingAPIKey, data } = useConfiguratorAPIKey();
  const apiKey = data?.api_key ?? undefined;

  const [isPreviewAvailable, showPreviewNotAvailableMessage] =
    useCheckIfProductPreviewIsAvailable(productPublishState, apiKey);

  const { isLoading, preview, isShowingConfigurator, shouldLoadConfigurator } =
    useProductPreview(productToken, apiKey);

  return (
    <>
      <Button
        key="design-duplicate"
        icon={<EyeIcon />}
        onClick={() => {
          if (isLoadingAPIKey) {
            return;
          }

          isPreviewAvailable ? preview() : showPreviewNotAvailableMessage();
        }}
        loading={isLoading}
      >
        {t("products.actions.preview.button")}
      </Button>

      {isShowingConfigurator && (
        <AlertBannerWrapper>
          <Alert
            type="info"
            withBackground
            message={t("products.actions.preview.hint")}
            banner
            closable
          />
        </AlertBannerWrapper>
      )}

      {shouldLoadConfigurator && (
        <link rel="stylesheet" href={process.env.REACT_APP_CONFIGURATOR_CSS!} />
      )}
    </>
  );
};
