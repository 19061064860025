import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";
import { ProductAnalyticsApi } from "../../api";

export const useProductsAnalyticsAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, ProductAnalyticsApi>) => {
  return useAPI<T, ProductAnalyticsApi>({
    initialValue,
    APIClass: ProductAnalyticsApi,
    handleRequest,
  });
};
