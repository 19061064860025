import { Formik } from "formik";
import * as Yup from "yup";
import { DisplayConfiguratorSettingsForm } from "./DisplayConfiguratorSettingsForm";
import { DirtyFormPrompt } from "../form/DirtyFormPrompt";
import { useConfiguratorSettings } from "../../hooks/settings/useConfiguratorSettings";
import { useConfiguratorSettingsFormSubmit } from "../../hooks/settings/useConfiguratorSettingsFormSubmit";
import {
  HeaderSaveFormWithConfirmButton,
  UseConfirmOptions,
} from "melco-shared-logic";
import { Alert, Space } from "melco-ui";
import { useTranslation } from "react-i18next";

const ConfiguratorSettingsSchema = Yup.object().shape({
  version: Yup.number().required(),
});

export const ConfiguratorSettingsForm: React.FC = () => {
  const { t } = useTranslation();
  const { settings, isLoading } = useConfiguratorSettings();
  const onSubmit = useConfiguratorSettingsFormSubmit();

  if (isLoading || !settings) {
    return null;
  }

  const confirmOptions: UseConfirmOptions = {
    translationPrefix: "settings.configurator",
    content: (
      <Space direction="vertical" size="small">
        {t("settings.configurator.confirmation.message")}
        <Alert
          type="warning"
          withBackground
          description={t("settings.configurator.confirmation.warning")}
          showIcon={false}
        />
      </Space>
    ),
  };

  return (
    <Formik
      initialValues={settings}
      validationSchema={ConfiguratorSettingsSchema}
      onSubmit={onSubmit}
    >
      <DirtyFormPrompt>
        <DisplayConfiguratorSettingsForm />
        <HeaderSaveFormWithConfirmButton confirmOptions={confirmOptions} />
      </DirtyFormPrompt>
    </Formik>
  );
};
