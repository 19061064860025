import {
  ColorHelper,
  DesignTokens,
  LabeledColorSwatch,
  Menu,
  MenuItem,
  Space,
  SubMenu,
} from "melco-ui";
import { FieldErrorIndicator } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { useElementList } from "../../../hooks/libraries/designs/useElementList";
import { ElementListItem } from "./ElementListItem";
import { colorLabel } from "../../../helper/colorCollectionHelper";

const { spacings } = DesignTokens;
const { rgbToARGB } = ColorHelper;

type ElementListProps = {
  setCurrentDesignElementIndex: (designElementIndex: number) => void;
};

export const ElementList: React.FC<ElementListProps> = ({
  setCurrentDesignElementIndex,
}) => {
  const { t } = useTranslation();

  const { letteringObjects, colorObjects, globalIndex, groupIndex } =
    useElementList();

  return (
    <Menu
      mode="inline"
      inlineIndent={spacings.xlarge}
      defaultOpenKeys={["design-colors"]}
    >
      {letteringObjects.map((element) => {
        const { id } = element;

        return (
          <MenuItem
            key={id}
            onClick={() => setCurrentDesignElementIndex(globalIndex(element))}
          >
            <ElementListItem element={element}>
              <Space size="small">
                {t(
                  "libraries.designs.detail.form.element_list.lettering_title",
                  {
                    index: groupIndex(element),
                  }
                )}
                <FieldErrorIndicator
                  name={`element_list[${globalIndex(element)}]`}
                />
              </Space>
            </ElementListItem>
          </MenuItem>
        );
      })}

      <SubMenu
        key="design-colors"
        title={t("libraries.designs.detail.form.element_list.design_colors")}
      >
        {colorObjects.map((element) => {
          const { id, color } = element;

          return (
            <MenuItem
              key={id}
              onClick={() => setCurrentDesignElementIndex(globalIndex(element))}
            >
              <ElementListItem element={element}>
                <Space size="small">
                  <LabeledColorSwatch
                    argb={rgbToARGB(color?.rgb ?? undefined)}
                    label={color ? colorLabel(color) : ""}
                  />
                  <FieldErrorIndicator
                    name={`element_list[${globalIndex(element)}]`}
                  />
                </Space>
              </ElementListItem>
            </MenuItem>
          );
        })}
      </SubMenu>
    </Menu>
  );
};
