import { Card, Col, DesignTokens, Row, Statistic, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import { calculateTotalSessionssAnalytics } from "../../../helper/analyticsHelper";
import { useSessionsAnalytics } from "../../../hooks/analytics/useSessionsAnalytics";

const { spacings } = DesignTokens;

type SessionsProps = {
  startTimestamp?: Date;
  endTimestamp?: Date;
};

export const SessionsCard: React.FC<SessionsProps> = ({
  startTimestamp,
  endTimestamp,
}) => {
  const { t } = useTranslation();

  const { dailySummary, isLoading } = useSessionsAnalytics(
    startTimestamp,
    endTimestamp
  );
  const { dropped, completed } = calculateTotalSessionssAnalytics(dailySummary);

  return (
    <Card
      title={
        <Title level={5}>{t("dashboard.statistics.sessions.title")}</Title>
      }
      data-cy-id="dashboard-statistics-sessions"
    >
      <Row gutter={[spacings.middle, spacings.middle]}>
        <Col span={spacings.small}>
          <Statistic
            title={t("dashboard.statistics.sessions.completed.title")}
            value={completed}
            loading={isLoading}
          />
        </Col>
        <Col span={spacings.small}>
          <Statistic
            title={t("dashboard.statistics.sessions.dropped.title")}
            value={dropped}
            loading={isLoading}
          />
        </Col>
        <Col span={spacings.small}>
          <Statistic
            title={t("dashboard.statistics.sessions.total.title")}
            value={dropped + completed}
            loading={isLoading}
          />
        </Col>
      </Row>
    </Card>
  );
};
