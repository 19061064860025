import { isEmpty } from "lodash-es";
import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { MelcoCoreModelsThread } from "melco-shared-logic";

type BulkActionAddColorsProps = {
  allThreads: MelcoCoreModelsThread[];
  select: (items: MelcoCoreModelsThread[]) => void;
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionAddColors: React.FC<BulkActionAddColorsProps> = ({
  allThreads,
  select,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const { t } = useTranslation();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-add-selected"
      icon={<PlusIcon />}
      onClick={() => {
        const threadsToAdd = allThreads.filter((t) =>
          selectedRowKeys.includes(t.id!)
        );

        select(threadsToAdd);

        setSelectedRowKeys([]);
      }}
    >
      {t("color_collections.actions.add_colors.actions.add_selected")}
    </Button>
  );
};
