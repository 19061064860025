import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForColorCollection = (
  colorCollectionId: string,
  publishState: PublishState
) => ["colorCollection", colorCollectionId, publishState];

export const useColorCollection = (
  colorCollectionId: string,
  publishState: PublishState
) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForColorCollection(colorCollectionId, publishState),
    async (api) =>
      api.userColorCollectionsGetDef({ id: colorCollectionId, publishState })
  );
};
