import styled from "styled-components";
import { AppIcon, SecondaryNavigationMenuItem } from "melco-ui";

const StyledIcon = styled.a`
  margin-top: 2px;
  font-size: 18px;
`;

export const BackToAccountsLink: React.FC = () => {
  return (
    <SecondaryNavigationMenuItem>
      <StyledIcon
        href={`${process.env.REACT_APP_ACCOUNT_URL!}dashboard`}
        data-cy-id="floating-navigation-account-link"
      >
        <AppIcon />
      </StyledIcon>
    </SecondaryNavigationMenuItem>
  );
};
