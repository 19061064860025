import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CanvasActionGroup, CanvasPanel, DesignTokens, Title } from "melco-ui";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import styled from "styled-components";
import { DesignCanvasWorkingArea } from "../designs/DesignCanvasWorkingArea";
import { DesignsPreviewList } from "./DesignsPreviewList";
import { DesignPreview } from "./DesignPreview";

const { spacings } = DesignTokens;

const Wrapper = styled.div`
  margin-top: ${spacings.xlarge}px;
`;

const DesignsPreviewWrapper = styled.div`
  display: flex;
  margin: 0 -${spacings.xlarge}px;
`;

const LeftCanvasPanel = styled(CanvasPanel)`
  width: 300px;
`;

type DesignsPreviewProps = {
  designs: MelcoCoreModelsDesignListItem[];
};

export const DesignsPreview: React.FC<DesignsPreviewProps> = ({ designs }) => {
  const { t } = useTranslation();

  const [currentDesign, setCurrentDesign] = useState<
    MelcoCoreModelsDesignListItem | undefined
  >();

  useEffect(() => {
    if (!currentDesign && designs[0]) {
      setCurrentDesign(designs[0]);
    }
  }, [currentDesign, designs, setCurrentDesign]);

  return (
    <Wrapper>
      <Title level={5}>
        {t("libraries.design_collections.actions.add_designs.title")}
      </Title>

      <DesignsPreviewWrapper>
        <LeftCanvasPanel
          elevation="right"
          data-cy-id="designs-preview-list-panel"
        >
          <DesignsPreviewList
            designs={designs}
            currentDesign={currentDesign}
            setCurrentDesign={setCurrentDesign}
          />
        </LeftCanvasPanel>

        <DesignCanvasWorkingArea
          actionGroups={
            <CanvasActionGroup positionY="top" positionX="right">
              {/*<ZoomToDesignButtonPortal />*/}
            </CanvasActionGroup>
          }
        >
          {currentDesign && <DesignPreview designId={currentDesign.id!} />}
        </DesignCanvasWorkingArea>
      </DesignsPreviewWrapper>
    </Wrapper>
  );
};
