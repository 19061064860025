import { useCallback } from "react";
import {
  MelcoCoreModelsCustomProductImage,
  MelcoCoreModelsCustomProductImageDesign,
  MelcoCoreModelsDesign,
} from "melco-shared-logic";
import { useField } from "formik";

export const useSetDesignForView = (formikPrefix: string) => {
  const [{ value }, , { setValue }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(`${formikPrefix}.color_list`);

  const setDesign = useCallback(
    (design?: MelcoCoreModelsDesign) => {
      const newColorList = (value ?? []).map((vl) => {
        // TODO: ask whether to set both the renderer_rect and matrix or just the matrix?
        // TODO: ask when this should be set? just when the view is moved?
        const designList: MelcoCoreModelsCustomProductImageDesign[] = design
          ? [
              {
                design,
                renderer_rect: {},
                renderer_matrix: {
                  m00: 1,
                  m01: 0,
                  m02: 0,
                  m10: 0,
                  m11: 1,
                  m12: 0,
                },
              },
            ]
          : [];

        return { ...vl, design_list: designList };
      });

      setValue(newColorList);
    },
    [value, setValue]
  );

  return setDesign;
};
