import { useCallback, useEffect, useState } from "react";
import {
  RangePicker,
  DesignTokens,
  Row,
  Col,
  Button,
  UndoIcon,
} from "melco-ui";
import { Search } from "melco-shared-logic";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty, some } from "lodash-es";

import { SessionStatusFilter } from "./filter/SessionStatusFilter";
import { ProductionStatusFilter } from "./filter/ProductionStatusFilter";
import { OrdersListFiltersType } from "../../hooks/orders/useOrders";

const { spacings } = DesignTokens;

type OrdersListFilterProps = {
  onFiltersChange: (filters: OrdersListFiltersType | undefined) => void;
};

export const OrdersListFilter: React.FC<OrdersListFilterProps> = ({
  onFiltersChange,
}) => {
  const { t } = useTranslation();

  const [ordersFilter, setOrdersFilter] = useState<
    OrdersListFiltersType | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    onFiltersChange(ordersFilter);
  }, [ordersFilter, onFiltersChange]);

  const resetFilter = useCallback(() => {
    setSearchValue("");
    setOrdersFilter(undefined);
  }, []);

  return (
    <Row gutter={[spacings.middle, spacings.middle]}>
      <Col flex={0}>
        <Search
          onSearchInputChange={(value) => {
            setOrdersFilter((prevFilter) => {
              return { ...prevFilter, searchText: value };
            });
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
      </Col>
      <Col flex={1}>
        <SessionStatusFilter
          onChange={(value) => {
            setOrdersFilter((prevFilter) => {
              return {
                ...prevFilter,
                sessionStatus: !isEmpty(value) ? value : undefined,
              };
            });
          }}
          value={ordersFilter?.sessionStatus ?? undefined}
        />
      </Col>
      <Col flex={1}>
        <ProductionStatusFilter
          onChange={(value) => {
            setOrdersFilter((prevFilter) => {
              return {
                ...prevFilter,
                productionStatus: !isEmpty(value) ? value : undefined,
              };
            });
          }}
          value={ordersFilter?.productionStatus ?? undefined}
        />
      </Col>
      <Col flex={0}>
        <RangePicker
          format={moment.localeData().longDateFormat("L")}
          onChange={(value) => {
            const startTimeStamp =
              (value && value[0] && value[0].startOf("day").toDate()) ??
              undefined;
            const endTimeStamp =
              (value && value[1] && value[1].endOf("day").toDate()) ??
              undefined;

            setOrdersFilter((prevFilter) => {
              return {
                ...prevFilter,
                startTimeStamp,
                endTimeStamp,
              };
            });
          }}
          value={
            ordersFilter?.startTimeStamp &&
            ordersFilter?.endTimeStamp && [
              moment(ordersFilter?.startTimeStamp),
              moment(ordersFilter?.endTimeStamp),
            ]
          }
          placeholder={[
            t("orders_list.filter.start_timestamp.placeholder"),
            t("orders_list.filter.end_timestamp.placeholder"),
          ]}
        />
      </Col>
      <Col xs={6} md={5} xl={3}>
        {some(ordersFilter) && (
          <Button
            icon={<UndoIcon />}
            type="primary"
            onClick={resetFilter}
            data-cy-id="orders-filter-reset"
            style={{ minWidth: "100%" }}
          >
            {t("filter.reset.button", { ns: "melco-common" })}
          </Button>
        )}
      </Col>
    </Row>
  );
};
