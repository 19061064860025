import { Button, DuplicateIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDuplicateBlankPicture } from "../../../../hooks/libraries/blanks/blankPictures/useDuplicateBlankPicture";

type BlankPictureDuplicateButtonProps = {
  blankPictureId: string;
};

export const BlankPictureDuplicateButton: React.FC<
  BlankPictureDuplicateButtonProps
> = ({ blankPictureId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [duplicateBlankPicture, isLoading] =
    useDuplicateBlankPicture(blankPictureId);

  return (
    <Button
      key="blank-picture-duplicate"
      icon={<DuplicateIcon />}
      onClick={async () => {
        const blankPicture = await duplicateBlankPicture();

        if (blankPicture) {
          navigate(`/libraries/blank-pictures/${blankPicture.id}`);
        }
      }}
      loading={isLoading}
    >
      {t("blank_pictures.actions.duplicate.button")}
    </Button>
  );
};
