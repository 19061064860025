import { TokenRestoreState, useTokenRestore } from "melco-shared-logic";
import { Main } from "./Main";

export const RestoreToken: React.FC = () => {
  const tokenRestoreState = useTokenRestore();

  switch (tokenRestoreState) {
    case TokenRestoreState.RestoredSuccessfully:
      return <Main />;

    case TokenRestoreState.RestoreFailed:
      window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}?redirectTo=${process.env.REACT_APP_APP_URL}`;
      // console.error("Restore of token failed");
      return null;

    default:
      // TODO: Add loading indicator
      return null;
  }
};
