import { useField } from "formik";
import { isEmpty } from "lodash";
import { MelcoCoreModelsUserImage } from "melco-shared-logic";
import { useCallback } from "react";

const canvasMinSize = 500;
const canvasMaxSize = 800;

const clampCanvasSize = (size?: number) => {
  if (!size) {
    return canvasMinSize;
  }

  return Math.max(canvasMinSize, Math.min(canvasMaxSize, size));
};

export const useSetInitialCanvasSize = () => {
  const [{ value: canvasWidth }, , { setValue: setCanvasWidth }] = useField<
    number | undefined
  >("canvas_width");
  const [{ value: canvasHeight }, , { setValue: setCanvasHeight }] = useField<
    number | undefined
  >("canvas_height");

  return useCallback(
    (blankPicture: MelcoCoreModelsUserImage) => {
      if (isEmpty(String(canvasWidth)) || (canvasWidth ?? 0) <= 0) {
        setCanvasWidth(clampCanvasSize(blankPicture.width));
      }

      if (isEmpty(String(canvasHeight)) || (canvasHeight ?? 0) <= 0) {
        setCanvasHeight(clampCanvasSize(blankPicture.height));
      }
    },
    [canvasWidth, setCanvasWidth, canvasHeight, setCanvasHeight]
  );
};
