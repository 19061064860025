import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { ConfiguratorSettingsForm } from "../../components/settings/ConfiguratorSettingsForm";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { HeaderButtonArea } from "melco-shared-logic";

export const ConfiguratorSettings: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("settings");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("settings.configurator.title")}
          breadcrumb={breadcrumb}
          extra={<HeaderButtonArea />}
        />
      }
      contentPadding
    >
      <ConfiguratorSettingsForm />
    </MainContentArea>
  );
};
