import { Formik } from "formik";
import * as Yup from "yup";
import { DisplayBasicSettingsForm } from "./DisplayBasicSettingsForm";
import { DirtyFormPrompt } from "../form/DirtyFormPrompt";
import { useBasicSettings } from "../../hooks/settings/useBasicSettings";
import { useSettings } from "../../hooks/settings/useSettings";
import { useBasicSettingsFormSubmit } from "../../hooks/settings/useBasicSettingsFormSubmit";
import { HeaderSaveFormButton } from "melco-shared-logic";

const BasicSettingsSchema = Yup.object().shape({
  measurement_unit: Yup.string().required(),
  default_embroidery_production_file_format: Yup.string().required(),
});

export const BasicSettingsForm: React.FC = () => {
  const { settings, isLoading } = useBasicSettings();
  const { settings: defaultSettings, isLoading: isDefaultSettingsLoading } =
    useSettings();
  const onSubmit = useBasicSettingsFormSubmit();

  if (isLoading || !settings || isDefaultSettingsLoading || !defaultSettings) {
    return null;
  }

  return (
    <Formik
      initialValues={settings}
      validationSchema={BasicSettingsSchema}
      onSubmit={onSubmit}
    >
      <DirtyFormPrompt>
        <DisplayBasicSettingsForm defaultSettings={defaultSettings} />
        <HeaderSaveFormButton />
      </DirtyFormPrompt>
    </Formik>
  );
};
