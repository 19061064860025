import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ColorCollectionsList } from "../../../components/libraries/colorCollections/ColorCollectionsList";
import { AddColorCollectionButton } from "../../../components/libraries/colorCollections/AddColorCollectionButton";

export const ColorCollections: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("libraries");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("libraries.color_collections.title")}
          breadcrumb={breadcrumb}
          extra={<AddColorCollectionButton />}
        />
      }
    >
      <ColorCollectionsList />
    </MainContentArea>
  );
};
