import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddDesignCollection } from "../../../hooks/libraries/designCollections/useAddDesignCollection";

export const AddDesignCollectionButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addDesignCollection, isLoading] = useAddDesignCollection();

  return (
    <Button
      key="design-collection-add"
      type="primary"
      icon={<PlusIcon />}
      onClick={async () => {
        const colorCollection = await addDesignCollection();

        if (colorCollection) {
          navigate(`/libraries/design-collections/${colorCollection.id!}`);
        }
      }}
      loading={isLoading}
    >
      {t("libraries.design_collections.actions.add.button")}
    </Button>
  );
};
