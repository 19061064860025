import { useCallback } from "react";
import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDeleteDesignCollections = () => {
  const [deleteDesignCollection] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, id: string) => {
      const data = await api.userDesignCollectionsDelete({ id, force: true });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "libraries.design_collections.actions.delete",
    }
  );

  return useCallback(
    async (designCollectionIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const designCollectionId of designCollectionIds) {
        try {
          await deleteDesignCollection(designCollectionId);
          successfullyDeleted.push(designCollectionId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteDesignCollection]
  );
};
