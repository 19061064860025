import { useState } from "react";
import {
  DisplayFormErrors,
  InputField,
  MelcoCoreModelsUserImage as MelcoCoreModelsUserBlank,
  MelcoCoreModelsUserImage,
  VerticalForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import {
  Col,
  DesignTokens,
  DividerLine,
  FullWidthSpace,
  PreviewSider,
  Row,
  Title,
} from "melco-ui";
import styled from "styled-components";
import { useField } from "formik";
import { isEmpty } from "lodash-es";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertBlank } from "../../../hooks/libraries/blanks/useRevertBlank";
import { AddBlankPicturesDrawer } from "./AddBlankPicturesDrawer";
import { BlankBlankPicturesList } from "./BlankBlankPicturesList";
import { BlankColorsSortableList } from "./BlankColorsSortableList";
import { BlankViewsSortableList } from "./BlankViewsSortableList";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { useBlankReferencesCount } from "../../../hooks/libraries/blanks/useBlankReferencesCount";

const { spacings } = DesignTokens;

const StyledDividerLine = styled(DividerLine)`
  margin-left: -${spacings.middle}px;
  margin-right: -${spacings.middle}px;
`;

type DisplayBlankDetailFormProps = {
  blank: MelcoCoreModelsUserBlank;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsUserBlank, unknown>
  >;
  referencesDrawer: DrawerType;
};

export const DisplayBlankDetailForm: React.FC<DisplayBlankDetailFormProps> = ({
  blank,
  refetch,
  referencesDrawer,
}) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = blank;

  const { t } = useTranslation();
  const [revertBlank, isLoading] = useRevertBlank(id!, refetch);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { total: referencesCount } = useBlankReferencesCount(id!);

  const [{ value: blankPictures }] =
    useField<MelcoCoreModelsUserImage[]>("images");

  return (
    <>
      <SavePublishHeader
        revertAction={revertBlank}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <Row gutter={[spacings.xlarge, spacings.xlarge]}>
        <Col xs={isEmpty(blankPictures) ? 24 : 16}>
          <VerticalForm>
            <FullWidthSpace direction="vertical">
              <div>
                <DisplayFormErrors />

                <InputField
                  name="name"
                  label={t("libraries.blanks.blanks.detail.form.label.name")}
                />

                <Row gutter={[spacings.middle, 0]}>
                  <Col xs={24} md={12}>
                    <InputField
                      name="canvas_width"
                      type="number"
                      label={t(
                        "libraries.blanks.blanks.detail.form.label.canvas_size"
                      )}
                      addonBefore={t(
                        "libraries.blanks.blanks.detail.form.label.canvas_width"
                      )}
                      addonAfter={t(
                        "libraries.blanks.blanks.detail.form.label.canvas_size_unit"
                      )}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <InputField
                      name="canvas_height"
                      type="number"
                      label=" "
                      addonBefore={t(
                        "libraries.blanks.blanks.detail.form.label.canvas_height"
                      )}
                      addonAfter={t(
                        "libraries.blanks.blanks.detail.form.label.canvas_size_unit"
                      )}
                    />
                  </Col>
                </Row>
              </div>

              <BlankBlankPicturesList setDrawerOpen={setDrawerOpen} />
            </FullWidthSpace>
          </VerticalForm>

          <AddBlankPicturesDrawer
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </Col>

        {!isEmpty(blankPictures) && (
          <Col xs={8}>
            <PreviewSider>
              <FullWidthSpace direction="vertical" size="small">
                <Title level={5}>
                  {t("libraries.blanks.blanks.detail.preview_sider.title")}
                </Title>
                <div>
                  <BlankViewsSortableList />
                  <StyledDividerLine />
                  <BlankColorsSortableList />
                </div>
              </FullWidthSpace>
            </PreviewSider>
          </Col>
        )}
      </Row>
    </>
  );
};
