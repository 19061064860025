import { useQueries } from "react-query";
import {
  useAuthenticatedAPIConfig,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";

export const useColorCollectionsForRenderer = (
  colorCollectionIds: string[]
) => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

  const publishState = "published";

  const queries = colorCollectionIds.map((id) => ({
    queryKey: ["colorCollection", id, publishState],
    queryFn: async () => api.userColorCollectionsGetDef({ id, publishState }),
  }));

  return useQueries(queries);
};
