import { useColorCollectionReferences } from "./useColorCollectionReferences";

export const useColorCollectionReferencesCount = (
  colorCollectionId: string
) => {
  const { data: colorCollectionReferences } =
    useColorCollectionReferences(colorCollectionId);

  const totalDesigns = colorCollectionReferences?.design_list?.length || 0;
  const totalProducts =
    colorCollectionReferences?.custom_product_list?.length || 0;

  return { total: totalDesigns + totalProducts };
};
