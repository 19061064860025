import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useBlankPictureUpload = () => {
  const [uploadBlankPicture] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, file: Blob) => {
      const data = await api.userImageUploadDraft({
        file,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "blank_pictures.actions.upload",
    }
  );

  return {
    uploadBlankPicture,
  } as const;
};
