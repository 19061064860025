import React from "react";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { snakeCase } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Breadcrumb = keyof typeof breadcrumbToPath;

const breadcrumbToPath = {
  settings: {
    path: "/settings",
  },
  settingsBasic: {
    path: "/settings/basic",
  },
  orders: {
    path: "/orders",
  },
  customization: {
    path: "/customization",
  },
  customizationProducts: {
    path: "/customization/products",
  },
  libraries: {
    path: "/libraries",
  },
  librariesBlanks: {
    path: "/libraries/blanks",
  },
  librariesBlanksBlankPictures: {
    path: "/libraries/blank-pictures",
  },
  librariesDesigns: {
    path: "/libraries/designs",
  },
  librariesDesignCollections: {
    path: "/libraries/design-collections",
  },
  librariesFontsEmbroidery: {
    path: "/libraries/fonts/embroidery",
  },
  librariesColorsEmbroidery: {
    path: "/libraries/colors/embroidery",
  },
  librariesColorCollectionsEmbroidery: {
    path: "/libraries/color-collections/embroidery",
  },
};

export const useBreadcrumb = (...breadcrumbs: Breadcrumb[]) => {
  const { t } = useTranslation();

  const routes = breadcrumbs.map((b) => {
    return {
      ...breadcrumbToPath[b],
      breadcrumbName: t(`main_menu.${snakeCase(b)}` as any),
    };
  });

  const itemRender: (route: Route) => React.ReactNode = (route: Route) => {
    return <Link to={route.path}>{route.breadcrumbName}</Link>;
  };

  return { routes, itemRender };
};
