import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useAddBlank } from "../../../hooks/libraries/blanks/useAddBlank";
import { useNavigate } from "react-router-dom";

export const AddBlankButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addBlank, isLoading] = useAddBlank();

  return (
    <Button
      key="blank-add"
      type="primary"
      icon={<PlusIcon />}
      onClick={async () => {
        const blank = await addBlank();
        if (blank) {
          navigate(`/libraries/blanks/${blank.id!}`);
        }
      }}
      loading={isLoading}
    >
      {t("blanks.actions.add.button")}
    </Button>
  );
};
