import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { FontReferencesList } from "./FontReferencesList";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type FontReferencesDrawerProps = {
  drawer: DrawerType;
  id: string;
};

export const FontReferencesDrawer: React.FC<FontReferencesDrawerProps> = ({
  drawer,
  id: fontId,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("fonts.actions.references.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <FontReferencesList fontId={fontId} />
    </Drawer>
  );
};
