import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useAddDesignCollection = () =>
  useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userDesignCollectionsCreateDraft({
        melcoCoreModelsCreateDesignCollection: {
          design_list: [],
        },
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "libraries.design_collections.actions.add",
    }
  );
