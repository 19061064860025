import { useField } from "formik";
import { MelcoCoreModelsUserImage } from "melco-shared-logic";

export const useBlankPictureByIdFromProduct = (blankPictureId: string) => {
  const [{ value: blankPictures }] = useField<
    MelcoCoreModelsUserImage[] | undefined
  >("blank.images");

  return (blankPictures ?? []).find((bp) => bp.id === blankPictureId);
};
