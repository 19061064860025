import { useCallback, useState } from "react";
import { Button, CheckIcon, Col, Table, TableActionBarRow } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  DrawerType,
  MelcoCoreModelsDesignListItem,
  Search,
  ViewContext,
} from "melco-shared-logic";
import { useDesigns } from "../../../hooks/libraries/designs/useDesigns";
import { designsListColumns } from "../../libraries/designs/DesignsList";
import { useSetDesignForView } from "../../../hooks/customization/products/useSetDesignForView";
import { useFetchDesign } from "../../../hooks/libraries/designs/useFetchDesign";

type ChooseDesignListProps = {
  drawer: DrawerType;
  formikPrefix: string;
};

export const ChooseDesignList: React.FC<ChooseDesignListProps> = ({
  drawer,
  formikPrefix,
}) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useDesigns("published");

  const { t } = useTranslation();
  const { closeDrawer, drawerField } = drawer;
  const { isSelected } = drawerField;

  const setDesign = useSetDesignForView(formikPrefix);
  const [selectedDesignId, setSelectedDesignId] = useState<string | undefined>(
    undefined
  );
  const [fetchDesign, isFetchingDesign] = useFetchDesign();

  const onSelect = useCallback(
    async (selectedDesign: MelcoCoreModelsDesignListItem) => {
      setSelectedDesignId(selectedDesign.id!);

      try {
        const design = await fetchDesign(selectedDesign.id!, "published");

        if (design) {
          setDesign(design);
          closeDrawer();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSelectedDesignId(undefined);
      }
    },
    [setSelectedDesignId, fetchDesign, setDesign, closeDrawer]
  );

  const columns = [
    ...designsListColumns(t, ViewContext.Drawer),
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, d: MelcoCoreModelsDesignListItem) => {
        return isSelected(d.id) ? (
          <Button type="primary" icon={<CheckIcon />} size="small" disabled>
            {t("products.actions.choose_design.actions.selected")}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<CheckIcon />}
            size="small"
            onClick={() => onSelect(d)}
            loading={isFetchingDesign && selectedDesignId === d.id}
          >
            {t("products.actions.choose_design.actions.select")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        noDataMessage={t("designs_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
