import { useField } from "formik";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import { message } from "melco-ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useAddDesigns = () => {
  const { t } = useTranslation();

  const [{ value: designs }, , { setValue: setDesigns, setError }] =
    useField<MelcoCoreModelsDesignListItem[]>("design_list");

  const isAlreadyAdded = (design: MelcoCoreModelsDesignListItem) =>
    design.id && designs.find((d) => d.id === design.id);

  const addDesigns = useCallback(
    (toAdd: MelcoCoreModelsDesignListItem[]) => {
      const addedDesigns = toAdd
        .filter((d) => !isAlreadyAdded(d))
        .map((design) => {
          const { id, name, previews } = design;

          return {
            id,
            name,
            previews,
          };
        });

      setDesigns([...(designs ?? []), ...addedDesigns]);

      message.success(
        t("libraries.design_collections.actions.add_designs.actions.added", {
          count: addedDesigns.length,
        })
      );
    },
    [designs, setDesigns, t]
  );

  const removeDesigns = useCallback(
    (toRemove: { id: string }[]) => {
      const idsToRemove = toRemove.map((d) => d.id);

      setDesigns(designs.filter((d) => !idsToRemove.includes(d.id!)));

      // reset field error as removing a field changes the index positions which could lead to errors being assigned to the wrong field
      setError(undefined);
    },
    [designs, setDesigns, setError]
  );

  return {
    designs,
    addDesigns,
    removeDesigns,
    isAlreadyAdded,
  };
};
