import { InputNumberField, SwitchField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DefaultTextField } from "./DefaultTextField";
import { FontsField } from "./FontsField";

const StyledInputNumberField = styled(InputNumberField)`
  width: 100%;
`;

type LetteringElementFieldsProps = {
  formikPrefix: string;
};

export const LetteringElementFields: React.FC<LetteringElementFieldsProps> = ({
  formikPrefix,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FontsField formikPrefix={formikPrefix} />

      <StyledInputNumberField
        name={`${formikPrefix}.lettering.text_max_length`}
        label={t("libraries.designs.detail.form.label.text_max_length")}
        min={1}
      />

      <DefaultTextField formikPrefix={formikPrefix} />

      <SwitchField
        name={`${formikPrefix}.is_required`}
        label={t("libraries.designs.detail.form.label.is_required")}
      />
    </>
  );
};
