import {
  MelcoCoreModelsDesignCollection,
  selectThumbnailForSize,
} from "melco-shared-logic";

export const useDesignCollectionsThumbnailURL = (
  designCollection: MelcoCoreModelsDesignCollection,
  width: number,
  height: number
) => {
  const { default_design } = designCollection;

  const thumbnail = selectThumbnailForSize(
    default_design?.previews ?? [],
    Math.max(width, height)
  );

  return thumbnail?.url;
};
