import dayjs from "dayjs";
import { FullWidthSpace, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import { DesignsChart } from "./DesignsChart";

export const PerformanceAPIService: React.FC = () => {
  const { t } = useTranslation();
  const now = new Date();
  const startTimestamp = dayjs(now).subtract(12, "month").toDate();

  const endTimestamp = now;

  return (
    <FullWidthSpace
      direction="vertical"
      size="small"
      data-cy-id="dashboard-performance"
    >
      <Title level={4}>{t("dashboard.performance.title")}</Title>
      <DesignsChart
        startTimestamp={startTimestamp}
        endTimestamp={endTimestamp}
      />
    </FullWidthSpace>
  );
};
