import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const useFetchDesign = () => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api, designId: string, publishState: PublishState) => {
      const data = await api.userDesignsGetDef({ id: designId, publishState });

      return {
        successMessages: [],
        data,
      };
    },
    {
      translationPrefix: "global",
    }
  );
};
