import { Trans } from "react-i18next";
import { Text, Link } from "melco-ui";

type ToggleWarningsLinkProps = {
  toggleWarnings: () => void;
};

export const ToggleWarningsLink: React.FC<ToggleWarningsLinkProps> = ({
  toggleWarnings,
}) => {
  return (
    <>
      <Text type="secondary">
        {" - "}
        <Trans i18nKey="global.action_header.warnings.warning_link">
          Publish won't work,
          <Link underline type="secondary" onClick={toggleWarnings}>
            see why
          </Link>
        </Trans>
      </Text>
    </>
  );
};
