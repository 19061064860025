import { useField } from "formik";
import { useCallback } from "react";

export type DrawerSelectFieldType<T> = {
  name: string;
  label?: string;
  value: T | undefined;
  isSelected: (item: T | undefined) => boolean;
  select: (items: T[]) => void;
  unselect: (items: T[]) => void;
};

export const useDrawerSelectField = <T extends any>(
  name: string,
  label?: string
) => {
  const [{ value }, , { setValue }] = useField<T | undefined>(name);

  const isSelected = useCallback(
    (item: T | undefined) => {
      if (value == null || item == null) {
        return false;
      }

      // check by id equality
      const itemAsAny: any = item;
      const valueAsAny: any = value;

      if (itemAsAny && itemAsAny.id && valueAsAny && valueAsAny.id) {
        return itemAsAny.id === valueAsAny.id;
      }

      return value === item;
    },
    [value]
  );

  const select = useCallback(
    (items: T[]) => items.length > 0 && setValue(items[0]),
    [setValue]
  );

  const unselect = useCallback((_item: T[]) => setValue(undefined), [setValue]);

  return {
    name,
    label,
    value,
    isSelected,
    select,
    unselect,
  } as DrawerSelectFieldType<T>;
};
