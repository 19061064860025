import { DesignTokens, Alert } from "melco-ui";
import styled from "styled-components";

const { spacings } = DesignTokens;

const StyledAlert = styled(Alert)`
  margin: ${spacings.xlarge}px ${spacings.xlarge}px 0px ${spacings.xlarge}px;
`;

type ReferencesListAlertProps = {
  message: React.ReactNode;
};

export const ReferencesListAlert: React.FC<ReferencesListAlertProps> = ({
  message,
}) => <StyledAlert type="warning" message={message} />;
