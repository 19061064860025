import { isEmpty } from "lodash-es";
import { Button, DownloadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import saveAs from "file-saver";
import { Key } from "antd/lib/table/interface";
import { useDownloadOrders } from "../../hooks/orders/useDownloadOrders";

type BulkActionDownloadOrdersProps = {
  selectedRowKeys: Key[];
};

export const BulkActionDownloadOrders: React.FC<
  BulkActionDownloadOrdersProps
> = ({ selectedRowKeys }) => {
  const { t } = useTranslation();

  const [downloadOrders, isLoading] = useDownloadOrders();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-download"
      icon={<DownloadIcon />}
      onClick={async () => {
        const response = await downloadOrders(selectedRowKeys as string[]);

        if (response) {
          const { raw } = response;
          const blob = await raw.blob();
          const filename = raw.headers.get("x-filename") ?? undefined;

          saveAs(blob, filename);
        }
      }}
      loading={isLoading}
    >
      {t("orders.actions.download.button")}
    </Button>
  );
};
