import { CanvasWorkingArea } from "melco-ui";

type DesignCanvasWorkingAreaProps = {
  actionGroups?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

export const DesignCanvasWorkingArea: React.FC<
  DesignCanvasWorkingAreaProps
> = ({ actionGroups, children, className }) => (
  <CanvasWorkingArea className={className}>
    {children}

    {actionGroups}
  </CanvasWorkingArea>
);
