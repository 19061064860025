import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useDuplicateFont = (fontId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userAlphabetDuplicateDraft({
        id: fontId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    { translationPrefix: "fonts.actions.duplicate" }
  );
};
