import {
  MelcoCoreModelsCustomProduct,
  MelcoCoreModelsCustomProductView,
  SwitchField,
  TextAreaField,
  useDrawer,
  useDrawerSelectField,
} from "melco-shared-logic";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DesignTokens, CanvasPanel } from "melco-ui";
import { ChooseDesignDrawer } from "./ChooseDesignDrawer";
import { DrawerSelectField } from "../../form/DrawerSelectField";
import { ProductViewCanvas } from "./ProductViewCanvas";
import { DisplayNameOfDesign } from "./DisplayNameOfDesign";
import { HoopSelect } from "./HoopSelect";
import { useDesignAttributes } from "../../../hooks/customization/products/useDesignAttributes";
import { DisplayNameOfDesignCollection } from "./DisplayNameOfDesignCollection";

const { spacings } = DesignTokens;

const CanvasWrapper = styled.div`
  display: flex;
`;

const LeftCanvasPanel = styled(CanvasPanel)`
  width: 300px;
`;

const LeftCanvasWrapper = styled.div`
  margin-right: ${spacings.large}px;
`;

type ProductViewTabProps = {
  product: MelcoCoreModelsCustomProduct;
  view: MelcoCoreModelsCustomProductView;
  formikPrefix: string;
};

export const ProductViewTab: React.FC<ProductViewTabProps> = ({
  view,
  formikPrefix,
}) => {
  const { t } = useTranslation();

  const designIdField = useDrawerSelectField<string | undefined>(
    `${formikPrefix}.color_list[0].design_list[0].design.id`,
    t("customization.products.detail.form.label.design")
  );

  const designCollectionIdField = useDrawerSelectField<string | undefined>(
    `${formikPrefix}.color_list[0].design_list[0].design_collection.id`,
    t("customization.products.detail.form.label.design_collection")
  );

  const drawer = useDrawer([designIdField, designCollectionIdField]);

  const {
    hoopFieldName,
    productionRotationDegreeFieldName,
    productionInstructionsFieldName,
  } = useDesignAttributes(formikPrefix);

  const hasDesignCollectionBeenSelected = !!designCollectionIdField.value;
  const hasDesignBeenSelected = !!designIdField.value;

  return (
    <>
      <CanvasWrapper>
        <LeftCanvasPanel
          elevation="right"
          data-cy-id={`product-configuration-${view.name}`}
        >
          <LeftCanvasWrapper>
            {hasDesignCollectionBeenSelected ? (
              <DrawerSelectField
                type="choose"
                field={designCollectionIdField}
                drawer={drawer}
                render={(designCollectionId) =>
                  designCollectionId && (
                    <DisplayNameOfDesignCollection
                      designCollectionId={designCollectionId}
                    />
                  )
                }
                compact
              />
            ) : (
              <DrawerSelectField
                type="choose"
                field={designIdField}
                drawer={drawer}
                render={(designId) =>
                  designId && <DisplayNameOfDesign designId={designId} />
                }
                compact
              />
            )}

            {hasDesignBeenSelected && (
              <>
                <HoopSelect name={hoopFieldName} />

                <SwitchField
                  name={productionRotationDegreeFieldName}
                  label={t(
                    "customization.products.detail.form.label.production_rotation_degree"
                  )}
                  valueConverter={{
                    fromValueToBoolean: (value: number | undefined) =>
                      value === 180,
                    fromBooleanToValue: (value?: boolean) => (value ? 180 : 0),
                  }}
                />

                <TextAreaField
                  name={productionInstructionsFieldName}
                  label={t(
                    "customization.products.detail.form.label.production_instructions"
                  )}
                  showCount
                  maxLength={1000}
                  rows={3}
                />
              </>
            )}
          </LeftCanvasWrapper>
        </LeftCanvasPanel>

        <ProductViewCanvas formikPrefix={formikPrefix} />
      </CanvasWrapper>

      <ChooseDesignDrawer drawer={drawer} formikPrefix={formikPrefix} />
    </>
  );
};
