import { DesignTokens, Row } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MelcoCoreModelsDesign } from "../../../../.yalc/melco-shared-logic/dist";
import { DesignInfoItem } from "./DesignInfoItem";
import { DesignSize } from "./DesignSize";

const { spacings } = DesignTokens;

const StyledRow = styled(Row)`
  width: 270px;
`;

const displayNumber = (number?: number) => {
  if (number == null) {
    return undefined;
  }

  return String(number);
};

type DesignInfosProps = {
  design: MelcoCoreModelsDesign;
};

export const DesignInfos: React.FC<DesignInfosProps> = ({ design }) => {
  const { t } = useTranslation();
  const translationPrefix = "libraries.designs.detail.form";

  const { colors, color_changes, stitches, trims } = design;

  return (
    <StyledRow gutter={[spacings.xlarge, 0]}>
      <DesignInfoItem
        label={t(`${translationPrefix}.label.size`)}
        value={<DesignSize design={design} />}
      />
      <DesignInfoItem
        label={t(`${translationPrefix}.label.trims`)}
        value={displayNumber(trims)}
      />
      <DesignInfoItem
        label={t(`${translationPrefix}.label.colors`)}
        value={displayNumber(colors)}
      />
      <DesignInfoItem
        label={t(`${translationPrefix}.label.color_changes`)}
        value={displayNumber(color_changes)}
      />
      <DesignInfoItem
        label={t(`${translationPrefix}.label.stitches`)}
        value={displayNumber(stitches)}
      />
    </StyledRow>
  );
};
