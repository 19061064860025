import { DesignTokens, Alert } from "melco-ui";
import styled from "styled-components";

const { spacings } = DesignTokens;

const StyledAlert = styled(Alert)`
  margin: ${spacings.xlarge}px ${spacings.xlarge}px -${spacings.small}px ${spacings.xlarge}px;
`;

type BlankPictureMaximumAlertProps = {
  message: React.ReactNode;
};

export const BlankPictureMaximumAlert: React.FC<
  BlankPictureMaximumAlertProps
> = ({ message }) => <StyledAlert type="info" message={message} />;
