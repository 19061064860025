import { ReactNode } from "react";
import { FormItem, ColorInputProps, ColorInput } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

type InputFieldProps = {
  name: string;
  label?: string;
  info?: ReactNode;
};

export const ColorInputField = (
  props: InputFieldProps & Omit<ColorInputProps, "onChange">
) => {
  const { t } = useTranslation();
  const { name, label, info, ...inputProps } = props;
  const [field, meta, { setTouched, setValue }] = useField(name!);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
      info={info}
    >
      <ColorInput
        {...inputProps}
        {...field}
        onChange={(argb) => {
          setTouched(true);
          setValue(argb);
        }}
      />
    </FormItem>
  );
};
