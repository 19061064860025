import {
  BulkActionCount,
  DisplayFieldErrors,
  MelcoCoreModelsDesignListItem,
  useTableSelection,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import {
  Button,
  DesignTokens,
  EditIcon,
  FullWidthSpace,
  PlusIcon,
  Space,
  Table,
  Title,
  ValueOrFallback,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import get from "lodash-es/get";
import { BulkActionRemoveDesigns } from "./BulkActionRemoveDesigns";
import { useAddDesigns } from "../../../hooks/libraries/designCollections/useAddDesigns";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { DesignsListThumbnail } from "../designs/DesignsListThumbnail";

const { colors, spacings } = DesignTokens;

const EmptyDesignCollectionWrapper = styled.div`
  border: 1px dashed ${colors.gray.middle};
  padding: ${spacings.middle}px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  margin-left: -${spacings.xlarge}px;
  margin-right: -${spacings.xlarge}px;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type DesignCollectionDesignsListProps = {
  drawer: DrawerType;
};

export const DesignCollectionDesignsList: React.FC<
  DesignCollectionDesignsListProps
> = ({ drawer }) => {
  const { t } = useTranslation();

  const { openDrawer } = drawer;

  const { selectedRowKeys, setSelectedRowKeys, rowSelection } =
    useTableSelection<MelcoCoreModelsDesignListItem>(() => {});

  const { designs } = useAddDesigns();

  const title = (
    <Title level={5}>
      {t("libraries.design_collections.actions.add_designs.title")}
    </Title>
  );

  const addButton = (
    <Button type="primary" icon={<PlusIcon />} onClick={openDrawer}>
      {t("libraries.design_collections.actions.add_designs.button")}
    </Button>
  );

  if (isEmpty(designs)) {
    return (
      <div>
        {title}

        <DisplayFieldErrors name="design_list" />

        <EmptyDesignCollectionWrapper>{addButton}</EmptyDesignCollectionWrapper>
      </div>
    );
  }

  const sorter = (dataIndex: string) => (a: any, b: any) =>
    String(get(a, dataIndex)).localeCompare(String(get(b, dataIndex)));

  const columns = [
    {
      title: "",
      key: "thumbnail",
      dataIndex: "thumbnail",
      render: (_: string, item: MelcoCoreModelsDesignListItem) => (
        <DesignsListThumbnail design={item} />
      ),
      width: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
      height: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
    },
    {
      title: t("libraries.design_collections.detail.designs_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: sorter("name"),
      render: (_: string, item: MelcoCoreModelsDesignListItem) => (
        <ValueOrFallback
          value={item.name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      align: "right" as const,
      render: (_: string, item: MelcoCoreModelsDesignListItem) => (
        <Button
          icon={<EditIcon />}
          size="small"
          href={`/libraries/designs/${item.id!}`}
        >
          {t("libraries.design_collections.actions.edit.button")}
        </Button>
      ),
    },
  ];

  return (
    <div>
      <TableHeader>
        {title}

        <Space size="middle">
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionRemoveDesigns
            allDesigns={designs}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </Space>
      </TableHeader>

      <DisplayFieldErrors name="design_list" />

      <FullWidthSpace direction="vertical" size="large">
        <TableWrapper>
          <Table
            showSorterTooltip={false}
            dataSource={designs}
            columns={columns}
            rowKey="id"
            pagination={false}
            rowSelection={rowSelection}
            data-cy="designs-table"
          />
        </TableWrapper>
        <AddButtonWrapper>{addButton}</AddButtonWrapper>
      </FullWidthSpace>
    </div>
  );
};
