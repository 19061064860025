import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddColorCollection } from "../../../hooks/libraries/colorCollections/useAddColorCollection";

export const AddColorCollectionButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addColorCollection, isLoading] = useAddColorCollection();

  return (
    <Button
      key="color-collection-add"
      type="primary"
      icon={<PlusIcon />}
      onClick={async () => {
        const colorCollection = await addColorCollection();

        if (colorCollection) {
          navigate(
            `/libraries/color-collections/embroidery/${colorCollection.id!}`
          );
        }
      }}
      loading={isLoading}
    >
      {t("color_collections.actions.add.button")}
    </Button>
  );
};
