import { MainContentArea, ContentHeader, ValueOrFallback } from "melco-ui";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/navigation/useBreadcrumb";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";
import { useBlank } from "../../../hooks/libraries/blanks/useBlank";
import { BlankDetailForm } from "../../../components/libraries/blanks/BlankDetailForm";
import { BlankDetailMoreActionButton } from "../../../components/libraries/blanks/BlankDetailMoreActionButton";
import { useTranslation } from "react-i18next";
import { useGenericDrawer } from "../../../hooks/drawer/useGenericDrawer";
import { BlankReferencesDrawer } from "../../../components/libraries/blanks/BlankReferencesDrawer";
import { BlankDuplicateButton } from "../../../components/libraries/blanks/BlankDuplicateButton";

export const BlankDetails: React.FC = () => {
  const breadcrumb = useBreadcrumb("libraries", "librariesBlanks");

  const { t } = useTranslation();

  const { blankId } = useParams<{
    blankId: string;
  }>();

  const {
    query: { data: blank, refetch },
    queryKey,
  } = useBlank(blankId ?? "", ALL_PUBLISHED_STATES);

  const referencesDrawer = useGenericDrawer();

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            blank && (
              <ValueOrFallback
                value={blank.name}
                fallback={t("global.fallback.empty")}
              />
            )
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              <BlankDuplicateButton blankId={blankId ?? ""} />
              {blank && (
                <BlankDetailMoreActionButton
                  blank={blank}
                  referencesDrawer={referencesDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      {blank && (
        <>
          <BlankDetailForm
            blank={blank}
            refetch={refetch}
            referencesDrawer={referencesDrawer}
            queryKey={queryKey}
          />

          {blank.id && (
            <BlankReferencesDrawer drawer={referencesDrawer} id={blank.id} />
          )}
        </>
      )}
    </MainContentArea>
  );
};
