import { useField } from "formik";
import { DefaultColorSelect } from "./DefaultColorSelect";
import { DefaultColorSelectField } from "./DefaultColorSelectField";

type DefaultColorFieldProps = {
  formikPrefix: string;
};

export const DefaultColorField: React.FC<DefaultColorFieldProps> = ({
  formikPrefix,
}) => {
  const [{ value: colorCollectionId }] = useField<string>(
    `${formikPrefix}.color_collection_id`
  );

  if (!colorCollectionId || colorCollectionId.length <= 0) {
    return (
      <DefaultColorSelectField
        formikPrefix={formikPrefix}
        colorList={[]}
        isLoading={false}
      />
    );
  }

  return (
    <DefaultColorSelect
      formikPrefix={formikPrefix}
      colorCollectionId={colorCollectionId}
    />
  );
};
