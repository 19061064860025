import { MelcoCoreModelsUserAlphabet, SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "melco-ui";
import { useFontsDropdown } from "../../../hooks/libraries/fonts/useFontsDropdown";
import { useResetDefaultFont } from "../../../hooks/libraries/fonts/useResetDefaultFont";
import { DefaultFontField } from "./DefaultFontField";

type FontsFieldProps = {
  formikPrefix: string;
};

const valueConverter = {
  toSelectValue: (value: MelcoCoreModelsUserAlphabet[] | undefined) =>
    (value ?? []).map((v) => v.id!),
  toFormValue: (
    _value: any,
    options: { font: MelcoCoreModelsUserAlphabet }[] | undefined
  ) => (options ?? []).map((o) => o.font),
};

export const FontsField: React.FC<FontsFieldProps> = ({ formikPrefix }) => {
  const { t } = useTranslation();

  useResetDefaultFont(formikPrefix);

  const { isLoading, isFetching, data, isPreviousData } = useFontsDropdown();

  const fontsOptions = (data ?? []).map((font) => ({
    font,
    value: font.id!,
    label: font.name ?? "",
  }));

  return (
    <>
      <ConfigProvider renderEmpty={() => <>{t("fonts_list.empty")}</>}>
        {/* FIXME */}
        <SelectField<any>
          name={`${formikPrefix}.lettering.alphabet_list`}
          label={t("libraries.designs.detail.form.label.alphabet_list")}
          mode="multiple"
          loading={isPreviousData ? isFetching : isLoading}
          style={{ width: "100%" }}
          options={fontsOptions}
          valueConverter={valueConverter}
          showSearch
          optionFilterProp="label"
          filterOption
        />
      </ConfigProvider>

      <DefaultFontField
        formikPrefix={formikPrefix}
        allFonts={data ?? []}
        isLoading={isPreviousData ? isFetching : isLoading}
      />
    </>
  );
};
