import { useCallback, useState } from "react";
import { DrawerSelectFieldType } from "../form/useDrawerSelectField";

export type DrawerType = {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  drawerField: DrawerSelectFieldType<any>;
  setCurrentDrawerFieldName: (name: string) => void;
};

export const useDrawer = (fields: DrawerSelectFieldType<any>[]) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentDrawerFieldName, setCurrentDrawerFieldName] = useState<
    string | undefined
  >(undefined);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const drawerField =
    fields.find((f) => f.name === currentDrawerFieldName) ?? fields[0];

  return {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    drawerField,
    setCurrentDrawerFieldName,
  } as DrawerType;
};
