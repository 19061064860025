import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../../helper/publishedState";

export const queryKeyForBlankPicture = (
  blankPictureId: string,
  publishState: PublishState
) => ["blankPicture", blankPictureId, publishState];

export const useBlankPicture = (
  blankPictureId: string,
  publishState: PublishState
) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForBlankPicture(blankPictureId, publishState),
    async (api) => {
      return api.userImageGetDef({ id: blankPictureId, publishState });
    }
  );
};
