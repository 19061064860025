import { isEmpty } from "lodash-es";
import { Button, RemoveIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { MelcoCoreModelsDesignListItem } from "melco-shared-logic";
import { useAddDesigns } from "../../../hooks/libraries/designCollections/useAddDesigns";

type BulkActionRemoveDesignsProps = {
  allDesigns: MelcoCoreModelsDesignListItem[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionRemoveDesigns: React.FC<
  BulkActionRemoveDesignsProps
> = ({ allDesigns, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();

  const { removeDesigns } = useAddDesigns();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-remove"
      icon={<RemoveIcon />}
      onClick={() => {
        const toRemove = allDesigns.filter(
          (d) => d.id && selectedRowKeys.includes(d.id)
        );

        removeDesigns(toRemove as { id: string }[]);

        setSelectedRowKeys([]);
      }}
    >
      {t("libraries.design_collections.actions.add_designs.actions.remove")}
    </Button>
  );
};
