import { MelcoCoreModelsDesign } from "melco-shared-logic";
import { ElementConfiguration, Resource } from "../../renderer/useRenderer";
import {
  deDuplicateAlphabetRFMUrls,
  rendererElementsFromElementList,
} from "../../../helper/elementConfigurationHelper";

export const useDesignElementConfigurationFromDesign = (
  design: MelcoCoreModelsDesign | undefined
) => {
  const elementList = design?.element_list ?? [];

  const elements = rendererElementsFromElementList(elementList);

  const resources: Resource[] = [
    {
      url: design?.rfm_url!,
      resourceType: "design",
    },
    ...deDuplicateAlphabetRFMUrls(elements),
  ];

  const elementConfiguration: ElementConfiguration = {
    elements,
    resources,
  };

  return elementConfiguration;
};
