import { Button, DownloadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { useDownloadFont } from "../../../hooks/libraries/fonts/useDownloadFont";

type FontDownloadButtonProps = {
  fontId: string;
  fontFilename: string;
};

export const FontDownloadButton: React.FC<FontDownloadButtonProps> = ({
  fontId,
  fontFilename,
}) => {
  const { t } = useTranslation();
  const [downloadFont, isLoading] = useDownloadFont(fontId);

  return (
    <Button
      key="font-download"
      icon={<DownloadIcon />}
      onClick={async () => {
        const blob = await downloadFont();
        if (blob) {
          saveAs(blob, fontFilename);
        }
      }}
      loading={isLoading}
    >
      {t("fonts.actions.download.button")}
    </Button>
  );
};
