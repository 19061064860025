import { Button, DownloadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { useDownloadDesign } from "../../../hooks/libraries/designs/useDownloadDesign";

type DesignDownloadButtonProps = {
  designId: string;
  designFilename: string;
};

export const DesignDownloadButton: React.FC<DesignDownloadButtonProps> = ({
  designId,
  designFilename,
}) => {
  const { t } = useTranslation();
  const [downloadFont, isLoading] = useDownloadDesign(designId);

  return (
    <Button
      key="design-download"
      icon={<DownloadIcon />}
      onClick={async () => {
        const blob = await downloadFont();
        if (blob) {
          saveAs(blob, designFilename);
        }
      }}
      loading={isLoading}
    >
      {t("designs.actions.download.button")}
    </Button>
  );
};
