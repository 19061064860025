// session status
export const ALL_ORDER_SESSION_STATUS = "dropped,completed";

export type OrderSessionStatus = "dropped" | "completed" | string;

const orderSessionStatusLookup: {
  [key: number]: "dropped" | "completed";
} = {
  2: "dropped",
  3: "completed",
};

export const orderSessionStatusIdToName = (id: number) => {
  return orderSessionStatusLookup[id];
};

// production status
export const ALL_ORDER_PRODUCTION_STATUS = "new,downloaded,ordered";

export type OrderProductionStatus = "new" | "downloaded" | "ordered" | string;

const orderProductionStatusLookup: {
  [key: number]: "new" | "downloaded" | "ordered";
} = {
  1: "new",
  2: "downloaded",
  3: "ordered",
};

export const orderProductionStatusIdToName = (id: number) => {
  return orderProductionStatusLookup[id];
};
