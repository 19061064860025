import { ComponentProps } from "react";
import { SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import {
  ConfiguratorVersion,
  configuratorVersionValueToId,
} from "../../helper/configuratorVersion";

type ConfiguratorVersionSelectFieldProps = ComponentProps<typeof SelectField>;
export const ConfiguratorVersionSelectField: React.FC<
  ConfiguratorVersionSelectFieldProps
> = (props) => {
  const { name, ...rest } = props;

  const { t } = useTranslation();

  const options = Object.values(ConfiguratorVersion).map((version) => {
    return {
      label: t(`settings.configurator.form.values.version.${version}` as any),
      value: configuratorVersionValueToId(version),
    };
  });

  return (
    <SelectField
      name={name}
      showSearch
      optionFilterProp="label"
      {...rest}
      options={options}
    />
  );
};
