import {
  Alert,
  Button,
  DeleteIcon,
  LinkIcon,
  Menu,
  MoreIcon,
  Space,
} from "melco-ui";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDeleteDesigns } from "../../../hooks/libraries/designs/useDeleteDesigns";
import { useShouldShowWarning } from "../../../hooks/items/useShouldShowWarning";
import { MelcoCoreModelsDesign, useConfirm } from "melco-shared-logic";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type DesignDetailMoreActionButtonProps = {
  design: MelcoCoreModelsDesign;
  referencesDrawer?: DrawerType;
};

export const DesignDetailMoreActionButton: React.FC<
  DesignDetailMoreActionButtonProps
> = ({ design, referencesDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteDesign = useDeleteDesigns();
  const shouldShowWarning = useShouldShowWarning();

  const confirmDelete = useConfirm(
    async () => {
      //TODO: Handle case if design.id doesnt exist
      if (design.id) {
        const successfullyDeletedIds = await deleteDesign([design.id]);

        // redirect to list view in case the entity was deleted successfully
        if (!isEmpty(successfullyDeletedIds)) {
          navigate(`/libraries/designs`);
        }
      }
    },
    {
      translationPrefix: "designs.actions.delete",
      translationOptions: {
        count: 1,
      },
      content: shouldShowWarning(design) && (
        <Space direction="vertical" size="small">
          {t("designs.actions.delete.confirmation.message", {
            count: 1,
          })}
          <Alert
            type="warning"
            withBackground
            description={t("designs.actions.delete.confirmation.warning", {
              count: 1,
            })}
            showIcon={false}
          />
        </Space>
      ),
    }
  );

  const MoreActions = (
    <Menu>
      {referencesDrawer && (
        <Menu.Item
          key="more-actions-references"
          onClick={() => {
            referencesDrawer.openDrawer();
          }}
        >
          <LinkIcon /> {t("designs.actions.references.button")}
        </Menu.Item>
      )}
      <Menu.Item
        key="more-actions-delete"
        danger
        onClick={() => {
          confirmDelete();
        }}
      >
        <DeleteIcon /> {t("designs.actions.delete.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="designs-more-actions"
        icon={<MoreIcon />}
        data-cy-id="designs-more-actions"
      />
    </Dropdown>
  );
};
