import { useApiServiceBasicSettingsFormSubmit } from "./useBasicSettingsFormSubmit.apiservice";
import { useFusionBasicSettingsFormSubmit } from "./useBasicSettingsFormSubmit.fusion";

export type BasicSettingsFormValueType = {
  measurement_unit: string;
  default_embroidery_production_file_format: string;
};

export const useBasicSettingsFormSubmit =
  process.env.REACT_APP_PRODUCT_VARIANT! === "fusion"
    ? useFusionBasicSettingsFormSubmit
    : useApiServiceBasicSettingsFormSubmit;
