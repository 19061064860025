import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

export const useDownloadDesign = (designId: string) => {
  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userDesignsDownload({
        id: designId,
        publishState: ALL_PUBLISHED_STATES,
      });

      return {
        successMessages: [],
        data,
      };
    },
    { translationPrefix: "global" }
  );
};
