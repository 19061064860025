import React, { useEffect, useState } from "react";
import { APIError, useErrorMessageMapping } from "melco-shared-logic";
import { Button, ReloadIcon, Empty } from "melco-ui";
import { useTranslation } from "react-i18next";

export const useTableErrorComponent = (error: unknown, refetch: () => void) => {
  const { t } = useTranslation();
  const mapErrors = useErrorMessageMapping();

  const [errorComponent, setErrorComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  useEffect(() => {
    const renderErrorComponent = async (error: unknown) => {
      const {
        feedbackMessage: { message },
      } = await mapErrors(error as APIError[]);

      setErrorComponent(
        <Empty image={null} description={message}>
          <Button
            icon={<ReloadIcon />}
            onClick={() => {
              refetch();
            }}
          >
            {t("global.button.retry")}
          </Button>
        </Empty>
      );
    };

    if (!error) {
      setErrorComponent(undefined);
    } else {
      renderErrorComponent(error);
    }
  }, [t, error, mapErrors, refetch]);

  return errorComponent;
};
