import { ConfigProvider } from "melco-ui";
import { MelcoCoreModelsUserAlphabet, SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";

type DefaultFontSelectProps = {
  formikPrefix: string;
  fontIds: string[];
  allFonts: MelcoCoreModelsUserAlphabet[];
  isLoading: boolean;
};

export const DefaultFontSelect: React.FC<DefaultFontSelectProps> = ({
  formikPrefix,
  fontIds,
  allFonts,
  isLoading,
}) => {
  const { t } = useTranslation();

  const defaultFontOptions = (allFonts ?? [])
    .filter((o) => fontIds.includes(o.id!))
    .map((o) => ({
      value: o.id ?? "",
      label: o.name ?? "",
    }));

  return (
    <ConfigProvider renderEmpty={() => <>{t("fonts_list.empty")}</>}>
      <SelectField
        name={`${formikPrefix}.lettering.default_alphabet_id`}
        label={t("libraries.designs.detail.form.label.default_alphabet_id")}
        loading={isLoading}
        style={{ width: "100%" }}
        options={defaultFontOptions}
        showSearch
        optionFilterProp="label"
        filterOption
      />
    </ConfigProvider>
  );
};
