import { ColorSwatch } from "melco-ui";
import { useBlankPictureByIdFromProduct } from "../../../hooks/customization/products/useBlankPictureByIdFromProduct";

type BlankPictureColorSwatchProps = {
  blankPictureId: string;
};

export const BlankPictureColorSwatch: React.FC<
  BlankPictureColorSwatchProps
> = ({ blankPictureId }) => {
  const blankPicture = useBlankPictureByIdFromProduct(blankPictureId);

  if (!blankPicture) {
    return null;
  }

  return <ColorSwatch argb={blankPicture.color_hex ?? undefined} />;
};
