import { useCallback } from "react";
import { useAPIAction, FusionApi } from "melco-shared-logic";

export const useDeleteProducts = () => {
  const [deleteProduct] = useAPIAction(
    FusionApi,
    async (api, id: string) => {
      const data = await api.fusionCustomProductsDelete({ id, force: true });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "products.actions.delete",
    }
  );

  return useCallback(
    async (productIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const productId of productIds) {
        try {
          await deleteProduct(productId);
          successfullyDeleted.push(productId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteProduct]
  );
};
