import { useBlankPictureReferences } from "./useBlankPictureReferences";

export const useBlankPictureReferencesCount = (blankPictureId: string) => {
  const { data: blankPictureReferences } =
    useBlankPictureReferences(blankPictureId);

  const totalBlanks = blankPictureReferences?.blank_list?.length || 0;
  const totalProducts =
    blankPictureReferences?.custom_product_list?.length || 0;

  return { total: totalBlanks + totalProducts };
};
