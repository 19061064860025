export enum ConfiguratorVersion {
  Simple = "simple",
  Labeled = "labeled",
}

export const DEFAULT_VERSION = ConfiguratorVersion.Simple;

const configuratorVersionIdLookup: Record<number, ConfiguratorVersion> = {
  0: ConfiguratorVersion.Simple,
  1: ConfiguratorVersion.Labeled,
};

export const configuratorVersionIdToValue = (id: number) => {
  return configuratorVersionIdLookup[id];
};

const configuratorVersionValueLookup: Record<ConfiguratorVersion, number> = {
  [ConfiguratorVersion.Simple]: 0,
  [ConfiguratorVersion.Labeled]: 1,
};

export const configuratorVersionValueToId = (version: ConfiguratorVersion) => {
  return configuratorVersionValueLookup[version];
};
