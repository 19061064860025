import {
  useAPIQueryAndQueryKey,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { PublishState } from "../../../helper/publishedState";

export const queryKeyForDesign = (
  designId: string,
  publishState: PublishState
) => ["design", designId, publishState];

export const useDesign = (designId: string, publishState: PublishState) => {
  return useAPIQueryAndQueryKey(
    UserContentsDraftPublishApi,
    queryKeyForDesign(designId, publishState),
    async (api) => api.userDesignsGetDef({ id: designId, publishState })
  );
};
