import {
  MelcoCoreModelsSupplierProduct,
  useAPIQuery,
  UserContentsApi,
  useTable,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import { useTableErrorComponent } from "../../table/useTableErrorComponent";

export const useThreadSupplierProducts = () => {
  const {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    currentPage,
    pageSize,
    sortBy,
    searchQuery,
    searchQueryChange,
  } = useTable<MelcoCoreModelsSupplierProduct>();

  const query = useAPIQuery(
    UserContentsApi,
    [
      "threadSupplierProducts",
      currentPage,
      pageSize,
      filters,
      sortBy(),
      searchQuery,
    ],
    async (api) => {
      const response = await api.threadSupplierProductsSearch({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortBy(),
        searchText: !isEmpty(searchQuery) ? searchQuery : undefined,
        supplierType: "threads",
      });

      setTotal(response.total);

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    searchQuery,
    searchQueryChange,
    errorComponent,
    ...query,
  };
};
