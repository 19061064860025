import { useField } from "formik";
import { Button, LinkIcon, Space, UnlinkIcon } from "melco-ui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  configuratorVersionValueToId,
  DEFAULT_VERSION,
} from "../../../helper/configuratorVersion";
import { useConfiguratorSettings } from "../../../hooks/settings/useConfiguratorSettings";
import { ConfiguratorVersionSelectField } from "../../form/ConfiguratorVersionSelectField";

export const ConfiguratorVersionSetting: React.FC = () => {
  const { t } = useTranslation();
  const { settings, isLoading } = useConfiguratorSettings();

  const name = "configurator_version";

  const [{ value: productConfiguratorVersion }, , { setValue }] =
    useField(name);

  const isUnlinked = productConfiguratorVersion != null;
  const isLinked = !isUnlinked;

  const defaultVersion =
    settings?.version ?? configuratorVersionValueToId(DEFAULT_VERSION);

  const toggleLinkState = useCallback(() => {
    if (isUnlinked) {
      setValue(undefined);
    } else {
      setValue(defaultVersion);
    }
  }, [isUnlinked, setValue, defaultVersion, isLoading]);

  return (
    <>
      <ConfiguratorVersionSelectField
        name={name}
        label={t(
          "customization.products.detail.form.label.configurator_version"
        )}
        disabled={isLinked}
        valueConverter={
          isLinked
            ? {
                toFormValue: () => undefined,
                toSelectValue: () => settings?.version,
              }
            : undefined
        }
        info={
          <Space size="small">
            {t(
              `customization.products.detail.form.configurator_version.${
                isUnlinked ? "unlinked" : "linked"
              }.title` as any
            )}

            <Button
              size="small"
              onClick={toggleLinkState}
              icon={isUnlinked ? <LinkIcon /> : <UnlinkIcon />}
            >
              {t(
                `customization.products.detail.form.configurator_version.${
                  isUnlinked ? "unlinked" : "linked"
                }.toggleAction` as any
              )}
            </Button>
          </Space>
        }
      />
    </>
  );
};
