import { useCurrentProductVariant } from "../useCurrentProductVariant";
import { useGenericDrawer } from "./useGenericDrawer";

export const useDesignReferencesDrawer = () => {
  const productVariant = useCurrentProductVariant();
  const referencesDrawer = useGenericDrawer();

  if (productVariant === "fusion") {
    return referencesDrawer;
  }
};
