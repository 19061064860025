import { DesignTokens } from "melco-ui";
import { MelcoCoreModelsUserBlankListItem } from "melco-shared-logic";
import { useBlankThumbnailURL } from "../../../hooks/libraries/blanks/useBlankThumbnailURL";
import { ListThumbnail } from "../../shared/ListThumbnail";

const { spacings } = DesignTokens;

type BlanksListThumbnailProps = {
  blank: MelcoCoreModelsUserBlankListItem;
};

export const BlanksListThumbnail: React.FC<BlanksListThumbnailProps> = ({
  blank,
}) => {
  const thumbnailSrc = useBlankThumbnailURL(
    blank,
    spacings.xlarge,
    spacings.xlarge
  );

  return <ListThumbnail thumbnailSrc={thumbnailSrc ?? undefined} />;
};
