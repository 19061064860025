import { Col, DesignTokens, Row } from "melco-ui";
import { ProductionFilesCard } from "./ProductionFilesCard";
import { SessionsCard } from "./SessionsCard";
import { StatisticsCardRowProps } from "./StatisticsCardRow";

const { spacings } = DesignTokens;

export const StatisticsCardRowFusion: React.FC<StatisticsCardRowProps> = ({
  startTimestamp,
  endTimestamp,
}) => (
  <Row gutter={[spacings.middle, spacings.middle]}>
    <Col span={6}>
      <ProductionFilesCard
        startTimestamp={startTimestamp}
        endTimestamp={endTimestamp}
      />
    </Col>
    <Col span={18}>
      <SessionsCard
        startTimestamp={startTimestamp}
        endTimestamp={endTimestamp}
      />
    </Col>
  </Row>
);
