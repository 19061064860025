import { useState } from "react";
import styled from "styled-components";
import {
  SecondaryNavigation,
  SecondaryNavigationMenuItem,
  Text,
} from "melco-ui";
import { Dropdown } from "antd";
import { SecondaryNavigationMenu } from "./SecondaryNavigationMenu";
import { useCurrentUser, userInitials } from "melco-shared-logic";
import { BackToAccountsLink } from "./BackToAccountsLink";
import { HelpLink } from "./HelpLink";

const StyledName = styled.div`
  text-transform: uppercase;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
`;

export const FusionSecondaryNavigation: React.FC = () => {
  const currentUser = useCurrentUser();
  const [isVisible, setVisible] = useState(false);

  return (
    <SecondaryNavigation>
      <HelpLink />
      <BackToAccountsLink />
      {currentUser && (
        <SecondaryNavigationMenuItem
          prefix={
            currentUser.accountName && (
              <Text
                type="secondary"
                ellipsis={{ tooltip: currentUser.accountName }}
                data-cy-id="floating-navigation-user-account-name"
              >
                {currentUser.accountName}
              </Text>
            )
          }
        >
          <Dropdown
            overlay={
              <SecondaryNavigationMenu setDropDownVisible={setVisible} />
            }
            trigger={["click"]}
            arrow
            visible={isVisible}
            onVisibleChange={(v) => setVisible(v)}
          >
            <StyledName data-cy-id="floating-navigation-user-initials">
              {userInitials(currentUser)}
            </StyledName>
          </Dropdown>
        </SecondaryNavigationMenuItem>
      )}
    </SecondaryNavigation>
  );
};
