import { useCallback, useState } from "react";
import { Button, UploadIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "react-query";
import { useFontUpload } from "../../../hooks/libraries/fonts/useFontUpload";

type FontUploadButtonProps = {
  service: "embroidery" | "print";
};

export const FontUploadButton: React.FC<FontUploadButtonProps> = ({
  service,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { uploadFont } = useFontUpload(service);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true);
      for (const file of acceptedFiles) {
        try {
          await uploadFont(file);
        } catch (error) {}
        queryClient.invalidateQueries("fonts");
      }

      setIsLoading(false);
    },
    [uploadFont, queryClient]
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <input {...getInputProps()} />

      <Button
        key="font-upload"
        type="primary"
        icon={<UploadIcon />}
        onClick={() => {
          open();
        }}
        loading={isLoading}
      >
        {t("fonts.actions.upload.button")}
      </Button>
    </>
  );
};
