import { useAPIQuery, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useColorCollectionsDropdownData = () => {
  return useAPIQuery(
    UserContentsDraftPublishApi,
    ["colorCollectionsDropdownData"],
    async (api) => {
      const response = await api.userColorCollectionsGetList({
        offset: 0,
        limit: 100,
        sortBy: "name.asc",
        publishState: "published",
        service: "embroidery",
      });

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );
};
