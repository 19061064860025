import {
  DisplayFormErrors,
  FileUploadField,
  InputField,
  MelcoCoreModelsUserAlphabet,
  VerticalForm,
  DebugForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertFont } from "../../../hooks/libraries/fonts/useRevertFont";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { useFontReferencesCount } from "../../../hooks/libraries/fonts/useFontReferencesCount";

type DisplayFontDetailFormProps = {
  font: MelcoCoreModelsUserAlphabet;
  refetch: () => Promise<
    QueryObserverResult<MelcoCoreModelsUserAlphabet, unknown>
  >;
  referencesDrawer: DrawerType;
};

export const DisplayFontDetailForm: React.FC<DisplayFontDetailFormProps> = ({
  font,
  refetch,
  referencesDrawer,
}) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = font;

  const { t } = useTranslation();
  const [revertFont, isLoading] = useRevertFont(id!, refetch);

  const { total: referencesCount } = useFontReferencesCount(id!);

  return (
    <>
      <SavePublishHeader
        revertAction={revertFont}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <VerticalForm>
        <DisplayFormErrors />

        <InputField
          name="name"
          label={t("libraries.fonts.detail.form.label.name")}
        />

        <FileUploadField
          name="file"
          label={t("libraries.fonts.detail.form.label.file")}
          maxFiles={1}
        />

        <InputField
          name="recommended_text"
          label={t("libraries.fonts.detail.form.label.recommended_text")}
          maxLength={50}
        />
      </VerticalForm>

      {false && <DebugForm />}
    </>
  );
};
