import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ScrollToTopProps = {
  contentAreaRef?: React.RefObject<HTMLElement>;
};

// component is used to ensure the user is always scrolled to the top of the page when the location changes
// see https://v5.reactrouter.com/web/guides/scroll-restoration
export const ScrollToTop: React.FC<ScrollToTopProps> = ({ contentAreaRef }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    contentAreaRef?.current?.scrollTo(0, 0);
  }, [pathname, contentAreaRef]);

  return null;
};
